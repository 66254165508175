import React, { lazy } from "react";
import { useDispatch } from 'react-redux';
import {checklistStoreNote} from "../../actions/g4bmigrActions";

/**
 * Lazily loading "@csas-smart/gti-g4bmigr-ui": 
 */ 
const NoteField = lazy(() =>
    import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.NoteField }))
);

const NoteFieldContainer = (props) => {

    const dispatch = useDispatch();

    const onSaveNote = (requestDto) => {
        console.log('NoteFieldContainer - onSaveNote called');

        dispatch(checklistStoreNote(requestDto));
    };

    return (
        <NoteField {...props} onSaveNote={onSaveNote}/>
    )
};

export default NoteFieldContainer;
