import React, { lazy } from "react";
import { useDispatch } from 'react-redux';
import { migrationActivateDevice, wizardGetDevices, migrationGetPollingInfo, migrationGetPollStatus } from "../../actions/g4bmigrActions";

// Lazily loading "@csas-smart/gti-g4bmigr-ui":
const DeviceActivationField = lazy(() =>
    import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.DeviceActivationField }))
);
  
const DeviceActivationFieldContainer = (props) => {

    const dispatch = useDispatch();

    const updateDevices = () => {
        console.log('DeviceActivationFieldContainer - updateDevices called');

        return dispatch(wizardGetDevices(props.field.selector.attributes.overLimitMethodDevices.name));
    }

    const activateDevice = (requestDto) => {
        console.log('DeviceActivationFieldContainer - activateDevice called');

        return dispatch(migrationActivateDevice(requestDto));
    }

    const getPollingInfo = (requestDto) => {
        console.log('DeviceActivationFieldContainer - getPollingInfo called');

        return dispatch(migrationGetPollingInfo(requestDto));
    }

    const getStatus = (requestDto) => {
        console.log('DeviceActivationFieldContainer - getStatus called');

        return dispatch(migrationGetPollStatus(requestDto));
    }


    return (
        <DeviceActivationField
            {...props}
            updateDevices={updateDevices}
            activateDevice={activateDevice}
            getPollingInfo={getPollingInfo}
            getStatus={getStatus}
        />
    )
};

export default DeviceActivationFieldContainer;
