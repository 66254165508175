import {AsyncTypeahead} from "react-bootstrap-typeahead";
import React, {useEffect, useRef, useState} from "react";
import Resource from "../../core/serverresource";
import { ValidationError } from "@csas-smart/gti-ui-comps";

export const RuianComp = ({id, address, fieldName, t, validations, onChange, required}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const wholeAddressRef = useRef(address?.wholeAddress || '');
    const invalidRef = useRef(true);

    useEffect(() => {
        onValidate(wholeAddressRef.current);
        validations.componentMounted(fieldName + "-ruian", validate);

        //componentUnmount
        return () => {
            validations.componentUnmounted(fieldName + "-ruian");
        }
    }, []);

    const validate = () => {
        if (required && !wholeAddressRef.current) {
            validations.setError({ruian: {required: true}});
            return false;
        } else if (required && invalidRef.current) {
            validations.setError({ruian: {invalid: true}});
            return false;
        }
        validations.setError({ruian: {}});
        return true;
    };

    const onSearch = (query) => {
        setIsLoading(true);
        const body = {
            id: 1019382023,
            limit: 10,
            fieldType: 'WHOLE_ADDRESS',
            values: {
                WHOLE_ADDRESS: query,
            },
        };

        fetch(`${import.meta.env.VITE_APP_RUIAN_URL}/suggest`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        }).then(Resource.parseJSON).then((response) => {
            if (response.resultCode === 'OK') {
                setOptions(response.suggestions
                    .filter(suggestion => suggestion.values.NUMBER)
                    .map(suggestion => suggestion.values.WHOLE_ADDRESS));
            } else {
                console.error('request failed', response.errorMessage);
            }
        }).catch((error) => {
            console.error('request failed', error.statusText);
        }).finally(() => setIsLoading(false));
    };

    const onValidate = (query) => {
        if (!query) return;
        setIsLoading(true);
        const body = {
            id: 86464344,
            values: {
                WHOLE_ADDRESS: query,
            },
        };

        fetch(`${import.meta.env.VITE_APP_RUIAN_URL}/validate`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        }).then(Resource.parseJSON).then((response) => {
            if (response.resultCode === 'OK' && response.result.addresses.length > 0) {
                const newAddress = mapAddress(response.result.addresses[0].values);
                wholeAddressRef.current = newAddress.wholeAddress;
                invalidRef.current = false;
                onChange(newAddress);
            }
        }).catch((error) => {
            console.error('request failed', error.statusText);
        }).finally(() => setIsLoading(false));
    };

    const onSelect = ([selected]) => {
        if (selected) {
            onValidate(selected);
        }
    };

    const onInputChange = (text) => {
        invalidRef.current = true;
        wholeAddressRef.current = text;
        onChange({wholeAddress: text});
    };

    const mapAddress = (ruianAddress) => ({
        street: ruianAddress.STREET_NAME || ruianAddress.MUNICIPALITY_PART_NAME || ruianAddress.MUNICIPALITY_NAME,
        registryBuildingNumber: ruianAddress.NUMBER_POPISNE,
        buildingNumber: ruianAddress.CHAR_ORIENT ? ruianAddress.NUMBER_ORIENT + ruianAddress.CHAR_ORIENT : ruianAddress.NUMBER_ORIENT,
        city: ruianAddress.MUNICIPALITY_NAME,
        zipCode: ruianAddress.ZIP,
        country: ruianAddress.COUNTRY_CODE,
        wholeAddress: ruianAddress.WHOLE_NAME
    });

    return (
        <>
            <AsyncTypeahead
                id={id}
                filterBy={() => true}
                className={"employer-search-comp dropdown g-dropdown"}
                selected={[address?.wholeAddress || '']}
                isLoading={isLoading}
                minLength={3}
                paginate={false}
                promptText={""}
                options={options}
                emptyLabel={t('field.properties.labels.ruian.empty')}
                placeholder={t('field.properties.labels.placeholder')}
                onSearch={onSearch}
                onChange={onSelect}
                onInputChange={onInputChange}
                onBlur={validate}
            />
            <ValidationError errorText={t('field.error.required')} error={validations.validationError.ruian?.required}/>
            <ValidationError errorText={t('field.error.ruian.invalid')} error={validations.validationError.ruian?.invalid}/>
        </>
    );
};
