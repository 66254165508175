import React, {useEffect, useState} from "react"
import {Col, Row} from "reactstrap";
import {Select} from "@george-labs.com/design-system";
import { GdsFieldContainer } from "@csas-smart/gti-ui-comps";
import { isExisting } from "validations";

/**
 * input:  date as ms-timestamp
 * output: selected date as ms-timestamp
 *
 * PROPS:
 */


function initialMonths(t) {
    // Create empty array of 12 elements and convert them into month objects.
    return Array.from(Array(12), (e, i) => ({value: (i+1).toString(), label: t('common:phrases.months.' + (i + 1))})) // String cast is important, otherwise typescript complains about number instead of string.
}

const getTranslations = (t) => {
    return {
        month: t('common:field.employer-search-field.month'),
        year: t('common:field.employer-search-field.year'),
    };
};

// Function generates list of 10 following years to offer in dropdown.
function initialYears() {
    const currentYear = new Date().getFullYear(); // minus 1 is for addition sequence to work.
    // Create array of 10, turn it into dense array of undefined and apply addition sequence to each element.
    return Array.from(Array(10), (e, i) => ({value: (i + currentYear).toString(), label: (i + currentYear).toString()})); // String cast is important, otherwise typescript complains about number instead of string.
}

// Function gets components initial date. Initial date is set to the first day of following month.
const getInitialDate = () => {
    let newDate = new Date();
    newDate.setDate(1); // set date to 1. th
    newDate.setMonth(newDate.getMonth() + 1); // Increment by one month
    return newDate.getTime();
};

const MonthYearSelectComp = (props) => {
    const {field, onChange, value, t, required} = props;
    const [months] = useState(initialMonths(t));
    const [years] = useState(initialYears);

    const [translations] = useState(getTranslations(t));

    // On component did mount, pre-set current month
    useEffect(() => {
        // Default Month
        if (!value){
            onChange(getInitialDate());
        }
    },[]);

    const changeMonth = (e) => {
        const newValue = new Date(value).setMonth(e.value -1); // -1 due to indexing months from 0
        onChange(newValue);
    };

    const changeYear = (e) => {
        const newValue = new Date(value).setFullYear(e.value );
        onChange(newValue);
    };

    // Prepare current attribute value
    const selectedMonth = isExisting(value) ? new Date(value).getMonth() + 1: null; // +1 due to displaying indexing months from index 1
    const selectedYear = isExisting(value) ? new Date(value).getFullYear() : null;

    return (
        <GdsFieldContainer field={field} t={t} required={required} componentId={'monthYearSelection'}>
            <Row>
                <Col md={2} className="vertically-centered-col">
                    <span>{translations.month}</span>
                </Col>
                <Col md={4} className="vertically-centered-col">
                    <Select items={months} id={field.name + "select-month"} name={field.name + "select-month"}
                            value={months.filter(option => option.value == selectedMonth?.toString())} onSelect={changeMonth}
                            disabled={!field.editable} />
                </Col>
                <Col md={2} className="vertically-centered-col">
                    <span>{translations.year}</span>
                </Col>
                <Col md={4}>
                    <Select items={years} id={field.name + "select-year"} name={field.name + "select-year"}
                            value={years.filter(option => option.value == selectedYear?.toString())}
                            onSelect={changeYear}
                            disabled={!field.editable} />
                </Col>
            </Row>
        </GdsFieldContainer>
    );
};

export default MonthYearSelectComp;