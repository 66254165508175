import React from "react";
import {LoanLimitSliderField} from "@csas-smart/gti-corp-landing-ui/dist";
import SessionWrapper from "../sessions/sessionWrapper.ts";


const LoanLimitSliderFieldContainer = (props) => {
    const sessionWrapper = new SessionWrapper();

    return <LoanLimitSliderField {...props} language={sessionWrapper.getLanguage()}/>

}

export default LoanLimitSliderFieldContainer