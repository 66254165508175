import React, { useState } from "react";
import { RecordListField } from "@csas-smart/gti-ui-comps";
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { resolveHashIdFromState } from "../core/utils/taskUtils";
import { useAppSelector } from "../core/hooks/hooks";
import { loadCodebookWithLang } from "../actions/taskActions";
import { DocumentPdfViewerModal } from "@csas-smart/gti-sigma-ui";
import { Alert, Modal, ModalBody } from '@george-labs.com/design-system';


const RecordListFieldContainer = (props) => {
    const hashId = useAppSelector(() => resolveHashIdFromState());
    const { attributes } = useAppSelector(state => state.task.activity);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
    const [file, setFile] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { t } = props;

    /**
     * Method that searches records according to field properties.
     * @param fieldName The name of the field used for the search. 
     * @returns List of records according to defined 'loadByExpressions'.
     */
    // FIXME: Export RecordDto from gti-ui-comps to use as return type.
    // FIXME: Export Request from gti-ui-comps to as parameter.
    const searchRecords = (request: any): Promise<any[]> => {
        return RestCaller.httpPost(Resource.searchRecords(hashId, request.fieldName), attributes);
    }

    const loadCodebook = (codebook: string, attributes: any) => {
        return loadCodebookWithLang(codebook, attributes, "cs");
    }

    const deleteRecord = (duid: string) => {
        return RestCaller.httpPostWithoutResponse(Resource.cancelRecord(duid, hashId, props.field.name), attributes);
    }

    const attachExistingRecord = (duid: string) => {
        return RestCaller.httpPut(Resource.addExistingRecord(duid, hashId, props.field.name), attributes);
    }

    const getRecordDetail = (request: any) => {
        setLoading(true);
        setIsOpen(true);
        return RestCaller.httpGetWithBinary(Resource.downloadRecord(request.duid, hashId, props.field.name))
            .then(response => {
                if (response.headers.get('Content-Type').startsWith('application/pdf')) {
                    return Promise.resolve(response.blob())
                } else {
                    console.log("Cannot preview non-pdf file");
                    setIsOpen(false);
                    setErrorModalOpen(true);
                    return null;
                }
            })
            .then(file => {
                setFile(file);
            })
            .catch(error => {
                console.log("The loading of the file failed due to: ", error);
                setError({ stack: t("common:field.recordlist-field.documentLoadFailed") });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const uploadRecord = (request) => {
        console.log("Upload request, desired attributes: ", attributes)
        const promises = request.records.map(record => {
            const formData = new FormData();
            formData.append('file', record.file);
            const payload = {
                recordType: record.type,
                uploadAttributes: [] // Sending 'attributes' causes an issue on firewall, as max number of params is exceeded. We probably need to filter them out. ??
            }
            formData.append('json', new Blob([JSON.stringify(payload)], {
                type: "application/json"
            }));
            return RestCaller.httpPostWithBinary(Resource.uploadRecord(hashId, props.field.name), formData);
        });
        return Promise.all(promises).then(records => {
            console.log("UPLOAD:", records);
            return records;
        })
        
    }

    return <>
        <RecordListField {...props}
            searchRecords={searchRecords}
            loadCodebook={loadCodebook}
            deleteRecord={deleteRecord}
            addRecord={attachExistingRecord}
            displayFile={getRecordDetail}
            uploadRecord={uploadRecord}
        />
        <DocumentPdfViewerModal documentName={undefined}
            documentContent={file}
            isOpen={isOpen} onClose={() => { setIsOpen(false) }}
            isLoading={isLoading}
            error={error} />
        <Modal isOpen={errorModalOpen} onClose={() => { setErrorModalOpen(false) }}>
            <ModalBody>
                <Alert variant={Alert.VARIANT.ERROR}>
                    { t("common:field.recordlist-field.unsupportedDocumentPreview") }
                </Alert>
            </ModalBody>
        </Modal>
    </>
};

export default RecordListFieldContainer;
