import {LogglyTracker} from 'loggly-jslogger';
import LogLevel from "./LogLevel";
import LogglyMessageString from "./logglyMessageString";
import LogglyMessageObject from "./logglyMessageObject";
import LogglyMessageApi from "./logglyMessageApi";
import SessionWrapper from "../../sessions/sessionWrapper";

class GtiLogger {
    LOGGLY_KEY = '7e04779f-b678-4ec6-9fee-85ac09dbe2bb';
    APP_TAG = 'gti_web';
    logger;
    sessionWrapper;

    constructor() {
        this.logger = new LogglyTracker();
        this.sessionWrapper = new SessionWrapper();
        const env = import.meta.env.VITE_ENV;
        this.logger.push({
            tag: this.APP_TAG + ',' + env + ','+LogLevel.DEBUG,
            logglyKey: this.LOGGLY_KEY,
            sendConsoleErrors: true
        });
    }

    getAllowedLevels() {
        switch(import.meta.env.LOG_LEVEL) {
            case LogLevel.DEBUG: return [LogLevel.DEBUG, LogLevel.INFO, LogLevel.WARN, LogLevel.ERROR];
            case LogLevel.INFO: return [LogLevel.INFO, LogLevel.WARN, LogLevel.ERROR];
            case LogLevel.WARN: return [LogLevel.WARN, LogLevel.ERROR];
            case LogLevel.ERROR: return [LogLevel.ERROR];
        }
        return [];
    }

    info(msg) {
        this.log2loggly(msg, LogLevel.INFO);
    }

    debug(msg) {
        this.log2loggly(msg, LogLevel.DEBUG);
    }

    error(msg) {
        this.log2loggly(msg, LogLevel.ERROR);
    }

    warn(msg) {
        this.log2loggly(msg, LogLevel.WARN);
    }

    api(msg) {
        this.log2loggly(msg, LogLevel.INFO, "api");
    }

    log2loggly(msg, logLevel, type?) {
        if (this.getAllowedLevels().indexOf(logLevel) === -1) {
            return;
        }

        switch(logLevel) {
            case LogLevel.INFO: console.info(msg); break;
            case LogLevel.DEBUG: console.debug(msg); break;
            case LogLevel.ERROR: console.error(msg); break;
            case LogLevel.WARN: console.warn(msg); break;
            default: console.log(msg);
        }

        const env = import.meta.env.VITE_ENV;
        this.logger.push(
            {
                tag: this.APP_TAG + ',' + env + ','+logLevel,
                logglyKey: this.LOGGLY_KEY,
                sendConsoleErrors: true
            }
        )

        let logglyMessage = null;

        if(type && type === 'api') {
            logglyMessage = new LogglyMessageApi(msg);
        }
        else if(msg instanceof Date || typeof msg === 'string' || typeof msg === 'number' || typeof msg === 'boolean') {
            logglyMessage = new LogglyMessageString(msg.toString());
        } else {
            logglyMessage = new LogglyMessageObject(msg);
        }

        const hashId = this.sessionWrapper.existsHashId() ? this.sessionWrapper.getHashId() : "null";
        const sessionId = this.sessionWrapper.existsSessionId() ? this.sessionWrapper.getSessionId() : "null";

        logglyMessage.userAgent = window.navigator.userAgent;
        logglyMessage.hashId = hashId;
        logglyMessage.sessionId = sessionId;

        this.logger.push(logglyMessage);
    }


}

export default new GtiLogger();
