import { isEmptyString } from "validations";

/**
 * Simple function validating, whether given string is possibly valid zipCode. Function validates only length of string.
 * @param arg Argument passed as a possible zipCode.
 * @returns {boolean}
 */
export function isValidZipCode(arg: string | undefined | null) {
  if (isEmptyString(arg)) {
    return false;
  }
  return arg!.replace(/\s/g, '').length === 5; // Due to isEmptyString check 'arg' must be non-empty string, hence the '!.' operator to suppress ts error.
}