import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import {Button} from '@george-labs.com/design-system';
import GeorgeSpinner from "../../core/georgeSpinner";
import {getRecord, pregenerateRecord} from "../../actions/documentActions";
import {isMobileApp} from "../../core/utils/salesChannelUtils";
import {DocumentPdfViewerModal} from '@csas-smart/gti-sigma-ui';
import {useAppDispatch} from "../../core/hooks/hooks";
import {resolveFilenameFromResponseHeaders} from "../../common/reusableFunctions";

export const NgRecordItem = (props) => {
    const { record } = props;
    const { name } = props.field;
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [recordDetail, setRecordDetail] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        if(selectedFile && isMobileApp()) {
            setRecordDetail(<DocumentPdfViewerModal documentContent={selectedFile} documentName={record.documentName} isLoading={loading} isOpen={selectedFile !== null} onClose={closeDetail} error={undefined}></DocumentPdfViewerModal>);
        } else {
            setRecordDetail(null);
        }
    }, [selectedFile]);

    const downloadDocument = (evt) => {
        evt.stopPropagation();
        setLoading(true);

        if (record.preprint) {
            dispatch(pregenerateRecord(name, record.templateDefinitionHash))
                .then(processResponse)
                .then(documentResolved)
                .catch(documentRejected)
        } else {
            dispatch(getRecord(record.duid))
                .then(processResponse)
                .then(documentResolved)
                .catch(documentRejected)
        }
    }

    const processResponse = (response) => {
        setLoading(false);
        record.downloadFilename = resolveFilenameFromResponseHeaders(response);
        return response.blob()
    }

    const documentResolved = (blob) => {
        if (isMobileApp()){
            const fileurl = URL.createObjectURL(blob);
            selectedFileHandler(fileurl);
        } else {
            //document is <DOM> document. Not document object used by this "component"
            const link = document.createElement('a');

            link.setAttribute('download', record.downloadFilename);
            link.href = window.URL.createObjectURL(new Blob([blob]));

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }

    };

    const closeDetail = () => {
        setSelectedFile(null);
    }

    const selectedFileHandler = (file) => {
        setSelectedFile(file);
    }

    const documentRejected = (error) => {
        setLoading(false);
        console.log(error);
    };

    const spinner = (
        <Row>
            <Col xl={10} lg={10} md={10} sm={12} xs={12}>
                <GeorgeSpinner />
            </Col>
        </Row>);

    return loading ? spinner : (
        <Row>
            <Col xl={10} lg={10} md={10} sm={12} xs={12}>
                <Button className={"pl-0"} fullWidth={Button.FULL_WIDTH.NEVER} variant={Button.VARIANT.TERTIARY} icon={Button.ICON.FILE_DOWNLOAD} onClick={downloadDocument}>{record.documentName}</Button>
            </Col>
            {recordDetail}
        </Row>
    )
}
