import SessionWrapper from "../../sessions/sessionWrapper";

/**
 * USED FOR DEBUG PURPOSES ONLY
 * enhancer sets the 'web-api-corporate' header to value requested as url parameter 'hidden_cluid_po'.
 * For correct usage it is important to set property VITE_APP_AUTH_HEADER_CORP in current .env file to 'hidden_cluid_po' value. Default .env value is empty value.
 */
class WebApiCorporate {

    sessionWrapper: SessionWrapper;
    
    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    enhanceHeaders(headers) {
        if(!import.meta.env.VITE_APP_AUTH_HEADER_CORP || !window.sessionStorage.getItem(import.meta.env.VITE_APP_AUTH_HEADER_CORP)){
            return headers;
        }

        if(!headers) {
            headers = {};
        }

        return {...headers, [import.meta.env.VITE_APP_AUTH_HEADER_CORP]: window.sessionStorage.getItem(import.meta.env.VITE_APP_AUTH_HEADER_CORP)};
    }
}

export default WebApiCorporate;
