import {SigmaDownloadField} from '@csas-smart/gti-sigma-ui';
import React from "react";
import {getDocument, getFolderByField} from "../../actions/sigmaActions";
import {useDispatch} from "react-redux";
import { AppDispatch } from '../../core/store';
import {resolveFilenameFromResponseHeaders} from "../../common/reusableFunctions";

const SigmaDownloadFieldContainer = (props) => {

    let filename = undefined;
    const { folderId } = props.attributes;
    const dispatch = useDispatch<AppDispatch>();

    const onFolderLoad = () => {
        return dispatch(getFolderByField(props.field.name));
    };

    const onDocumentDownload = (documentId) => {
        return dispatch(getDocument(folderId, documentId))
            .then(processDocument)
            .catch(documentRejected)
    };

    const processDocument = async (response) => {
        filename = resolveFilenameFromResponseHeaders(response);
        const blob = await response.blob();
        return {blob, filename};
    };


    const documentRejected = (error) => {
        console.log(error);
    };

    return (
        <SigmaDownloadField {...props} onFolderLoad={onFolderLoad} onDocumentDownload={onDocumentDownload}/>
    )
};

export default SigmaDownloadFieldContainer;
