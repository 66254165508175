import Resource from './serverresource';

//import {showError} from '../actions/taskActions'

class RestCaller {
    constructor() {
        console.debug('Rest caller initialization');
    }

    httpPureGet(url) {
        return fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
    }

    httpGet(url, headers = {}) {
        if (!headers["Content-Type"]) {
            headers["Content-Type"] = "application/json; charset=utf-8";
        }
        return fetch(url, {
            method: 'GET',
            headers: headers
        })
        .then(Resource.checkStatus)
        .then(Resource.parseJSON);
    }

    httpGetWithBinary(url) {
        return fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(Resource.checkStatus);
    }

    httpPost(url, request, additionalHeaders = {}) {
        const headers = Object.assign({
            "Content-Type": "application/json; charset=utf-8",
        }, additionalHeaders);

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: headers
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON);
    }

    httpPostWithoutResponse(url, request) {
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        })
            .then(Resource.checkStatus)
    }

    httpPostWithLang(url, request, lang) {
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "language": lang,
                "accept-language": lang
            }
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON);
    }

    httpPostWithBinary(url, request) {
        return fetch(url, {
            method: 'POST',
            body: request
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON);
    }

    httpPostWithStatusCheck(url, request) {
        return fetch(url, {
            method: 'POST',
            body: request
        })
    }

    httpPostWithUrlEncodedFormData(url, request) {
        return fetch(url, {
            method: 'POST',
            redirect: 'follow',

            //URLSearchParams should automatically add "Content-Type": "application/x-www-form-urlencoded" header
            body: new URLSearchParams(request)
        });
    }

    httpPurePut(url, request) {
        return fetch(url, {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(Resource.checkStatus)
    }

    httpPut(url, request) {
        return fetch(url, {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(Resource.checkStatus)
        .then(Resource.parseJSON);
    }

    httpPutWithoutBody(url) {
        return fetch(url, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(Resource.checkStatus);
    }

    httpDelete(url) {
        return fetch(url, {
            method: 'DELETE'
        }).then(Resource.checkStatus)
    }
}

export default new RestCaller();
