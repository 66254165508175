import React from "react";
import {SigmaDocumentsToSignField} from "@csas-smart/gti-sigma-ui";
import SessionWrapper from "../../sessions/sessionWrapper";
import { isMobileApp } from "../../core/utils/salesChannelUtils";


const SigmaDocumentsToSignFieldContainer = (props) => {
  //const folderId = props.attributes.folderId;
  const sessionWrapper = new SessionWrapper();

  const onFolderSigned = () => {
    return props.completeActivityAction();
  }

  const onFolderRejected = () => {
    return props.completeActivityAction();
  }

  const apiConfig = {
    env: import.meta.env.VITE_ENV.toUpperCase(),
    webApiKey: import.meta.env.VITE_APP_API_KEY,
    accessToken: sessionWrapper.getAccessToken()
  }

  return (
      <SigmaDocumentsToSignField
          t={props.t}
          field={props.field}
          attributes={props.attributes}
          onFolderSigned={onFolderSigned}
          onFolderRejected={onFolderRejected}
          fieldValueChanged={props.fieldValueChanged}
          apiConfig={apiConfig}
          mobileDevice={isMobileApp()}
          lang={sessionWrapper.getLowerCaseLanguage()}
      />
  );
};

export default SigmaDocumentsToSignFieldContainer;
