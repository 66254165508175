import React, {useContext} from 'react';
import {Card, Col, Container, Row} from 'reactstrap';
import IconWrapper from "./lazy-wrappers/iconWrapper";
import {fireCaseCancelled} from "../actions/taskActions";
import {DeviceContext, DeviceContextProps} from "@csas-smart/gti-ui-comps";
import {PageHeader} from "./page-header";
import {isMobileApp} from "./utils/salesChannelUtils";
import {fireHandoverToken} from "../actions/mepActions";
import { Button } from '@george-labs.com/design-system';
import { useAppDispatch } from './hooks/hooks';


const ErrorComponent = (props) => {
    const {message} = props;
    const {t} = props;
    const dispatch = useAppDispatch();
    const contextValue = useContext<DeviceContextProps>(DeviceContext);
    let pageHeader = isMobileApp() ? null : (<PageHeader isError={true}/>);

    const backToGeorgeHandler = () => {
        dispatch(fireCaseCancelled())
            .finally(dispatchHandoverToken);
    };

    const dispatchHandoverToken = () => {
        return dispatch(fireHandoverToken());
    }

    const renderContent = () => {
        return <div>
            <Card className={"g-card g-card-product g-card-product-header"}>
                <Row>
                    &nbsp;
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className={'text-center'}>
                        <IconWrapper name="error-circle-xl-red" className={'svg-container'}/>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xl={8} lg={8} md={8} sm={8} xs={8} className={'text-center'}>
                        <p><h5><strong>{t('common:popup.error.georgeError')}</strong></h5></p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xl={8} lg={8} md={8} sm={8} xs={8} className={'text-center'}>
                        <p>{message}</p>
                    </Col>
                    &nbsp;
                </Row>
                <Row className="pt-3 pb-3 ml-3 mr-3">
                    <Col xs={12} className={'text-center'}>
                        <Button variant={Button.VARIANT.PRIMARY} onClick={backToGeorgeHandler}>{t('common:popup.error.backToOverview')}</Button>
                    </Col>
                </Row>
            </Card>
        </div>
    };


    return (
        <>
            {pageHeader}
            <Container className="appContainer">
                {contextValue.isDesktop ?
                    <>
                        <div><p>&nbsp;</p></div>
                        {renderContent()}
                    </>
                    : renderContent()
                }
            </Container>
        </>)
}
;

export default ErrorComponent;
