import React from 'react'
import {Row, Col} from "reactstrap";

const GeorgeSpinner = () => {

    return (
        <Row>
            <Col>
                <em className="g-spinner" aria-live="polite" aria-busy="true">
                    <span className="sr-only">Loading…</span>
                    <i></i><i></i><i></i><i></i><i></i>
                </em>
            </Col>
        </Row>
    );
};

export default GeorgeSpinner;