import { RootState } from "./types";

const initialState: RootState = { errorMessage: null, blockScreen: false, spinners: [], gaExternalId: null, gaPreviousStep: null, gaApplicationId: null};

const rootReducers = (state = initialState, action): RootState => {
    var newState;
    switch (action.type) {
        case 'BLOCK_SCREEN_WITH_MESSAGE':
            newState = Object.assign({}, {...state}, {errorMessage: action.message, blockScreen: true});
            return newState;
        case 'SHOW_ERROR_MESSAGE':
            newState = Object.assign({}, {...state}, {errorMessage: action.message});
            return newState;
        case 'HIDE_ERROR':
            newState = Object.assign({}, {...state}, {errorMessage: null});
            return newState;
        case 'SHOW_SPINNER':
            var spinnerToAdd = {name: action.data, active: true}
            var spinnersWithAdd = [...state.spinners, spinnerToAdd];
            newState = Object.assign({}, {...state}, {spinners: spinnersWithAdd});
            return newState;
        case 'HIDE_SPINNER':
            var spinnersCopy = [...state.spinners];
            for(var i = 0; i < spinnersCopy.length; i++) {
                if(spinnersCopy[i].name === action.data) {
                    spinnersCopy[i].active = false;
                }
            }

            newState = Object.assign({}, {...state}, {spinners: spinnersCopy});
            return newState;
        case 'GA_EXT_ID_LOADED': 
            newState = Object.assign({}, {...state}, {gaExternalId: action.extId});
            return newState;
        case 'GA_APPLICATION_CREATED': 
            newState = Object.assign({}, {...state}, {gaApplicationId: action.applicationId});
            return newState;
        case 'GA_SAVE_PREVIOUS_STEP': 
            newState = Object.assign({}, {...state}, {gaPreviousStep: action.activityName});
            return newState;

      default:
        return state;
    }
};

export default rootReducers;