import React, { lazy } from "react";
import {loadCodebookWithLang} from "../actions/taskActions";
import SessionWrapper from "../sessions/sessionWrapper";
import {addLabourDays} from "../common/reusableFunctions";

// Lazily loading "@csas-smart/gti-mnt-ui": 
const DisbursementOrdersField = lazy(() =>
    import("@csas-smart/gti-mnt-ui").then(module => ({ default: module.DisbursementOrdersField }))
);

/**
 * Wrapper for field that passes props that can only be created in this repository to the actual field in mnt-ui-comps.
 */
const DisbursementOrdersFieldContainer = (props) => {
    const sessionWrapper = new SessionWrapper();

    const loadCodebookWithLangHandler = (codebook, attributes, lang) => {
        return loadCodebookWithLang(codebook, attributes, lang ? lang : sessionWrapper.getLanguage());
    };

    return <DisbursementOrdersField {...props} loadCodebookWithLangHandler={loadCodebookWithLangHandler} addLabourDays={addLabourDays}/>
};

export default DisbursementOrdersFieldContainer;