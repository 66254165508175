import {useState} from 'react';

/**
 * This container is useful for click-sensitive buttons - eg buttons, that perform HTTP request.
 * When button is onClick is called, component sets its state to disabled. When promise is resolved, it sets state to enabled.
 * Therefore, when onClick is called multiple times (double click for instance), the action is performed jus once.
 * Currently, this container is used for footer components - boolean-field, toggle-group-field.
 *
 * PROPS:
 *      render - render props design pattern - any component you want to render.
 *      handleChange - function to be called just once. Function must return promise.
 *
 * **/

const OnceClickableComponentContainer = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const onClick = (...fnProps) => {
        if (isDisabled) {
            return;
        }
        setIsDisabled(true);
        props.handleChange(...fnProps)
            .then(() => {setIsDisabled(false)}
                )
            .catch(() => {setIsDisabled(false)})
    };

    return props.render(isDisabled, onClick);
};

export default OnceClickableComponentContainer;