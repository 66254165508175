import {decodeBase64} from "../../utils/objectUtils";
import SessionWrapper from "../../../sessions/sessionWrapper";
import {GRG_GO_PARAM_VALUE, GRG_PARAM_VALUE} from "../oidcService";

/**
 * State manager is used for handling request query parameteres specific for George application. Example of
 * these parameteres:
 *  returnState
 *  successURL
 *  errorURL
 *  ...
 *
 * state can hold parameters like caseType, salesChannel and many other parameteres. These are not valid with this state manager
 * and this manager not process them
 */
class GeorgeSalesChannelManager {
    sessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    /**
     * state manager can be used when 'state' parameter exists in request query parameters 'params' and this state
     * contains at least one of 'returnState' or 'successURL' parameters
     * @param params
     * @return {boolean}
     */
    canHandle(params) {
        const {state} = params;

        if(!state) {
            return false;
        }


        return this.validState(state);
    }

    /**
     * handle request query params in case of 'canHandle' returns 'true'
     * @param params
     */
    handleParams(params){
        if(!this.canHandle(params)) {
            return;
        }
        const {state} = params;

        const decodedState = decodeBase64(state);
        this.resolveSalesChannel(decodedState);
    }

    /**
     * State is valid if (and only if) there is at least one of 'returnState' or 'successURL' parameters in it
     * @param state - Base64 encoded George state
     * @return {boolean}
     */
    validState = (state) => {
        const decodedState = decodeBase64(state);
        return decodedState.returnState != null || decodedState.successURL != null;
    }

    resolveSalesChannel = (decodedState) => {
        if(!decodedState.caseType) {
            this.sessionWrapper.setSalesChannel(GRG_PARAM_VALUE);
        } else {
            //decoded state contoins caseType param - mark it as "GEORGE_GO"
            this.sessionWrapper.setSalesChannel(GRG_GO_PARAM_VALUE);
        }
    }
}

export default GeorgeSalesChannelManager;
