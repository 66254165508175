import React from "react";
import RestCaller from "../core/restCaller";
import Resource from "../core/serverresource";
import {GraphQLField} from '@csas-smart/gti-ui-comps';
import {useDispatch, useSelector} from "react-redux";
import {blockScreen, hideSpinner, showSpinner} from "../actions/taskActions";
import {resolveHashIdFromState} from "../core/utils/taskUtils";
import PropertiesLoader from "../core/propertiesloader";
import { RootState } from "../core/store";

const GraphqlFieldContainer = (props) => {
    const dispatch = useDispatch();

    const spinners = useSelector<RootState>((state) => state.root?.spinners)
    const hashId = resolveHashIdFromState();
    const allAttributes = useSelector<RootState>(state => state.task.activity.attributes);

    const {name} = props.field;
    const module = PropertiesLoader.getProperty(props.field.editor, "module", "NGRUNTIME");

    const graphqlCall = () => RestCaller.httpPost(Resource.graphql(hashId, name, module), allAttributes); //getData function

    const showSpinnerHandler = (name) => {
        dispatch(showSpinner(name));
    }

    const hideSpinnerHandler = (name) => {
        dispatch(hideSpinner(name));
    }

    const blockScreenHandler = (errMsg) => {
        dispatch(blockScreen(errMsg));
    }

    return <GraphQLField {...props}
                         showSpinner={showSpinnerHandler}
                         hideSpinner={hideSpinnerHandler}
                         blockScreen={blockScreenHandler}
                         getData={graphqlCall}
                         spinners={spinners}
    />
}

export default GraphqlFieldContainer;
