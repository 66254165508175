import React from "react";
import {SscsConditionsCheckField} from "@csas-smart/gti-bop-ui";
import {useDispatch} from "react-redux";
import {deleteDocument, uploadFiles} from "../../actions/documentActions";
const SscsConditionsCheckFieldContainer = (props) => {
    const { field } = props;

    const dispatch = useDispatch();

    const uploadConditionFilesHandler = (fieldName, attrName, files) => {
        return dispatch(uploadFiles(field.name, attrName, files));
    }

    const deleteConditionDocumentHandler = (attrName, document) => {
        return dispatch(deleteDocument( field.name, attrName, document));
    }

    return (
            <SscsConditionsCheckField {...props}
                                      uploadDocument={uploadConditionFilesHandler}
                                      deleteDocument={deleteConditionDocumentHandler}
            />
    )
}

export default SscsConditionsCheckFieldContainer;