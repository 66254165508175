import React, { useState } from "react";
import { Button } from '@george-labs.com/design-system';
import TogglePopup from "../core/popups/togglePopup";


const ToggleButton = (props) => {
    const { option, width, disabled, validate, withPopup, onClick } = props;
    const [isModalOpen, setModalOpen] = useState(false);

    const triggerOpenModal = (bool) => {
        return setModalOpen(bool);
    };

    const onClickLocal = (e) => {
        const { key, buttonType } = option;
        if (withPopup && key === withPopup.key) {
            return triggerOpenModal(true);
        }

        // If user hit label, the target would be the span and not the button - get target data from parent node.
        if (!e.target.value) {
            e.target.value = key;
        }

        // If user hit label, the target would be the span and not the button - get target data from parent node.
        if (!e.target.buttonType) {
            e.target.buttonType = buttonType;
        }

        if (onClick) {
            onClick(e, option.logout);
        }
    }

    const getStyle = () => {
        if (width && width > 0) {
            return { width: width + 'px' };
        }

        return null;
    };

    return (
        <>
            <Button variant={option.buttonType} disabled={disabled} onClick={onClickLocal} value={option.key}
                style={getStyle()} onBlur={validate}>
                {option.label}
            </Button>
            <TogglePopup name={""} setOpen={triggerOpenModal} isOpen={isModalOpen} withPopup={withPopup} onClick={onClick} />
        </>
    );
}

export default ToggleButton;
