/**
 * Function decides, whether the provided argument is not an empty array.
 * The array is considered not to be empty, if it is defined and contains at least one item.
 * @param arg
 */
export function isNotEmptyArray(arg: any[] | undefined | null) {
  return Array.isArray(arg) && arg.length > 0;
}

/**
 * Function decides, whether the provided argument is empty array or not.
 * As empty array is considered: [], null, undefined.
 * @param arg Argument to be tested.
 */
export function isEmptyArray(arg: any[] | undefined | null) {
  return !isNotEmptyArray(arg);
}
