import React, { lazy } from "react";
import { useDispatch } from 'react-redux';
import {wizardStoreDispositionRights, checklistStoreDispositionRights, checklistDownloadDoc} from "../../actions/g4bmigrActions";
import {resolveCaseTypeFromState} from '../../core/utils/taskUtils';

/**
 * Lazily loading "@csas-smart/gti-g4bmigr-ui": 
 */ 
const RepresentativeAuthorizationField = lazy(() =>
    import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.RepresentativeAuthorizationField }))
);

const RepresentativeAuthorizationContainer = (props) => {

    const caseType = resolveCaseTypeFromState();
    const dispatch = useDispatch();

    const onChange = (requestDto) => {
        console.log('RepresentativeAuthorizationContainer - onChange called, caseType: ', caseType);

        if (caseType === "G4BMIGRWIZ") {
            console.log('RepresentativeAuthorizationContainer - caseType G4BMIGRWIZ');

            //dispatch(wizardStoreDispositionRights(requestDto, props.field.selector.attributes.dispositionRights.name));
            dispatch(wizardStoreDispositionRights(requestDto));
        } else if (caseType === "G4BMIGRCHKL") {
            console.log('RepresentativeAuthorizationContainer - caseType G4BMIGRCHKL');

            dispatch(checklistStoreDispositionRights(requestDto, props.field.selector.attributes.dispositionRights.name));
            //dispatch(checklistStoreDispositionRights(requestDto));
        } else {
            console.log('RepresentativeAuthorizationContainer - Unsupported caseType: ', caseType);
        }
    };

    const onDownload = () => {
        console.log('RepresentativeAuthorizationContainer - onDownload called');

        return dispatch(checklistDownloadDoc());
    };

    return (
        <RepresentativeAuthorizationField {...props} onChange={onChange} onDownload={onDownload}/>
    )
};

export default RepresentativeAuthorizationContainer;
