import React from "react";
import { FeedbackField } from "@csas-smart/gti-ui-comps";
import RestCaller from "../core/restCaller";
import Resource from "../core/serverresource";
import { resolveHashIdFromState } from "../core/utils/taskUtils";

const FeedbackFieldContainer = (props) => {

    const { field } = props;
    const hashId = resolveHashIdFromState();

    const completeModalHandler = (request) => {
        RestCaller.httpPost(Resource.postEmoticonFeedback(field.name, hashId), request)
            .then(result => {
                console.log(result);
            })
            .catch((error) => {
                console.error("Error during saving feedback value: " + request.value + " and feedback note: " + request.note + ". Message: " + error);
            });
    };

    return <FeedbackField {...props} completeModalHandler={completeModalHandler}/>
};

export default FeedbackFieldContainer;