import React from "react";
import { useDispatch } from "react-redux";
import {fireCaseCancelled, toggleFieldUpdated} from "../actions/taskActions";
import authService from "../core/auths/authService";
import { ToggleGroupField } from "@csas-smart/gti-ui-comps";

/**
 * Component representing an togglegroup. For additional info please visit: https://gti-library-int.csint.cz/#/fields/togglegroup-field
 */
const ToggleGroupFieldContainer = (props) => {
    const dispatch = useDispatch();

    const handleToggleFieldUpdated = (attrName, attrValue) => {
        dispatch(toggleFieldUpdated(attrName, attrValue));
    }

    const handleFireCancelCase = () => {
        dispatch(fireCaseCancelled(true))
        authService.getService().handover();
    }

    return <div className="alerts-field">
        <ToggleGroupField {...props} fireCaseCancelled={handleFireCancelCase} toggleFieldUpdated={handleToggleFieldUpdated}/>
    </div>
};

export default ToggleGroupFieldContainer;