import {ADFS_CLIENT} from "../adfs2016service";

import Resource from '../../serverresource';
import SessionWrapper from "../../../sessions/sessionWrapper";
import {decodeBase64} from "../../utils/objectUtils";

/**
 * Handler is used in case of adfsCodeHandler was successfully processed and another step is get accessToken and link it
 * with current cluid parameter in state.
 */
class AdfsCodeHandler {

    sessionWrapper: SessionWrapper;
    
    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    /**
     * Handler can be used if and only if there is the 'code' URL parameter and there is NOT 'access_token' URL parameter at the same time.
     * Ofcourse there must not be accessToken stored in sessionStorage.
     * @param params
     * @returns {*|boolean}
     */
    canHandle = (params) => {
        return params?.code && !params?.access_token && !this.sessionWrapper.getAccessToken();
    }

    /**
     * Handler takes the 'code' URL parameter and call ADFS for get accessToken. When it receives token successfully then
     * it link 'cluid' parameter through OBO enpoint and refresh accessToken
     * @param params
     * @returns {Promise<void>}
     */
    handleParams =  (params) => {
        const { state } = params;
        const decodedState = decodeBase64(state);
        const { hashroute } = decodedState;
        this.sessionWrapper.setLoginCodeReceived();

        return this.handleAccessToken(params.code)
            .then(() => {
                this.sessionWrapper.clearLoginCodeReceived();
                const urlParamSeparator = hashroute?.indexOf('?') > -1 ? "&" : "?";
                window.location.replace(`${import.meta.env.VITE_APP_BASE_URL}${hashroute}${urlParamSeparator}state=${state}`);
            })
    }

    /**
     * helper method for storing token data into sessionStorage
     * @param data
     */
    setTokenData = (data) => {
        if(!data) {
            return Promise.resolve();
        }

        this.sessionWrapper.setAccessToken(data.access_token);
        this.sessionWrapper.setRefreshToken(data.refresh_token);
        Resource.setInterceptor();
        return Promise.resolve();
    }

    /**
     * Helper method for API call for receiving accessToken
     * @param code
     * @param saleschannel
     * @returns {Promise<void>}
     */
    handleAccessToken = (code) => {
        const request = {
            code: code,
            clientId: ADFS_CLIENT,
            scope: "openid",
            redirectUri: import.meta.env.VITE_FE_APP_URL
        };

        return fetch(Resource.getAdfs2016Token(), {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "web-api-key" : import.meta.env.VITE_APP_BTI_API_KEY
            },
            method: 'POST',
            body: JSON.stringify(request)
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then(this.setTokenData)
            .catch(err => {
                console.error("Error adfs token", err);
            })
    }

    refreshAccessToken = () => {
        const request = {
            clientId: ADFS_CLIENT,
            scope: 'openid',
            refreshToken: this.sessionWrapper.getRefreshToken(),
            grantType: 'refresh_token'
        };

        return fetch(Resource.refreshAdfs2016Token(), {
                headers: {
                    "Content-Type" : "application/json",
                    "Accept": "application/json"
                },
                method: "POST",
                body: JSON.stringify(request)
            })
                .then(Resource.checkStatus)
                .then(Resource.parseJSON)
                .catch(err => {
                    console.log(err);
                });


    };
}

export default AdfsCodeHandler;
