import React, { lazy } from "react";
import { useDispatch } from 'react-redux';
import {
    checklistErrorCase,
    checklistGetContractStatus,
    checklistGetData,
    checklistMigrateDocuments,
    checklistSetContractsSigned
} from "../../actions/g4bmigrActions";
import SessionWrapper from '../../sessions/sessionWrapper';

/**
 * Lazily loading "@csas-smart/sigma-ui": 
 */ 
const SigmaApiConfigWrapper = lazy(() =>
    import("@csas-sigma/sigma-ui").then(module => ({ default: module.SigmaApiConfigWrapper }))
);
/**
 * Lazily loading "@csas-smart/gti-g4bmigr-ui": 
 */ 
const DocumentsToSignField = lazy(() =>
    import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.DocumentsToSignField }))
);

const DocumentsToSignFieldContainer = (props) => {

    const dispatch = useDispatch();

    const sessionWrapper = new SessionWrapper();

    const apiConfig = {
        env: import.meta.env.VITE_ENV.toUpperCase(),
        webApiKey: import.meta.env.VITE_APP_API_KEY,
        accessToken: sessionWrapper.getAccessToken()
    }

    const onConfirmDocSigned = (requestDto) => {
        console.log('DocumentsToSignFieldContainer - onConfirmDocSigned called');

        return dispatch(checklistMigrateDocuments(requestDto));
    };

    const checkContractsStatus = (requestDto) => {
        console.log('DocumentsToSignFieldContainer - checkContractsStatus called');

        return dispatch(checklistGetContractStatus(props.field.selector.attributes.generatedContracts.name, requestDto));
    }

    const getContractsStatusCheckInterval = () => {
        return 5;
    }

    const setContractsSigned = (requestDto) => {
        console.log('DocumentsToSignFieldContainer - setContractsSigned called');

        return dispatch(checklistSetContractsSigned(requestDto));
    }

    const createErrorCase = (requestDto) => {
        console.log('DocumentsToSignFieldContainer - createErrorCase called');

        return dispatch(checklistErrorCase(requestDto));
    }

    const updateData = () => {
        console.log('DocumentsToSignFieldContainer - updateData called');

        return dispatch(checklistGetData());
    }

    return (
        <SigmaApiConfigWrapper {...props} accessToken={apiConfig.accessToken} environment={apiConfig.env} webApiKey={apiConfig.webApiKey}>
            <DocumentsToSignField
                {...props} 
                checkContractsStatus={checkContractsStatus}
                onConfirmDocSigned={onConfirmDocSigned}
                setContractsSigned={setContractsSigned}
                checkIntervalSeconds={getContractsStatusCheckInterval()}
                createErrorCase={createErrorCase}
                updateData={updateData}
            />
        </SigmaApiConfigWrapper>
    )
};

export default DocumentsToSignFieldContainer;
