import SessionWrapper from "../../sessions/sessionWrapper";

/**
 * USED FOR DEBUG PURPOSES ONLY
 * enhancer sets the 'web-api-cluid' header to value requested as url parameter 'hidden_cluid'.
 * For correct usage it is important to set property VITE_APP_AUTH_HEADER in current .env file to 'hidden_cluid' value. Default .env value is empty value.
 */
class WebApiCluid {

    sessionWrapper: SessionWrapper;
    
    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    enhanceHeaders(headers) {
        if(!import.meta.env.VITE_APP_AUTH_HEADER || !window.sessionStorage.getItem(import.meta.env.VITE_APP_AUTH_HEADER)){
            return headers;
        }

        if(!headers) {
            headers = {};
        }

        return {...headers, [import.meta.env.VITE_APP_AUTH_HEADER]: window.sessionStorage.getItem(import.meta.env.VITE_APP_AUTH_HEADER)};
    }
}

export default WebApiCluid;
