import {resolveHashIdFromState} from "../core/utils/taskUtils";
import React from "react";
import ReactDOM from 'react-dom';
import LinkToFile from "../comp/link-to-file";
import {findLinksToFiles, replaceLinksToFile} from "../common/templateHandling";
import {getHandlebars} from "../core/handlebars";
import { isNotEmptyString } from "validations";

// Lazily loading "handlebars" package: 
/* const getHandlebars = lazy(() =>
    import("../core/handlebars").then(module => ({ default: module.getHandlebars }))
  );*/

export const templateGenerated = (name, template, populatedTemplate) => ({ type: 'TEMPLATE_CHANGED', payload: { name, template, populatedTemplate } });
// export const regenerateTemplates = (payload) => ({type: 'REGENERATE_TEMPLATES', payload: payload});

/**
 * Function to trigger templates rerendering. The function is triggered each time an attribute is changed. Function scans the templates stored in the templates
 * part of the redux store (the not-evaluated representation) and if it contains the name of the changed attribute, it triggers the rerendering against new redux state.
 * @param {*} attr that was changed.
 * @returns triggers a TEMPLATE_CHANGED actions for affected templates.
 * Hint: If this approach would be too costly, it is possible to work with the watchedAttributes property, which is currently not beeing used.
 */
export function regenerateTemplates(attr) {
    return (dispatch, getState) => {
        var templatesForGeneration = getState().templates?.templates;
        // iterate all stored templates
        Object.keys(templatesForGeneration).forEach(key => {
            // check if template contains the changed attribute if so, trigger the reevaluation.
            if (isNotEmptyString(templatesForGeneration[key].template) &&
                templatesForGeneration[key].template.indexOf(attr.name) >= 0) {
                dispatch(generateTemplate(key, templatesForGeneration[key].template));
            }
        });

    }
}

/**
 * Function to rerender the static document placeholder for the actual React component.
 * @param {*} template The original template, that might contain several links to static documents.
 * @param {*} hashId The hashId of the task the template is rendered in.
 */
function rerender(template, hashId){
    const linksToRerender = findLinksToFiles(template);
    linksToRerender.forEach(link => {
        ReactDOM.render(
            <LinkToFile id={link.id} label={link.label} hashId={hashId}/>, document.getElementById(link.id)
        );
    });
}

/**
 * Function for generating a template with current data context. The original templates gets compiled by handlebars and is evaluated against
 * current application data context.
 * @param {*} id of the template to be generated.
 * @param {*} template with dynamic handlebars sections that is about to be generated.
 * @returns dispatches a TEMPLATE_CHANGED action on to a redux store.
 */
export function generateTemplate(id, template) {
    return (dispatch, getState) => {
        const attributes = getState().task?.activity?.attributes;
        const actor = getState().user?.actor;
        return populateTemplate(template, attributes, actor)
            .then((populatedTemplate) => {
                return dispatch(templateGenerated(id, template, populatedTemplate));
            })
            .then((response) => {
                // Rerender must be performed due to possible static documents ().
                rerender(template, resolveHashIdFromState());
                return new Promise((resolve) =>
                    resolve(response)
                );
            })
    }
}

/**
 * Function to populate a template with current application context. The function compiles the handlebars template and evaluates it against the current application context.
 * @param {*} template with dynamic handlebars sections that is about to be generated.
 * @param {*} attributes retrieved from the current redux state.
 * @param actor actor object retrieved from the current redux state.
 * @returns populated template with evaluated handlebars sections.
 */
export function populateTemplate(template, attributes, actor) {
    const cTemplate = getHandlebars().compile(template);
    return cTemplate(getTemplateDataContext(attributes, actor))
        .then(populatedTemplate => {
            // We need to create a placeholder for the static documents (linkToFile handlebar function).
            return replaceLinksToFile(populatedTemplate);
        });
}

/**
 * Function for composing current application context. Function loads business data from the redux store (case attributes) and enhances them with predefined non-business values (such as _hashId of the george task) and actor value.
 * @param {*} attributes retrieved from the current redux state.
 * @param actor actor object retrieved from the current redux state.
 * @returns the context the handlebars can evaluate the templates with.
 */
function getTemplateDataContext(attributes, actor) {
    let data = {};
    // add hashId to context
    const hashId = resolveHashIdFromState();
    data = Object.assign(data, {'_hashId': hashId});

    if (attributes) {
        // attributes must be converted into a name-value format.
        for(let i = 0; i < attributes.length; i++) {
            data[attributes[i].name] = attributes[i].value;
        }
    }
    if (actor) {
        data["_actor"] = actor;
    }

    return data;
}