import { TemplateState } from './types';
import { isNotEmptyString } from "validations";

const initialState: TemplateState = { templates: {}};

const templateReducers = (state = initialState, action): TemplateState => {
    var newState;
    switch (action.type) {
        case 'TEMPLATE_CHANGED':
            var templateId = action.payload.name;
            var templatesCopy = {...state.templates};

            templatesCopy[templateId] = {
                template: action.payload.template,
                stringValue: action.payload.populatedTemplate,
                regenerated: true
            };

            newState = Object.assign( {}, state, {templates: templatesCopy});
            return newState;
        case 'REGENERATE_TEMPLATES':
            var templatesForGeneration = {...state.templates};
            Object.keys(templatesForGeneration).forEach(key => {
                if(isNotEmptyString(templatesForGeneration[key].template) &&
                    templatesForGeneration[key].template.indexOf(action.payload.name) >= 0) {

                    templatesForGeneration[key].regenerated = false;
                }
            });

            newState = Object.assign( {}, state, {templates: templatesForGeneration});
            return newState;
        case 'ACTIVITY_DESIGN_LOADED':
            return {...initialState};
            //TODO - toto podle me nepotrebujeme - smazat po releasu "crab"
        case 'CLOSE_ACTIVITY':
            return {...initialState};

      default:
        return state;
    }
};

export default templateReducers;