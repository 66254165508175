import Resource from '../core/serverresource';
import RestCaller from "../core/restCaller";
import logger from "../core/loggly/gtiLogger";

export const actorLoaded = (result) => ({type: 'ACTOR_LOADED', actor: result});

export function loadActor() {
    return (dispatch) => {
        return RestCaller.httpGet(Resource.loadActor())
            .then(result => {
                dispatch(actorLoaded(result));
                return Promise.resolve();
            })
            .catch(error => {
                logger.error("Error in loading actor: " + error);
                throw error;
            });
    }
}

export function getUserInfo(hashId) {
    return RestCaller.httpGet(Resource.getUserInfo(hashId))
        .then(response => {return response})
        .catch(() => {return "";});
}

export function searchUsers(hashId, request) {
    return RestCaller.httpPost(Resource.searchUsers(hashId), request)
}
