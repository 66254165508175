import { isEmptyString } from "validations";

/**
 * Function validates, whether registration number is in correct format.
 * The ičo must be composed of 8 digits, where the last one is a control digit based on modulo.
 * @param {string} registrationNumber to be validated.
 */
export function isValidRegistrationNumber(registrationNumber: string | undefined | null) {
  // Check for empty string
  if (isEmptyString(registrationNumber)) {
    return false;
  }

  // Check eight digits:
  const regNoRegex = /^\d{8}$/;
  // @ts-ignore (it actually is checked by the isEmptyString method)
  if (!regNoRegex!.test(registrationNumber)) {
    return false;
  }

  // Check the control digit content:
  let product = 0;
  for (let i = 0; i < 7; i++) {
    // @ts-ignore (it actually is checked by the isEmptyString method)
    const index = +registrationNumber[i];
    product += index * (8 - i);
  }
  let controlDigit = (11 - (product % 11)) % 10;
  // @ts-ignore (it actually is checked by the isEmptyString method)
  const expectedControlDigit = +registrationNumber[7]; // The '+' is necessary for casting string to number.
  return controlDigit === expectedControlDigit;
}