import React from "react";
import Icon from "../icon";

/**
 * Wrapper is used only for handling lazy loading of George icons.
 * */

const IconWrapper = (props) => {
  return (
          <Icon {...props}/>
  )
};

export default IconWrapper;