import React from "react";
import {NgRecordItem} from "./record-item";

export const NgPrintComponent = (props) => {
    const {records, field} = props;

    if(!records || !(records instanceof Array)) {
        return null;
    }

    return records.map((record,index) => (<NgRecordItem key={'record-item-' + index} record={record} field={field}/>))

}
