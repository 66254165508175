import { useEffect } from "react";
import Resource from './serverresource';
import TagManager from 'react-gtm-module'
import GaDataLayer from './googleAnalytics/gaDataLayer';
import { gaApplicationCreated, gaExternalIdLoaded } from "../actions/taskActions";
import { isMobileApp } from "./utils/salesChannelUtils";
import SessionWrapper from "../sessions/sessionWrapper";
import { CurrentActivity } from "../types/design";
import { useAppDispatch, useAppSelector } from "./hooks/hooks";


/**
 * GoogleAnalyticsTagManager component takes care of listening to events and publishing events into the GA4 library.
 */
const GaTagManager = () => {

    const activityDesign: CurrentActivity = useAppSelector(state => state.task.activity);
    const taskStatus = useAppSelector(state => state.task?.georgeTask?.taskStatus);
    const gaExtId: string = useAppSelector(state => state.root.gaExternalId);
    const gaApplicationId = useAppSelector(state => state.root.gaApplicationId);
    const gaPreviousStep = useAppSelector(state => state.root.gaPreviousStep);
    const blockScreen = useAppSelector(state => state.root.blockScreen);
    const dispatch = useAppDispatch();

    /**
     * Function for loading the 'externalID' parameter.
     * This parameter is dynamically loaded from the MILA endpoint and should be unique across one client session
     * in multiple applications. 
     * @returns ExternalID identificator. 
     */
    const getExternalId = () => {
        const payload = { "identifier": null };
        return fetch(Resource.getExternalId(), {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then(result => result.identifier)
            .catch(ex => {
                console.log("Failed to load external Id due to: ", ex);
                return Promise.resolve("unknown");
            })
    }

    /**
     * Function that evaluates, wheter this process should log into GoogleAnalytics or not. 
     * It is turned on if the GeorgeTask contains configured the GA section.
     * @returns Boolean indicating, whether logging into GA is active.
     */
    const isGaActive = () => {
        return activityDesign.googleAnalyticsConfiguration;
    }

    /**
     * Function that evaluates, whether the GA are ready for logging.
     * The reason for this is, that proir to logging an externalId needs to be handled and first event must be 
     * the 'initial event'. After this takes place, the GA are considered as initiated and standard logging can take place.
     * @returns Boolean indicating, whether standard logging functions should take place. 
     */
    const isGaInitiated = () => {
        return isGaActive() && gaExtId;
    }

    /**
     * Function that evaluates, whether the 'application' event has been already submitted. 
     */
    const isApplicationInitiated = () => {
        return isGaActive() && gaApplicationId;
    }

    /**
     * Use effect that triggers 'initial' event and 'application_started' event. 
     * The method firstly initialize the TagManager and then publishes the initial event and application started event.
     */
    useEffect(() => {
        if (isGaActive() && !isGaInitiated()) {
            const sessionWrapper = new SessionWrapper();
            // Initialize the TagManager.
            const tagManagerArgs = {
                gtmId: 'GTM-PDCG26W'
            }
            TagManager.initialize(tagManagerArgs);

            // Obtain the externalId
            getExternalId().then((externalId) => {

                // Trigger the 'initial_event'
                GaDataLayer.createInitialEvent({
                    extID: externalId,
                    pageLayout: isMobileApp() ? "mobil" : "desktop",
                    referralChannel: activityDesign.googleAnalyticsConfiguration.googleAnalyticsTaskConfiguration.referralChannelExpression,
                    referralDisplayType: activityDesign.googleAnalyticsConfiguration.googleAnalyticsTaskConfiguration.referralDisplayTypeExpression,
                    webBusinessSection: activityDesign.googleAnalyticsConfiguration.googleAnalyticsTaskConfiguration.webBusinessSectionExpression,
                    webLanguageVersion: sessionWrapper.getLanguage().toLowerCase() === "cs" ? "čeština" : "english",
                    productName: activityDesign.googleAnalyticsConfiguration.googleAnalyticsTaskConfiguration.productName,
                    productID: activityDesign.googleAnalyticsConfiguration.googleAnalyticsTaskConfiguration.productID,
                    segment: activityDesign.googleAnalyticsConfiguration.googleAnalyticsTaskConfiguration.segment,
                    topLevelCategoryName: activityDesign.googleAnalyticsConfiguration.googleAnalyticsTaskConfiguration.topLevelCategoryName,
                });

                // Dispatch action
                dispatch(gaExternalIdLoaded(externalId));
            })
        }
    }, [activityDesign]);

    /**
     * Effect that automatically creates the 'page_view' event every time the new activity is loaded. 
     * Also the 'application' event is triggered afterwards (only once) - the reason for this is, that the 
     * 'application' event must be submitted AFTER the 'page_view' event (do not know why, does not make sence..)
     */
    useEffect(() => {
        if (isGaInitiated()) {
            GaDataLayer.createPageViewEvent({ screenTitle: activityDesign.design.name });
            
            // Submit 'application' event, if it has not been submitted yet.
            if (!isApplicationInitiated()){
                GaDataLayer.createApplicationEvent({ status: "started", applicationID: activityDesign.googleAnalyticsConfiguration.cidla })
                // Dispatch action
                dispatch(gaApplicationCreated(activityDesign.googleAnalyticsConfiguration.cidla));
            }
        }
    }, [activityDesign, gaExtId]);

    /**
     * Effect that creates the 'business_phase' event every time new business phase is encountered. 
     */
    useEffect(() => {
        if (isGaInitiated()) {
            if (activityDesign.googleAnalyticsConfiguration.googleAnalyticsActivityConfiguration) {
                activityDesign.googleAnalyticsConfiguration.googleAnalyticsActivityConfiguration.businessPhases.forEach(phase => {
                    GaDataLayer.createBusinessPhase(phase);
                })
            }
        }
    }, [activityDesign, gaExtId]);

    /**
     * Effect that creates the custom 'page_error' event every time an activity with explicit page_error configuration is encountered.
     * This allows for activity to be marked as an error one (even though it is not a block screen, but rather business-driven issue).
     */
        useEffect(() => {
            if (isGaInitiated()) {
                if (activityDesign.googleAnalyticsConfiguration.googleAnalyticsActivityConfiguration?.errorPageEvent) {
                    GaDataLayer.createPageErrorEvent(
                        {
                            eventDescription: activityDesign.googleAnalyticsConfiguration.googleAnalyticsActivityConfiguration.errorPageEvent.eventDescription,
                            screenTitle: activityDesign.googleAnalyticsConfiguration.googleAnalyticsActivityConfiguration.errorPageEvent.screenTitle,
                            pageErrorTitle: gaPreviousStep,
                            pageErrorLocation: "/zadost"
                        }
                    );
                }
            }
        }, [activityDesign, gaExtId]);

    /**
     * Effect that creates the 'business_phase' event every time new business phase is encountered. 
     */
    useEffect(() => {
        if (blockScreen) {
            GaDataLayer.createPageErrorEvent(
                {
                    eventDescription: "Z tohoto důvodu aktuálně nelze pokračovat online. Budeme rádi, když to zkusíte později znovu.",
                    screenTitle: "Omlouváme se, nastala technická chyba",
                    pageErrorTitle: gaPreviousStep,
                    pageErrorLocation: "/zadost"
                }
            )
        }
    }, [blockScreen]);


    /**
     * Effect that create 'application' event, when the GeorgeTask is finished.
     */
    useEffect(() => {
        if (isGaInitiated() && taskStatus === "COMPLETED") {
            GaDataLayer.createApplicationEvent({
                extID: gaExtId,
                status: "finished",
                applicationID: activityDesign.googleAnalyticsConfiguration.cidla
            })
        }
    }, [taskStatus, activityDesign?.googleAnalyticsConfiguration?.cidla])

    return null; // No view for you, but I do ton of other stuff. 
}

export default GaTagManager;