import PropertiesLoader from "../propertiesloader";

const defaultExtensions = ["doc", "docx", "dotm", "xls", "xlsx", "jfif", "jfif-tbnl", "jpe", "jpeg", "jpg", "png", "gif", "pdf", "zip"];

export function resolveFileExtensions(field) {
    if (!field) {
        return defaultExtensions;
    }

    return PropertiesLoader.getProperty(field.editor, "supportedFileExtensions", defaultExtensions)
        .map(ext => ext.indexOf('.') >= 0 ? ext : '.' + ext);
}