import LogglyMessage from "./logglyMessage";
class LogglyMessageString extends LogglyMessage {
    message: any;
    
    constructor(msg) {
        super();

        this.type = "STRING";
        this.message = msg;
    }
}

export default LogglyMessageString;
