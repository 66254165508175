import React from "react";
import {NavigationField} from "@csas-smart/gti-ui-comps";
import authService from "../core/auths/authService";
import {
    fireActivityComplete,
    fireActivityCompletionFailed,
    fireCaseSaved,
    fireLoadActivityDesign,
} from "../actions/taskActions";
import {useAppDispatch, useAppSelector} from "../core/hooks/hooks";
import SessionWrapper from "../sessions/sessionWrapper";

const NavigationFieldContainer = (props: any) => {
    const dispatch = useAppDispatch();
    const currentActivity = useAppSelector((state) => state.task.activity);
    const sessionWrapper = new SessionWrapper()

    const handleLogout = () => {
        return authService.getService().logout();
    };

    const handleRevoke = () => {
        return authService.getService().revoke();
    };

    const handleSave = async () => {
        await dispatch(fireCaseSaved());
        await dispatch(fireLoadActivityDesign());
    };

    const handleComplete = (withHandover: boolean, context: any) => {
        const enhancedContext = Object.assign({activityName: currentActivity.design.name}, context);
        if (withHandover) {
            return props.completeActivityAction(context);
        } else {
            const componentsAreValid = context?.buttonType === "secondary" ? true : props.validateActivityAction();

            if (componentsAreValid) {
                return dispatch(fireActivityComplete(enhancedContext));
            } else {
                return dispatch(fireActivityCompletionFailed());
            }
        }
    };

    return <NavigationField onLogout={handleLogout}
                            onRevokeToken={handleRevoke}
                            onSave={handleSave}
                            onComplete={handleComplete}
                            salesChannel={sessionWrapper.getSalesChannel()}
                            {...props} />;
};

export default NavigationFieldContainer;