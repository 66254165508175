import React from "react";
// import { MsTeamsVideoCallField } from "gti-rebox-comp";
// import RestCaller from "../core/restCaller";
// import Resource from "../core/serverresource";

// function getACSBankerData() {
//     return RestCaller.httpGet(Resource.getACSBankerData());
// }

// const MsTeamsVideoCallFieldContainer = (props) => {
const MsTeamsVideoCallFieldContainer = () => {

    // return <MsTeamsVideoCallField  getACSBankerData={getACSBankerData} {...props}/>
    return <p>TODO MS Teams video call component</p>;
};

export default MsTeamsVideoCallFieldContainer;