import React from "react";
import {ThemeProvider} from "@george-labs.com/design-system";

// Lazy loaded GDS dependency
// const ThemeProvider = React.lazy(() => import("@george-labs.com/design-system").then(module => ({default: module.ThemeProvider})))
// const THEME = React.lazy(() => import("@george-labs.com/design-system").then(module => ({default: module.ThemeProvider.THEME})))

/**
 * The wrapper around 'themeProvider' from GDS. Purpose of the wrapper is to lazy load GDS in order to separate GDS to its own package. 
 * 
 * @param {ReactElement} children    Content of the ThemeProvider. 
 */
const GdsThemeProvider = (props) => {

    return (
        <ThemeProvider theme={ThemeProvider.THEME.BS4}>
                {props.children}
        </ThemeProvider>
    )
}

export default GdsThemeProvider;