class LogglyMessage {
    userAgent;
    url;
    accessToken;
    type;
    hashId;
    sessionId;
}

export default LogglyMessage;
