import React, { Suspense } from "react";
import { getLocale } from "../i18n";
import enStrings from '../../locales/en/gds-translations.json';
import csStrings from '../../locales/cs-CZ/gds-translations.json';
import {LanguageProvider} from "@george-labs.com/design-system";

// Lazy loaded GDS dependency
// const LanguageProvider = React.lazy(() => import("@george-labs.com/design-system").then(module => ({default: module.LanguageProvider})))

/**
 * The wrapper around 'LanguageProvider' from GDS. Purpose of the wrapper is to lazy load GDS in order to separate GDS to its own package. 
 * Wrapper also sets GDS language and prepares inner translations, defined in gds-translations.json files.
 * 
 * @param {ReactElement} children    Content of the LanguageProvider. 
 */
const GdsLanguageProvider = (props) => {

    const language = getLocale();
    return (
        <Suspense fallback={null}>
            <LanguageProvider locale={language || "en"} strings={language === "cs" ? csStrings : enStrings}>
                {props.children}
            </LanguageProvider>
        </Suspense>
    )
}

export default GdsLanguageProvider;