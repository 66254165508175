import React from 'react';

const LoggingContext = React.createContext<LoggingContextProps>({});

export default LoggingContext;
export const LoggingProvider = LoggingContext.Provider;
export const LoggingConsumer = LoggingContext.Consumer;

export interface LoggingContextProps {
    logger?: any;
}