export default class defaultCt {

    constructor() {
    }

    isApplicable() {
        return true;
    }

    prepareLink(baseLink) {
        return baseLink;
    }
}
