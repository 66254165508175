import {SigmaSignMethodsField} from '@csas-smart/gti-sigma-ui';
import React from "react";
import SessionWrapper from '../../sessions/sessionWrapper';

const SigmaSignMethodsFieldContainer = (props) => {

    const sessionWrapper = new SessionWrapper();

    const apiConfig = {
        env: import.meta.env.VITE_ENV.toUpperCase(),
        webApiKey: import.meta.env.VITE_APP_API_KEY,
        accessToken: sessionWrapper.getAccessToken()
      }

    return (
        <SigmaSignMethodsField {...props} apiConfig={apiConfig} lang={sessionWrapper.getLanguage()}/>
    )
};

export default SigmaSignMethodsFieldContainer;