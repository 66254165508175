import React from "react";
import {SscsDisbursementOrdersBopField} from "@csas-smart/gti-bop-ui";
import {loadCodebookWithLang} from "../../actions/taskActions";
import SessionWrapper from "../../sessions/sessionWrapper";


const SscsDisbursementOrdersBopFieldContainer = (props) => {
    const sessionWrapper = new SessionWrapper();

    const loadCodebookWithLangHandler = (codebook, attributes, lang) => {
        return loadCodebookWithLang(codebook, attributes, lang ? lang : sessionWrapper.getLanguage());
    };

    return (
        <SscsDisbursementOrdersBopField {...props} loadCodebookWithLangHandler={loadCodebookWithLangHandler} />
    );
}

export default SscsDisbursementOrdersBopFieldContainer;
