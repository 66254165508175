import SessionWrapper from "../../sessions/sessionWrapper";
import {isUrlMep} from "../utils/urlUtils";
/* global APP_VERSION */   // -> Otherwise eslint does not know about the APP_VERSION variable and would trigger "no-undef" rule.

/**
 * enhancer sets the 'Accept-Language' header to resolved lang in i18n through the 'getLocale' method
 */
class FeVersion {
    HEADER_NAME = "fe-version";
    DEFAULT_VALUE = "unknown";

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    enhanceHeaders(headers, url) {
        if (!headers) {
            headers = {};
        }

        if (isUrlMep(url)) {
            return headers;
        }

        return {...headers, [this.HEADER_NAME]: APP_VERSION ? APP_VERSION : this.DEFAULT_VALUE};
    }
}

export default FeVersion;
