import React, { lazy } from "react";
import { useDispatch } from 'react-redux';
import {checklistErrorCase, checklistGenerateDoc, checklistGetContractStatus} from "../../actions/g4bmigrActions";

/**
 * Lazily loading "@csas-smart/gti-g4bmigr-ui": 
 */ 
const GenerateDocButtonsField = lazy(() =>
    import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.GenerateDocButtonsField }))
);

const GenerateDocButtonsFieldContainer = (props) => {

    const dispatch = useDispatch();

    const onGenerateDoc = (requestDto) => {
        console.log('GenerateDocButtonsFieldContainer - onGenerateDoc called');

        return dispatch(checklistGenerateDoc(requestDto));
    };

    const checkContractsStatus = (requestDto) => {
        console.log('GenerateDocButtonsFieldContainer - checkContractsStatus called');

        return dispatch(checklistGetContractStatus(props.field.selector.attributes.generatedContracts.name, requestDto));
    }

    const getContractsStatusCheckInterval = () => {
        return 5;
    }

    const createErrorCase = (requestDto) => {
        console.log('GenerateDocButtonsFieldContainer - createErrorCase called');

        return dispatch(checklistErrorCase(requestDto));
    }

    return (
        <GenerateDocButtonsField
            {...props}
            onGenerateDoc={onGenerateDoc}
            checkContractsStatus={checkContractsStatus}
            checkIntervalSeconds={getContractsStatusCheckInterval()}
            createErrorCase={createErrorCase}
        />
    )
};

export default GenerateDocButtonsFieldContainer;
