import base64 from "base64url";
import {objectKeysToLowerCase} from "./queryUtils";
import { isNotEmptyObject } from "validations";

export const hasObjectProerty = (obj, propertyName) => {
    return Object.keys(obj).filter(key => key === propertyName).length > 0;
};

/**
 * Method takes value as argument and make it double negatet for fix undefined/null value as "false" value
 * @param value
 * @returns {boolean}
 */
export const fixBooleanValue = (value) => {
    if(value === "true") {
        return true;
    }
    if(value === "false") {
        return false;
    }

    return !!value;
};

export const decodeBase64 = (encoded) => {
    if(!encoded || typeof encoded != "string") {
        return null;
    }

    console.log("encoded state: " + encoded);

    const uriDecoded = decodeUriState(encoded);
    console.log("GTI state after componentURI remove: " + uriDecoded);

    const decoded = base64.decode(uriDecoded);
    console.log("GTI State after decoding: " + decoded);

    /**
     * If result wrapped in {...} then return it as object. Otherwise return plain string
     */
    if(decoded.startsWith('{') && decoded.endsWith('}')) {
        return JSON.parse(decoded);
    } else {
        return decoded;
    }
}

export const encodeBase64 = (obj) => {
    if(!obj) {
        return null;
    }

    return base64.encode(JSON.stringify(obj));
}

export const decodeUriState = (state) => {
    const decodedState = decodeURIComponent(state);
    if(decodedState.indexOf("%") > -1) {
        return decodeUriState(decodedState);
    }
    return decodedState;
}

export const generateState = (params) => {
    if((params.state && typeof params.state === 'string')) {
        const parsedParams = decodeBase64(params.state);
        const loweredParsedParams = objectKeysToLowerCase(parsedParams);
        if(loweredParsedParams.casetype) {
            params = loweredParsedParams;
        }
    }

    const {lang, casetype, subcasetype, startedfrom, productid, hashid, transaction_id, module, saleschannel, threadhashedid, route, campaigninstanceid, noload, cluid, authtype, cluidpo, orgunitid} = params;
    const hidden = params[import.meta.env.VITE_APP_URL_AUTH_PARAM];
    const hashroute = window.location.hash;
    const state = Object.assign({}, {lang, casetype, subcasetype, startedfrom, productid, hashid, transaction_id, module, saleschannel, threadhashedid, route, campaigninstanceid, noload, cluid, authtype, hashroute, cluidpo, orgunitid});

    if(isNotEmptyObject(hidden)) {
        state[import.meta.env.VITE_APP_URL_AUTH_PARAM] = hidden;
    }

    console.log('generated state: ' + state);
    return btoa(JSON.stringify(state));
}
