import React, { useEffect, useState } from 'react';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Resource from '../core/serverresource';
import RestCaller from '../core/restCaller';
import { GdsFieldContainer, ValidationError } from '@csas-smart/gti-ui-comps';
import { isEmptyArray, isEmptyString, isNotEmptyArray, isValidRegistrationNumber } from "validations";


function useMergeState(initialState) {
    const [state, setState] = useState(initialState);
    const setMergedState = newState =>
        setState(prevState => Object.assign({}, prevState, newState)
        );
    return [state, setMergedState];
}

const getTranslations = (t) => {
    return {
        searchPlaceholder: t('common:field.employer-search-field.searchPlaceholder'),
        searchText: t('common:field.employer-search-field.searchText'),
        emptyLabel: t('common:field.employer-search-field.emptyLabel'),
        employerError: t('common:field.employer-search-field.employerError'),
        registrationNumberError: t('common:field.employer-search-field.registrationNumberError'),
        registrationNumberFormatError: t('common:field.employer-search-field.registrationNumberFormatError')
    }
};


const EmployerSearchComp = (props) => {

    const { field, t, required, value, validations, customLabels, isRegNumberRequired } = props;
    const [translations] = useState(getTranslations(t));
    const [data, setData] = useMergeState({ loading: false, nameOptions: [], registrationNumberOptions: [] });
    const nameValue = value.name;
    const registrationNumberValue = value.registrationNumber;

    // On componentDidMount, propagate initial error to false
    useEffect(() => {
        validations.propagateError({ "searchCompError": false });
    }, []);

    // Effect - register new version of component validation.
    useEffect(() => {
        validations.componentMounted(field.name + "search-employer", () => (validate(nameValue)));
        return function cleanup() {
            validations.componentUnmounted(field.name + "search-employer");
        };
    }, [nameValue]);

    // Effect - register new version of component validation.
    useEffect(() => {
        validations.componentMounted(field.name + "search-employer-registration-number", () => (validateRegistrationNumber(registrationNumberValue)));
        return function cleanup() {
            validations.componentUnmounted(field.name + "search-employer-registration-number");
        };
    }, [registrationNumberValue]);

    // Validation from blur
    const validate = (currentValue) => {
        if (required && isEmptyString(currentValue)) {
            validations.propagateError({ "searchCompError": true });
            return false;
        }
        validations.propagateError({ "searchCompError": false });
        return true;
    };

    // Validation of registration number from blur. 
    const validateRegistrationNumber = (currentValue) => {
        return validateEmptyRegistrationNumber(currentValue) && validateRegistrationNumberFormat(currentValue);
    }

    const validateEmptyRegistrationNumber = (currentValue) => {
        if (required && isRegNumberRequired && isEmptyString(currentValue)) {
            validations.propagateError({ "searchComp_emptyRegistrationNumber": true });
            return false;
        }
        validations.propagateError({ "searchComp_emptyRegistrationNumber": false });
        return true;
    }

    const validateRegistrationNumberFormat = (currentValue) => {
        if (required && isRegNumberRequired && !isValidRegistrationNumber(currentValue)) {
            validations.propagateError({ "searchComp_registrationNumberFormat": true });
            return false;
        }
        validations.propagateError({ "searchComp_registrationNumberFormat": false });
        return true;
    }

    // Async search by company name
    const searchByName = (query) => {
        setData({ loading: true });
        RestCaller.httpGet(Resource.searchEmployerByName(query))
            .then(result => {
                setData({ loading: false, nameOptions: result, registrationNumberOptions: [] });
            });
    };

    // Async search by registration number
    const searchByRegistrationNumber = (query) => {
        setData({ loading: true });
        RestCaller.httpGet(Resource.searchEmployerByRegistrationNumber(query))
            .then(result => {
                setData({ loading: false, nameOptions: [], registrationNumberOptions: result });
            });
    };

    // Company propagates only selected value, array of options remains stored only in component state.
    const onChange = (selectedValue) => {
        // clear state on clear button press
        if (isEmptyArray(selectedValue)) {
            setData({ nameOptions: [], registrationNumberOptions: [] });
        }
        const newValue = isNotEmptyArray(selectedValue) ? selectedValue[0] : props.defaultValue;
        // On change is called from async search comp
        Object.assign(newValue, { isManual: false });
        props.handleChange(newValue);
        validate(newValue.name);
        validateRegistrationNumber(newValue.registrationNumber);
    };

    // When component blurs and selected is different then value, propagate change.
    const onBlur = (e) => {
        const newValue = { name: e.target.value };
        if (isEmptyString(value.registrationNumber) && value.isManual !== true) {
            Object.assign(newValue, { isManual: true });
        }
        props.handleBlur(Object.assign(value, newValue));
        setData({ loading: false });
        validate(newValue.name);
    };

    const onBlurRegistrationNumber = (e) => {
        const newValue = { registrationNumber: e.target.value };
        props.handleBlur(Object.assign(value, newValue));
        setData({ loading: false });
        validateRegistrationNumber(newValue.registrationNumber);
    }

    return (
        <>
            <GdsFieldContainer field={{ ...field, label: customLabels?.name }} t={t} required={required} componentId={"searchByNameComp"}>
                <AsyncTypeahead id={field.name + "searchByNameComp"}
                    className={"employer-search-comp dropdown g-dropdown"}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={nameValue ? [nameValue] : []}
                    paginate={false}
                    options={data.nameOptions}
                    isLoading={data.loading}
                    onSearch={searchByName}
                    minLength={3}
                    maxResults={10}
                    placeholder={translations.searchPlaceholder}
                    searchText={translations.searchText}
                    emptyLabel={translations.emptyLabel}
                    promptText={""}
                    useCache={false}
                    clearButton
                />
                <ValidationError key={field.name + "search-employer-error"} errorText={translations.employerError} error={validations.validationError && validations.validationError.searchCompError === true} />
            </GdsFieldContainer>

            <GdsFieldContainer field={{...field, label: customLabels?.registrationNumber }} t={t} required={required && isRegNumberRequired} componentId={'searchByRegistrationNumberComp'}>
                <AsyncTypeahead id={field.name + "searchByRegistrationNumberComp"}
                    className={"employer-search-comp dropdown g-dropdown"}
                    onChange={onChange}
                    onBlur={onBlurRegistrationNumber}
                    selected={registrationNumberValue ? [registrationNumberValue] : []}
                    paginate={false}
                    options={data.registrationNumberOptions}
                    isLoading={data.loading}
                    onSearch={searchByRegistrationNumber}
                    minLength={8}
                    inputProps={{ maxLength: 8 }}
                    maxResults={10}
                    placeholder={translations.searchPlaceholder}
                    searchText={translations.searchText}
                    emptyLabel={translations.emptyLabel}
                    promptText={""}
                    clearButton
                    useCache={false}
                    labelKey={registrationNumberValue ? registrationNumberValue : option => `${option.registrationNumber} - ${option.name}`}
                />
                <ValidationError key={field.name + "search-employer-empty-registration-number-error"} errorText={translations.registrationNumberError} error={validations.validationError && validations.validationError.searchComp_emptyRegistrationNumber === true}/>
                <ValidationError key={field.name + "search-employer-empty-registration-number-format-error"} errorText={translations.registrationNumberFormatError} error={validations.validationError && validations.validationError.searchComp_registrationNumberFormat === true}/>
            </GdsFieldContainer>
        </>
    );
};

export default EmployerSearchComp;