import React from "react";
import {SscsConditionsCheckSummaryField} from "@csas-smart/gti-bop-ui";

const SscsConditionsCheckSummaryFieldContainer = (props) => {

    return (
            <SscsConditionsCheckSummaryField {...props}/>
    )
}

export default SscsConditionsCheckSummaryFieldContainer;