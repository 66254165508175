/**
 * Function validates, whether given object is not empty.
 * As not empty object is considered an object with at least one key with value.
 * @param arg
 * @returns {boolean}
 */
export function isNotEmptyObject(arg: object | undefined | null) {
  return !isEmptyObject(arg);
}

/**
 * Function validates, whether given parameter is empty object.
 * As an empty object is considered: null, undefined, {}.
 * @param arg Argument to be evaluated.
 * @returns {boolean} Indicating, whether the argument is empty object or not.
 */
export function isEmptyObject(arg: object | undefined | null) {
  if (!arg) {
    return true;
  }

  for (var key in arg) {
    //@ts-ignore
    if (arg[key]) {
      return false;
    }
  }
  return true;
}

/**
 * Function that decides, whether the provided argument is exising.
 * As existing is considered everything except for: null, undefined.
 * @param arg
 * @return {boolean}
 */
export function isExisting(arg: any | any[] | undefined | null) {
  return arg !== undefined && arg !== null;
}

/**
 * Function that decides, whether the provided argument is not exising.
 * As non-existing is considered: null, undefined.
 * @param arg
 * @return {boolean}
 */
export function isNotExisting(arg: any | undefined | null) {
  return !isExisting(arg);
}