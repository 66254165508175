import { UserState } from "./types";

const initialState: UserState = { actor: null };

const userReducers = (state = initialState, action): UserState => {
    var newState;
    switch (action.type) {
        case 'ACTOR_LOADED':
            newState = {...state, actor: action.actor};
            return newState;

        default:
            return state;
    }
};

export default userReducers;
