import queryString from 'query-string';
import { isEmptyString } from "validations";


/**
 * sometimes url is in the shape of https://servicing.csast.csas.cz/#/caseType=CTxy&module=BOP&.... this /#/caseT... is wrong.
 * Right shape is /#/?caseType=.... method fixHash do the job:
 *  * take window.location.hash value
 *  * parse real Route value
 *  * if there are some custom params on the right side of route then params are added with "?" prefix
 * @param hash - typically input is window.location.hash
 * @returns hashRoute as is when input is correct (i.e. #/?caseType=CTxy&module=BOP&...)
 *          fixed route when params are added incorrectly
 */
export const fixHash = (hash) => {
    if(isEmptyString(hash)) {
        return "#/";
    }

    const hashRoute = resolveHashRoute(hash);
    if(hashRoute.length !== hash.length && hash.indexOf(hashRoute + "?") === -1) {

        return hashRoute + "?" + hash.substring(hashRoute.length);
    }
    return hash;
}

/**
 * local params are parsed query params
 * (https://servicing.csast.csas.cz?-->caseType=CT123&module=BOP&....<--/#/whatever/you/think/you/know?these=parameters&are=not&included=false)
 * @param query
 * @returns {{}}
 */
export const querySearchInternal = (query) => {
    if(isEmptyString(query)) {
        return {};
    }

    const params = queryString.parse(query, {arrayFormat: 'comma'});
    const parsedParams = {};
    Object.keys(params)
        .forEach(key => {

                const parsedKey = key.startsWith('/') ? key.substr(1).toLowerCase() : key.toLocaleLowerCase();
                const value = params[key];
                parsedParams[parsedKey] = value;

        });

    return parsedParams;
};

export const objectKeysToLowerCase = (obj) => {
    if(!obj || Object.keys(obj).length === 0) {
        return null
    }

    var resultObject = {};

    Object.keys(obj)
        .forEach(key => {
            resultObject[key.toLowerCase()] = obj[key];
        })

    return resultObject;
}

export const resolveHashRoute = (hash) => {
    if(isEmptyString(hash)) {
        return null;
    }

    let hashRoute = '';
    const indexOfEquality = hash.indexOf("=");
    const indexOfQuestionMark = hash.indexOf("?");
    if(indexOfEquality === -1) {
        hashRoute = hash.substring(0, indexOfQuestionMark === -1 ? hash.length : indexOfQuestionMark);
    } else if(indexOfQuestionMark === -1) {
        if(hash.startsWith('#/')) {
            hashRoute = "#/";
        } else if(hash.startsWith('#')){
            hashRoute = "#"
        } else {
            throw new Error("Cannot resolve hash: " + hash);
        }
    } else {
        hashRoute = hash.substring(0, indexOfQuestionMark);
    }

    return hashRoute;
}

/**
 * hashParams are parsed params found on client side of url (right side from the "#" char)
 * (https://servicing.csast.csas.cz?these=parameters&are=not&included=false/#/whatever/you/think/you/know?-->caseType=CT123&module=BOP<--)
 * @param hash
 * @returns {{}}
 */
export const resolveHashParams = (hash) => {
    if(isEmptyString(hash)) {
        return {};
    }

    const hashIdNameIndex = hash.search(/\/task\//);
    let hashId = null;

    if(hashIdNameIndex >= 0) {
        //paths like /task/{hashId}, /task/next/{hashId}
        hashId = hash.substring(hash.lastIndexOf("/") + 1);
    }


    const hashRoute = resolveHashRoute(hash);

    const restOfParams = queryString.parse(hash.substring(hashRoute.length),{arrayFormat: 'comma'});
    if(hashId){
        restOfParams['hashId'] = hashId;
    }
    for(const param of Object.keys(restOfParams)) {
        if(restOfParams[param].indexOf("#") > 0) {
            const splittedValue = restOfParams[param].split("#");
            restOfParams[param] = splittedValue[0];

            const brokenParam = queryString.parse(splittedValue[1]);
            for(const hp of Object.keys(brokenParam)) {
                restOfParams[hp] = brokenParam[hp];
            }
        }
    }

    return objectKeysToLowerCase(restOfParams);
}
