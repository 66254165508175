import React from "react";
import {Row, Col} from 'reactstrap';

const HorizontalSeparatorField = () => {
    return (
        <Row>
            <Col md={12} style={{padding: 0}}>
                <p className="horizontal-separator"></p>
            </Col>
        </Row>
    );
};

export default HorizontalSeparatorField;