import React, { lazy } from "react";
import { useDispatch } from 'react-redux';
import { migrationActivateDevice, checklistGetDevices, migrationGetPollingInfo, migrationGetPollStatus, checklistSetOBO, checklistGetData } from "../../actions/g4bmigrActions";

// Lazily loading "@csas-smart/gti-g4bmigr-ui":
const ChecklistDeviceActivationField = lazy(() =>
    import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.ChecklistDeviceActivationField }))
);

const ChecklistDeviceActivationFieldContainer = (props) => {

    const dispatch = useDispatch();

    const getDevices = (requestDto) => {
        console.log('ChecklistDeviceActivationFieldContainer - getDevices called');

        return dispatch(checklistGetDevices(requestDto));
    }

    const activateDevice = (requestDto) => {
        console.log('ChecklistDeviceActivationFieldContainer - activateDevice called');

        return dispatch(migrationActivateDevice(requestDto));
    }

    const getPollingInfo = (requestDto) => {
        console.log('ChecklistDeviceActivationFieldContainer - getPollingInfo called');

        return dispatch(migrationGetPollingInfo(requestDto));
    }

    const getStatus = (requestDto) => {
        console.log('ChecklistDeviceActivationFieldContainer - getStatus called');

        return dispatch(migrationGetPollStatus(requestDto));
    }

    const setOBO = (requestDto) => {
        console.log('ChecklistDeviceActivationFieldContainer - setOBO called');

        return dispatch(checklistSetOBO(requestDto));
    };

    const updateData = () => {
        console.log('ChecklistDeviceActivationFieldContainer - updateData called');

        return dispatch(checklistGetData());
    };


    return (
        <ChecklistDeviceActivationField
            {...props}
            setOBO={setOBO}
            getDevices={getDevices}
            activateDevice={activateDevice}
            getPollingInfo={getPollingInfo}
            getStatus={getStatus}
            updateRepresentatives={updateData}
        />
    )
};

export default ChecklistDeviceActivationFieldContainer;
