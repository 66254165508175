import React, {Component} from "react";
import {Input, Col, Row} from 'reactstrap';
import {isNumber} from "../common/validations";

/**
 * * FIXME: PROPTYPES
    Component representing one inputLine.
    Component receives in props attrName, that is returned to master component on handleChange.
**/

interface Props {
    maxLength?: number;
    editable: boolean;
    onBlur?: () => void;
    onChange: (obj: any) => void;
    value: string;
    placeholder?: string;
    attrName: string;
    name?: string
}

class InputComp extends Component<Props> {

    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){
        if (isNumber(this.props.maxLength) && e.target.value.length > this.props.maxLength){
            return;
        }
        let newObj = {};
        newObj[this.props.attrName] = e.target.value;
        this.props.onChange(newObj);
    }

    render(){
        const { editable, onBlur } = this.props;
        return (
            <Row>
                <Col md={12}>
                    <Input type="text" onBlur={onBlur} value={this.props.value || ""} id={"1"} name={this.props.name || "prezentational"} placeholder={this.props.placeholder? this.props.placeholder : ""} disabled={!editable} onChange={this.handleChange}/>
                </Col>
            </Row>
        );
    }
}

export default InputComp;