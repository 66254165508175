import Field from "../field";
import React from "react";
import SessionWrapper from "../../sessions/sessionWrapper";

export function resolveHashIdFromState() : string {
    return new SessionWrapper().getHashId();
}

export function resolveCaseTypeFromState() {
    return new SessionWrapper().getCaseType();
}

//functions Detects IE
export const detectIE = () => {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
};

export const getActivityFieldsByLocation = (activity, location, completeAction, validateActivity) => {
    return  activity.fields.filter(field => field.location === location).map(field =>
                <Field field={field}
                       key={field.name}
                       completeActivityAction={completeAction}
                       validateActivityAction={validateActivity}
                       location={location}
                />
    )
};
