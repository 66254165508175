import React, { lazy } from 'react';
import {loadCodebookWithLang} from "../../actions/taskActions";
import SessionWrapper from "../../sessions/sessionWrapper";
import {GdsFieldContainer} from '@csas-smart/gti-ui-comps';

// Lazily loading "@csas-smart/gti-mnt-ui": 
const FutureOwnersField = lazy(() =>
    import("@csas-smart/gti-mnt-ui").then(module => ({ default: module.FutureOwnersField }))
);

const FutureOwnersFieldContainer = (props) => {
    const {field, t, required} = props;
    const sessionWrapper = new SessionWrapper();

    const loadCodebookEntriesHandler = (codebook, attributes) => {
        return loadCodebookWithLang(codebook, attributes, sessionWrapper.getLanguage());
    };
    return (
        <GdsFieldContainer field={field} t={t} required={required} componentId={'FutureOwnersField'}>
            <FutureOwnersField {...props}
                               loadCodebookEntries={loadCodebookEntriesHandler}
            />
        </GdsFieldContainer>)
}

export default FutureOwnersFieldContainer;
