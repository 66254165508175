import { Account } from "core-types";

/**
 * @desc Function receives fullAccountNumber and parse it into normalize form, that is prefix, bankCode and account parts.
 * @param fullAccountNumber - with optional prefix and bankCode.
 * @returns {*} object with keys: prefix, bankCode, account
 */
export function composeAccountObject(fullAccountNumber: string): Account{
  let prefix, bankCode;
  let accountNumber = fullAccountNumber;
  let prefixPosition = fullAccountNumber.indexOf('-');
  let bankCodePosition = fullAccountNumber.indexOf('/');
  if (prefixPosition > 0){
    prefix = fullAccountNumber.substring(0, prefixPosition);
  }
  bankCode = fullAccountNumber.substring(bankCodePosition + 1, fullAccountNumber.length);

  accountNumber = accountNumber
    .replace(/[0-9]*-/g, "") // remove account prefix part
    .replace(/\/[0-9]*/g, ""); // remove bank code part

  return {
    prefix: prefix,
    bankCode: bankCode,
    account: accountNumber
  };
}