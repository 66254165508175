import { Amount, SliderInput } from "@george-labs.com/design-system";
import React from "react";

interface Props {
  loanAmount: number,
  onChange: (_: number) => Promise<void>
}

const DemoSlider = ({ loanAmount, onChange }: Props) => {

  const handleSliderChange = (e: any) => {
    return onChange(e.target.value);
  };

  return (
    <SliderInput
      min={0}
      max={500}
      value={loanAmount}
      startLabel={<Amount value={0} precision={0} currency="EUR" />}
      middleLabel={<Amount value={250} precision={0} currency="EUR" />}
      endLabel={<Amount value={500} precision={0} currency="EUR" />}
      onChange={handleSliderChange}
    />
  );
};

export default DemoSlider;