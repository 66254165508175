import React, { lazy } from "react";
import { useDispatch } from 'react-redux';
import {checklistGetActivationQrCode, checklistGetActivationComplete, checklistGetData} from "../../actions/g4bmigrActions";

/**
 * Lazily loading "@csas-smart/gti-g4bmigr-ui": 
 */ 
const SafetyMethodActivationField = lazy(() =>
    import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.SafetyMethodActivationField }))
);

const SafetyMethodActivationFieldContainer = (props) => {

    const dispatch = useDispatch();

    const onActivationQrCodeLoad = (cluidUser) => {
        console.log('SafetyMethodActivationFieldContainer - onActivationQrCodeLoad called');

        return dispatch(checklistGetActivationQrCode(cluidUser));
    };

    const onActivationCompleteCheck = (cluidUser) => {
        console.log('SafetyMethodActivationFieldContainer - onActivationCompleteCheck called');

        return dispatch(checklistGetActivationComplete(cluidUser));
    };

    const updateData = () => {
        console.log('SafetyMethodActivationFieldContainer - updateData called');

        return dispatch(checklistGetData());
    };

    return (
        <SafetyMethodActivationField
            {...props}
            onActivationQrCodeLoad={onActivationQrCodeLoad}
            onActivationCompleteCheck={onActivationCompleteCheck}
            updateData={updateData}
        />
    )
};

export default SafetyMethodActivationFieldContainer;
