import ct1736 from "./ct1736";
import defaultCt from "./default-ct";

export default class linkCreatorDelegate {
    availableCreators = [];

    constructor() {
        this.availableCreators.push(new ct1736());

    }

    getLinkCreator(ctx) {
        if(!ctx) {
            return new defaultCt();
        }

        for(const creator of this.availableCreators) {
            if(creator.isApplicable(ctx)) {
                return creator;
            }
        }

        return new defaultCt();
    }
}
