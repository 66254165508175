
import {taskCompletedParam, caseTypeParam, isTabletParam} from "./link-params";

export default class ct1736 {

    constructor() {
    }

    isApplicable(ctx) {
        if(!ctx) {
            return false;
        }

        return ctx[caseTypeParam] === 'CT1736' && ctx[isTabletParam] === true;
    }

    prepareLink(baseLink, additionalLinkParams) {
        if(baseLink.indexOf('cshubappquit') > -1) {
            return baseLink;
        }
        const urlParamsEndIndex = baseLink.indexOf("#access_token") > -1 ?  baseLink.indexOf("#access_token") : baseLink.length;
        const link = baseLink.substring(0, urlParamsEndIndex);
        const token = baseLink.substring(urlParamsEndIndex);

        //returnaction is already filled by 3rd party application and we do not want to change this value
        if(baseLink.indexOf("returnaction") > -1) {
            return baseLink;
        }

        const taskCompletedParamKey = Object.keys(additionalLinkParams).filter(key => key === taskCompletedParam);
        if(additionalLinkParams && taskCompletedParamKey.length === 1) {
            const actionValue = additionalLinkParams[taskCompletedParam] === true ? "success" : "failed";

            return link + "&returnaction="+actionValue+token;
        }

        return baseLink;
    }
}
