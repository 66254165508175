import React, { useState } from "react";
import { SscsSelectDisbursementTypeField } from "@csas-smart/gti-bop-ui";
import { GdsFieldContainer } from "@csas-smart/gti-ui-comps";


const SscsSelectDisbursementTypeFieldContainer = (props) => {

    const { field, t, required } = props;
    const [isVisible, setIsVisible] = useState(true);

    const hideField = () => {
        setIsVisible(false);
    }

    return isVisible ? (
        <GdsFieldContainer field={field} t={t} required={required} componentId={'sscsSelectDisbursementTypeFieldContainer'}>
            <SscsSelectDisbursementTypeField {...props} hideField={hideField} />
        </GdsFieldContainer>
    ) : null;
}

export default SscsSelectDisbursementTypeFieldContainer;