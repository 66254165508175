import React from "react";
import { GdsFieldContainer, IqField } from "@csas-smart/gti-ui-comps";
import {getLocale} from "../core/i18n";

const IqFieldContainer = (props) => {

    const {field, t, required} = props;
    const language = getLocale();

    return <GdsFieldContainer field={field} t={t} required={required}>
            <IqField {...props} language={language}/>
          </GdsFieldContainer>
};

export default IqFieldContainer;