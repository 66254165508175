import { isExisting } from "@csas-smart/gti-ui-comps/dist/common/validation/validations";

/**
 * Helper class which loads the properties of the editor
 */
class PropertiesLoader {

    getProperty(editor, propertyName, defaultValue = null) {
        if (editor.properties) {
            var result = editor.properties.filter( p => propertyName === p.name);
            if (result && result.length>0 && isExisting(result[0].value) ) {
                return result[0].value;
            }
        }

        return defaultValue;
    }

}

// export singleton
export default new PropertiesLoader();