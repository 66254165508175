import PropertiesLoader from '../propertiesloader';

const defaultStateFilter = {
    visible: true,
    editable: true,
    preselectUnfulfilled: true,
    preselectOverdue: true,
    preselectToCheck: true,
    preselectFulfilled: true
};

const defaultTypeFilter = {
    visible: true,
    editable: true
};

const defaultFilterLegend = {
    nameOfLegend: 'V přehledu Vám zobrazujeme tyto podmínky:',
    legendUnfulfilled: 'Nesplněné podmínky, můžete k nim nahrát dokumenty a požádat o kontrolu.',
    legendOverdue: 'Nesplněné podmínky po termínu. Můžete k nim nahrát dokumenty a požádat o kontrolu.',
    legendToCheck: 'Podmínky připravené ke kontrole bankou. Nelze k nim nahrát dokument ani požádat o kontrolu podmínky.',
    legendFulfilled: 'Splněné podmínky.'
};

export function getStateFilter(field) {
    if (!field) {
        return defaultStateFilter;
    }

    return PropertiesLoader.getProperty(field.editor, "stateFilter", defaultStateFilter);
}

export function getTypeFilter(field) {
    if (!field) {
        return defaultTypeFilter;
    }

    return PropertiesLoader.getProperty(field.editor, "typeFilter", defaultTypeFilter);
}

export function getShowLegend(field) {
    if (!field) {
        return false
    }

    return PropertiesLoader.getProperty(field.editor, "showLegend", false);
}

export function getFilterLegend(field) {
    if(!field) {
        return defaultFilterLegend;
    }

    return PropertiesLoader.getProperty(field.editor, "filterLegend", defaultFilterLegend);
}

export function getPreselectDrawing(field) {
    if(!field) {
        return false;
    }

    return PropertiesLoader.getProperty(field.editor, "preselectDrawing", false);
}

export function getPreselectSubsequent(field) {
    if(!field) {
        return false;
    }

    return PropertiesLoader.getProperty(field.editor, "preselectSubsequent", false);
}
