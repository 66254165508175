import {AccountField} from "@csas-smart/gti-ui-comps";
import React from "react";
import {loadCodebookWithLang} from "../actions/taskActions";
import SessionWrapper from "../sessions/sessionWrapper";

/**
 * Component representing an account-field. For additional info please visit: https://gti-library-int.csint.cz/#/fields/account-field
 */
const AccountFieldContainer = (props) => {
    const sessionWrapper = new SessionWrapper();

    const loadCodebookWithLangHandler = (codebook, attributes, lang) => {
        return loadCodebookWithLang(codebook, attributes, lang ? lang : sessionWrapper.getLanguage());
    };

    return <AccountField {...props} loadCodebookWithLangHandler={loadCodebookWithLangHandler} />
};

export default AccountFieldContainer;
