import LogglyMessage from "./logglyMessage";

class LogglyMessageObject extends LogglyMessage {

    objectData: any;
    
    constructor(objectMsg) {
        super();
        this.type = "OBJECT";
        this.objectData = objectMsg;
    }
}

export default LogglyMessageObject;
