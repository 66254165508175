import { MODULES, NgModule, NGRUNTIME_MODULE } from '../relevantStrategies/modules';
import TranslationService from './translationService';

class TranslationServiceDelegate {

    getTranslationService(module : string | undefined | null, hashId : string) : TranslationService {
        var ngModule : NgModule;
        if (!module) {
            ngModule = NGRUNTIME_MODULE;
        } else {
            ngModule = MODULES.filter(m => m.translations).find(m => m.name === module.toLowerCase()) || NGRUNTIME_MODULE;
        }
        const translationUrl = ngModule.traslationUrlOverride || (ngModule.url + '/translation');
        return new TranslationService(translationUrl, hashId);
    }
}

export default new TranslationServiceDelegate();
