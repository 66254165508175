import React, { Component } from "react";
import PropertiesLoader from "../core/propertiesloader";
import { translate } from '../core/i18n';
import OnceClickableComponentContainer from "../comp/container/once-clickable-component-container";
import FooterSignTogglegroup from "./sign-togglegroup-field/footer-sign-togglegroup";
import { Field, GdsFieldContainer, ValidationError } from "@csas-smart/gti-ui-comps";
import { isNotEmptyString } from "validations";


interface Props {
    field: Field;
    validations: any;
    t: any;
    required: any;
    location: any;
    attributes: any;
    fieldValueChanged: any;
    completeActivityAction: any;
    validateActivityAction: any;
    editable: boolean;
}

class SignToggleGroupField extends Component<Props> {

    attrValue: any;
    errorText: any;

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.handleActivityComplete = this.handleActivityComplete.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.validate = this.validate.bind(this);

        this.attrValue = this.props.field.selector.attributes["value"].name;
    }

    componentDidMount(){
        const {name} = this.props.field;
        // Load properties from descriptor
        this.props.validations.componentMounted(name, this.validateForm);

        this.errorText = PropertiesLoader.getProperty(this.props.field.editor, "errorText");
        this.props.validations.setError({error: false});
    }

    handleChange(e) {
        this.props.fieldValueChanged({name: this.attrValue, value:e.target.value});
        return this.handleActivityComplete({buttonType: e.target.buttonType});
    }

    handleToggleChange(e) {
        this.props.fieldValueChanged({name: this.attrValue, value:e});
        this.handleActivityComplete(null);
    }

    handleActivityComplete(context) {
        const { location } = this.props;
        if(location === 'FOOTER') {
            return this.props.completeActivityAction(context);
        }
    }

    // Uncommon patter, validation due to button logic, validation should not be initialized on lost focus, but rather onClick.
    validateForm(){
        return this.validate(this.props.attributes.value);
    }

    validate(value){
        const currentErrorValue = this.props.validations.validationError.error;
        const {dropErrorFlag, raiseErrorFlag} = this.props.validations;
        // If component is not required, skip validation
        if (!this.props.required){
            return true;
        }

        if (isNotEmptyString(value)) {
            dropErrorFlag(currentErrorValue, {error: false});
            return true;
        }
        else {
            raiseErrorFlag(currentErrorValue, {error: true});
            return false;
        }
    }

    render() {
        const { field, validations, t, required, validateActivityAction, editable} = this.props;

        const buttons = PropertiesLoader.getProperty(this.props.field.editor, "buttons");

        buttons.map(option => {
            option.label = translate(t, 'field.properties.buttons.' + option.key, option.label);

        });

        const buttonSize = PropertiesLoader.getProperty(field.editor, "buttonSize", false);
        const buttonWidth = PropertiesLoader.getProperty(field.editor, "buttonWidth", "small");
        let btnWidth = 0;
        if(buttonSize) {
            switch (buttonWidth) {
                case 'small':
                    btnWidth = 150;
                    break;
                case 'base':
                    btnWidth = 200;
                    break;
                case 'large':
                    btnWidth = 250;
                    break;
            }
        }

        var result = null;


        if (buttons) {
            result = <OnceClickableComponentContainer
                handleChange={this.handleChange}
                render={(isDisabled, onClick) => (
                    <GdsFieldContainer field={field} t={t} required={required} componentId={'togglegroupFooterComponent'}>
                        <>
                            <FooterSignTogglegroup
                                buttons={buttons}
                                onClick={onClick}
                                btnWidth={btnWidth}
                                isDisabled={isDisabled}
                                editable={editable}
                                field={field}
                                fieldValueChanged={this.props.fieldValueChanged}
                                completeActivityAction={this.props.completeActivityAction}
                                validateActivityAction={validateActivityAction}
                            />
                            <ValidationError errorText={translate(t, 'field.properties.errorText', this.errorText)} error={validations.validationError.error} />
                        </>
                    </GdsFieldContainer>
                )
                }
            />;

        }
        return (result);
    }

}

export default SignToggleGroupField;