import {resolveHashRoute} from "../utils/queryUtils";
import SessionWrapper from "../../sessions/sessionWrapper";
import Resource from "../serverresource";

class MockService {
    tokenReceivedHandler;
    sessionWrapper;
    SUPPORTED_AUTH_TYPE='mock';
    SUPPORTED_API_KEY = import.meta.env.VITE_APP_API_KEY;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    supportType = (authType) => {
        return authType === this.SUPPORTED_AUTH_TYPE;
    }

    withTokenReceivedHandler(tokenReceivedHandler) {
        this.tokenReceivedHandler = tokenReceivedHandler;
        return this;
    }

    init(params) {
        if(this.tokenReceivedHandler) {

            const resolvedRoute = resolveHashRoute(window.location.hash);
            this.sessionWrapper.setAccessToken(Math.random() * 1000000);
            this.sessionWrapper.setLoginCodeReceived();
            this.sessionWrapper.setRouteRedirect(resolvedRoute);
            Resource.setInterceptor();
            console.log("Route redirect is set to " + resolvedRoute);

            this.tokenReceivedHandler(params);
        }
    }

    revoke(hash) {
        console.log("MOCK for revoking case " + hash);
        this.sessionWrapper.clearSessionStorage();
        return Promise.resolve();
    }

    redirectToGeorge() {
        console.log("MOCK redirect to george");
        window.location.href = 'https://csas.cz';
    }

    handover() {
        this.sessionWrapper.clearSessionStorage();
    }

    getLogoutTimeout() {
        return -1;
    }

    getSupportedAuthType = () => {
        return this.SUPPORTED_AUTH_TYPE;
    }

    getSupportedApiKey = () => {
        return this.SUPPORTED_API_KEY;
    }

}

export default new MockService();
