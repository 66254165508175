import React from "react";
import {SscsLoanConditionsField} from "@csas-smart/gti-bop-ui";
import { GdsFieldContainer } from "@csas-smart/gti-ui-comps";


const SscsLoanConditionsFieldContainer = (props) => {

    const {field, t, required} = props;

    return (
        <GdsFieldContainer field={field} t={t} required={required} componentId={'sscsLoanConditionsFieldContainer'}>
            <SscsLoanConditionsField {...props} />
        </GdsFieldContainer>
    );

}

export default SscsLoanConditionsFieldContainer;