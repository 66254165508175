import React, { lazy } from "react";
import { useDispatch } from 'react-redux';
import {checklistGetData} from "../../actions/g4bmigrActions";

// Lazily loading "@csas-smart/gti-g4bmigr-ui":
const ClientInfoField = lazy(() =>
    import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.ClientInfoField }))
);
  
const ClientInfoFieldContainer = (props) => {

    const dispatch = useDispatch();

    const onUpdate = () => {
        console.log('ClientInfoFieldContainer - onUpdate called');

        return dispatch(checklistGetData());
    };

    const getTraceUrl = () => {
        return import.meta.env.VITE_APP_TRACE_URL;
    }

    return (
        <ClientInfoField {...props} onUpdate={onUpdate} traceUrl={getTraceUrl()} />
    )
};

export default ClientInfoFieldContainer;
