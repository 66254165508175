import React from "react";
import { CompanyField } from "@csas-smart/gti-ui-comps";
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import {resolveHashIdFromState} from "../core/utils/taskUtils";

const CompanyFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();

    const loadCompany = (inputData) => {
        return RestCaller.httpPost(Resource.getCompanies(hashId), inputData);
    }

    return <CompanyField {...props} search={loadCompany}/>
};

export default CompanyFieldContainer;
