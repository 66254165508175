import {OidcRpClient} from "@idport/oidc-rp-sdk";

/**
 * Builder class for OidcRpClient. This client is used for communication with MEP (getting code, hadnling accessToken, logout ...)
 */
class OidcRpClientInitializer {
    clientId;
    rpCallbackHandler;

    constructor() {
    }

    /**
     * MEP application identifier used for token generation
     * @param clientId
     * @return {OidcRpClientInitializer}
     */
    withClientId(clientId) {
        this.clientId = clientId;
        return this;
    }

    /**
     * handler which is used when MEP session changes or fails
     * @param rpCallbackHandler
     * @return {OidcRpClientInitializer}
     */
    withRpCallbackHandler(rpCallbackHandler) {
        this.rpCallbackHandler = rpCallbackHandler;
        return this;
    }

    /**
     * Get initialized parameters (clientId is mandatory) and initialize OidcRpClient.
     * @return {OidcRpClient} - initialized client.
     */
    buildRpClient() {
        if(!this.clientId) {
            throw new Error("Missing clientId value")
        }

        return OidcRpClient.createClient({
            disableIssuerValidation: true,
            clientId: this.clientId,
            issuer: `${import.meta.env.VITE_APP_OIDC_OP_BASE_FQDN}/api/${import.meta.env.VITE_APP_OIDC_PROFILE}/fl/oidc/v1/`,
            responseType: 'code',
            checkSession: { interval: 10, iframeId: 'op' },
            authorizationHttpMethod: 'POST',
            callback: this.rpCallbackHandler,
        })
    }
}

export default OidcRpClientInitializer;
