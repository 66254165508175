import React from "react";
import {SscsLocationField} from "@csas-smart/gti-bop-ui";
import {useDispatch} from "react-redux";
import {deleteDocument, uploadFiles} from "../../actions/documentActions";


const SscsLocationFieldContainer = (props) => {

    const { field } = props;

    const dispatch = useDispatch();
    const uploadConditionFilesHandler = (fieldName, attrName, files) => {
        return dispatch(uploadFiles(field.name, attrName, files));
    }

    const deleteConditionDocumentHandler = (attrName, document) => {
        return dispatch(deleteDocument( field.name, attrName, document));
    }

    return (
            <SscsLocationField {...props}
                               uploadDocument={uploadConditionFilesHandler}
                               deleteDocument={deleteConditionDocumentHandler}
            />
    );

}

export default SscsLocationFieldContainer;