import {getLocale} from "../i18n";
import SessionWrapper, { GSTATE_JSON_TYPE } from '../../sessions/sessionWrapper';
import logger from "../loggly/gtiLogger";

import GeorgeSalesChannelManager from "./oidc/georgeSalesChannelManager";
import OidcRpClientInitializer from "./oidc/oidcRpClientInitializer";
import OidcCodeHandler from "./oidc/oidcCodeHandler";
import OidcTokenHandler from "./oidc/oidcTokenHandler";
import OidcGtiStateManager from "./oidc/oidcGtiStateManager";
import {ALLOWED_ADFS_SALES_CHANNELS, LEGACY_SALES_CHANNELS} from "./authService";
import {LEGACY_AUTH_MODULES} from "../relevantStrategies/modules";
import ExtSystemStateManager from "./common/extSystemStateManager";

export const GRG_GO_PARAM_VALUE = "GRG_GO";
export const GRG_PARAM_VALUE = "GRG";
export const STORE_SALES_CHANNELS = ["STORE", "STORE_GO"];
export const MOBILE_APP_SALES_CHANNELS = ["STORE_GO", "GRG_GO"];

/**
 * Service provides methods for handling OIDC Authorization (login, logout, handover)
 */
class OidcService {
    rpSdk;
    tokenReceivedHandler;
    sessionWrapper;
    oidcRpClient;

    georgeSalesChannelManager;
    extSystemStateManager;
    gtiStateManager;
    handlers = [];

    SUPPORTED_AUTH_TYPE='mep-oidc';
    SUPPORTED_API_KEY = import.meta.env.VITE_APP_API_KEY;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
        this.georgeSalesChannelManager = new GeorgeSalesChannelManager();
        this.extSystemStateManager = new ExtSystemStateManager();
        this.gtiStateManager = new OidcGtiStateManager();

        this.handlers.push(new OidcCodeHandler());
        this.handlers.push(new OidcTokenHandler());
    }

    supportType = (authType) => {
        const module = this.sessionWrapper.getModule();
        //AC-42276 - SES
        const salesChannel = this.sessionWrapper.getSalesChannel();
        //FIXME: Temporary old way
        const allowedSalesChannel = ALLOWED_ADFS_SALES_CHANNELS.indexOf(salesChannel) === -1;
        const legacyModule = LEGACY_AUTH_MODULES.map(mod=>mod.name).indexOf(module?.toLowerCase()) >= 0
            && LEGACY_SALES_CHANNELS.indexOf(salesChannel) >= 0;
        const supportedModule = !module || !legacyModule;

        return supportedModule && allowedSalesChannel && authType === this.SUPPORTED_AUTH_TYPE;
    }

    withTokenReceivedHandler = (tokenReceivedHandler) => {
        this.tokenReceivedHandler = tokenReceivedHandler;
        return this;
    }

    /**
     * Main purpose of init method is to handle oidc access token with MEP. This handler has two phases:
     * 1) Call MEP to obtain code id
     * 2) Call MEP with received code id to obtain access token
     *
     * @param params - Set of request query params containing:
     *  state
     *      sometimes state containing geogrge state
     *      sometimes state containing gti application state
     *  caseType
     *  salesChannel
     *  transactionId
     *  productId
     *  etc.
     */
    init = (params) => {
        //[1] - Init check - RpClient should not be initialized
        if(this.oidcRpClient) {
            throw new Error('OIDC_ALREADY_INITIATED');
        }

        //[2] - handle george state and sales channel
        this.extSystemStateManager.handleParams(params);
        this.georgeSalesChannelManager.handleParams(params);

        //[3] - prepare oidc client service
        this.oidcRpClient = new OidcRpClientInitializer()
            .withClientId(import.meta.env.VITE_APP_CLIENT_ID)
            .withRpCallbackHandler(this.rpCallbackHandler)
            .buildRpClient();

        //[4] - handle request params based on what these params consists of
        //      - if there is no 'code' request param then we will start to handle 'code' using by OidcCodeHandler
        //      - if there is 'code' request param then use it to handle accessToken using by OidcTokenHandler
        const validHandlers = this.handlers
            .filter(handler => handler.canHandle(params))
            .map(handler => handler.handleParams);

        //[5] - in general there can be more than one 'validHandlers' promise functions so we will resolve them all before
        //      checking that accessToken exists.
        Promise.all(validHandlers.map(fnc => fnc(params, this.oidcRpClient)))
            .then(() => {
                logger.debug("Handlers execution finsihed")
                if(this.sessionWrapper.getAccessToken()) {
                    logger.debug("Post processing after token received")
                    this.tokenResolvedHandler(this.gtiStateManager.handleParams (params));
                }
            })
    }


    /**
     * method get gtiApplicationState and pass it into tokenReceivedHandler which is function set from the application in app.js.
     *
     * @param gtiApplicationState - Object containing feilds like casetype, productid, saleschannel etc.
     */
    tokenResolvedHandler = (gtiApplicationState) => {
        if(this.tokenReceivedHandler) {
            window.location.hash = this.sessionWrapper.getRouteRedirect();
            this.tokenReceivedHandler(gtiApplicationState);
        }
    }

    /**
     * method used for private purposes of oidcServices. It correctly prepares redirect uri collected from
     * * baseUri - stored in VITE_APP_MEP_HANDOVER_REDIRECT_URI .env properties file
     * * if there is GeorgeState stored in session storage by 'georgeSalesChannelManager.js' then this state
     * * is used for overriding default redirectUri or default georgeState (identified as returnState)
     * @return {*}
     */
    generateRedirect = () => {
        let redirectUri = import.meta.env.VITE_APP_MEP_HANDOVER_REDIRECT_URI;
        let state = 'overview';

        if(this.sessionWrapper.getGState()) {
            console.log("Trying to encode GTI gState: " +  this.sessionWrapper.getGState())
            const gState = this.sessionWrapper.getGState(GSTATE_JSON_TYPE);
            state = gState.returnState ? gState.returnState : state; //TODO - what if returnState is missing ?
            const successUrlKeys = Object.keys(gState).filter(key => key.toLowerCase() === 'successurl');
            const successUrlKey =  successUrlKeys.length > 0 && successUrlKeys[0] !== null ? successUrlKeys[0] : null;
          
            if(successUrlKey) {
                redirectUri = gState[successUrlKey];
            } else {
                redirectUri = (gState.redirectUri ? gState.redirectUri : redirectUri) + "?state="+state;
            }
        }

        return redirectUri;
    }

    /**
     * handler used for events generated by MEP. When one of 'session_changed' or 'session_error' types are comming
     * then logout url is used for redirection.
     * @param evt
     */
    rpCallbackHandler = (evt) => {
        console.log("rpCallbackHandler: " + evt);
        if(evt.type === 'session_changed') {
            window.location.href = import.meta.env.VITE_APP_LOGOUT_URL;
        } else if(evt.type === 'session_error'){
            console.log('OIDC-SM Error received');
            window.location.href = import.meta.env.VITE_APP_LOGOUT_URL;
        }
    }

    /**
     * Revoke method generates simple request with actually using accessToken and call MEP to invalidate this token.
     * @return {Promise<T>}
     */
    revoke = () => {
        const request = {
            token: this.sessionWrapper.getAccessToken()
        };

        return this.oidcRpClient.revokeToken(request)
            .then(() => {
                this.clearSM();
                this.sessionWrapper.clearSessionStorage();
                return Promise.resolve()
            })
            .catch(err => Promise.reject(err));
    }

    /**
     * Separator of state params should vary according to salesChannel.
     * @return "#" separator for store / store_go
     * @return "?" separator for others
     */
    evaluateParamSeparator = () => {
        if (STORE_SALES_CHANNELS.indexOf(this.sessionWrapper.getSalesChannel()) >= 0){ // indexOf instead of conatins for IE11 compatibility
            return "#";
        }
        else {
            return "?";
        }
    }

    /**
     * Handover method is used only in case of "rejecting" case but "stay logged in George". This method should never
     * invalidate token
     */
    handover = () => {
        const redirectUri = this.generateRedirect();
        this.sessionWrapper.clearSessionStorage();

        window.location.href = redirectUri;
    }

    redirectToGeorge = () => {
        this.handover();
    }

    logout = () => {
        const hashId = new SessionWrapper().getHashId();
        // For mobile devices just call revoke and then ggo://success
        if(MOBILE_APP_SALES_CHANNELS.includes(this.sessionWrapper.getSalesChannel())) {
            logger.info("Logging out for mobile version of case " + hashId);
            this.revoke()
                .then(() => {
                    logger.info("Logged out for mobile version of case " + hashId);
                    this.sessionWrapper.clearSessionStorage();
                    logger.info("Session cleared for case " + hashId);
                    window.location.href = "ggo://success";
                });
        } else {
            logger.info("Logging out for WEB version of case " + hashId);

            const logoutRequest = {
                postLogoutRedirectUri: import.meta.env.VITE_APP_LOGOUT_URL.replace('${lang}', getLocale()),
                state: "OIDC_STATE;${lang}".replace('${lang}', getLocale())
            }

            this.sessionWrapper.clearSessionStorage();
            //const state = "OIDC_STATE;${lang}".replace('${lang}', getLocale());
            //logger.info("Generated state for case " + hashId + " was " + state);

            //this.rpSdk.generateLogoutRequest(state);
            this.oidcRpClient.logout(logoutRequest);
            logger.info("RpSdk logged out for case " + hashId);
        }
    }

    getLogoutTimeout = () => {
        return import.meta.env.VITE_APP_AUTOMATIC_LOGOUT_SECS;
    }

    clearSM = () => {
        const rp = document.getElementById('rp');
        if (rp) {
            document.body.removeChild(rp);
        }
        const op = document.getElementById('op');
        if (op) {
            document.body.removeChild(op);
        }
    }

    getSupportedAuthType = () => {
        return this.SUPPORTED_AUTH_TYPE;
    }

    getSupportedApiKey = () => {
        return this.SUPPORTED_API_KEY;
    }
}

export default new OidcService();
