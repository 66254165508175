import { lazy } from "react";

import DateFieldContainer from "../field/date-field-container";
import DocumentsField from "../field/documents-field-container";
import PaymentField from "../field/payment-surcharge-field/payment-surcharge-field";
import AlertsFieldContainer from "../field/alerts-field-container";
import HorizontalSeparatorField from "../field/horizontal-separator-field";
import EmployerSearchField from "../field/employer-search-field";
//import GraphQLField from "../field/graphql-field";
import GraphqlFieldContainer from "../field/graphql-field-container";
import LogoutField from "../field/logout-field";
import SignToggleGroupField from "../field/signtogglegroup-field";
import { NgPrintField } from "../field/ng-print-field";
import SscsLoanPurposesBopFieldContainer from "../field/sscs/sscs-loan-purposes-bop-field";
import SscsSelectDisbursementTypeFieldContainer from "../field/sscs/sscs-select-disbursement-type";
import SscsSelectLoanPurposesFieldContainer from "../field/sscs/sscs-select-loan-purposes-field";
import SscsLoanConditionsFieldContainer from "../field/sscs/sscs-loan-conditions-field";
import IqFieldContainer from "../field/iq-field-container";
import ResCompanyField from "../field/res-company-field/res-company-field";
import SpecifiedOwnersFieldContainer from "../field/corporate/specified-owners-field";
import CodebookFieldContainer from "../field/codebook-field";
import { RuianField } from "../field/ruian/ruian-field";
import AddressFormFieldContainer from "../field/address-form-field-container";
import ConditionsCheckContainer from "../field/gcedr/conditions-check-container";
import ConditionsSummaryContainer from "../field/gcedr/conditions-summary-container";
import CurrencyAmountFieldContainer from "../field/currency-amount-field-container";
import ContributionAmountFieldContainer from "../field/contribution-amount-field-container";
import RestCallerFieldContainer from "../field/restcaller-field/restcaller-field";
import AccountFieldContainer from "../field/account-field-container";
import ContactsFieldContainer from "../field/contacts-field-container";
import SignatoryFieldContainer from "../field/corporate/signatory-field";
import AccountStatementsFieldContainer from "../field/corporate/account-statements-field";
import FeedbackFieldContainer from "../field/feedback-field-container";
import {
  ButtongroupField,
  DecimalField,
  SelectDateField,
  StringField,
  TextField,
  ComboBoxField,
  PersonalIdField,
  CollapsibleField,
  ShowTextField,
  BooleanField,
  DateTimeField, ListField, ProductCardListField, IconField, TimelineField,
} from "@csas-smart/gti-ui-comps";
import KycFieldContainer from "../field/kyc-field-container";
import GeorgeIdActivationFieldContainer from "../field/g4bmigr/george-id-activation-field";
import NoteFieldContainer from "../field/g4bmigr/note-field";
import RepresentativeAuthorizationContainer from "../field/g4bmigr/representative-authorization-field";
import DataSignFieldContainer from "../field/g4bmigr/data-sign-field";
import ClientInfoFieldContainer from "../field/g4bmigr/client-info-field";
import RepresentativeListFieldContainer from "../field/g4bmigr/representative-list-field";
import GenerateAndSignDocFieldContainer from "../field/g4bmigr/generate-and-sign-doc-field";
import DocumentsToSignFieldContainer from "../field/g4bmigr/documents-to-sign-field";
import GenerateDocButtonsFieldContainer from "../field/g4bmigr/generate-doc-buttons-field";
import SafetyMethodActivationFieldContainer from "../field/g4bmigr/safety-method-activation-field";
import DeviceActivationFieldContainer from "../field/g4bmigr/device-activation-field";
import ChecklistDeviceActivationFieldContainer from "../field/g4bmigr/checklist-device-activation-field";
import SigmaDocumentsFieldContainer from "../field/sigma/sigma-documents-field";
import SigmaDownloadFieldContainer from "../field/sigma/sigma-download-field";
import SigmaSignMethodsFieldContainer from "../field/sigma/sigma-sign-methods-field";
import SigmaDocumentsToSignFieldContainer from "../field/sigma/sigma-documents-to-sign-field";
import PersonInfoFieldContainer from "../field/person-info-field-container";
import SscsDisbursementOrdersBopFieldContainer from "../field/sscs/sscs-disbursement-orders-bop-field";
import MsTeamsVideoCallFieldContainer from "../field/ms-teams-video-call-field-container";
import RefixFieldContainer from "../field/gmnt/refix-field-container";
import PaymentSurchargeV2FieldContainer from "../field/payment-surcharge-v2-field-container";
import KycCorporateFieldContainer from "../field/kyc-corporate-field-container";
import ProductPromoFieldContainer from "../field/product-promo-field-container";
import FutureOwnersFieldContainer from "../field/gmnt/future-owners-field-container";
import LoanModelingFieldContainer from "../field/loan-modeling-field-container";
import CompanyFieldContainer from "../field/company-field-container";
import AgreementsFieldContainer from "../field/agreements-field-container";
import StepperFieldContainer from "../field/stepper-field-container";
import TransactionsFieldContainer from "../field/transactions-field-container";
import { TableField } from "@csas-smart/gti-ui-comps";
import UserFieldContainer from "../field/user-field-container";
import ToggleGroupFieldContainer from "../field/togglegroup-field-container";
import ProductFieldContainer from "../field/product-field-container";
import DisbursementOrdersFieldContainer from "../field/disbursement-orders-field-container";
import PensionsToggleGroupFieldContainer from "../field/pensions-togglegroup-field-container";
import ForeignPaymentFieldContainer from "../field/payments/foreign-payment-field-container";
import LoanLimitSliderFieldContainer from "../field/loan-limit-slider-field-container";
import AppointedPersonsFieldContainer from "../field/appointed-persons-field-container";
import ButtonFieldContainer from "../field/button-field-container";
import {InfoCardField} from "@csas-smart/gti-corp-landing-ui";
import RangeInterestTableFieldContainer from "../field/cga/range-interest-table-field";
import CompanyTableListField from "../field/cosi/company-table-list-field";
import SigmaDocumentListPreviewFieldContainer from "../field/sigma/sigma-document-list-preview-field";
import NavigationFieldContainer from "../field/navigation-field-container";
import BranchFieldContainer from "../field/branch-field-container";
import RecordListFieldContainer from "../field/recordlist-field-container";
import PersonInfoListFieldContainer from "../field/person-info-list-field-container";
import SimilarCasesFieldContainer from "../field/similar-cases-field-container";
import SigmaBranchSignatureOverviewFieldContainer from "../field/sigma/sigma-branch-signature-flow-overview-field";
import FesyncPollingFieldContainer from "../field/fesync-polling-field-container";
import SscsLocationFieldContainer from "../field/sscs/sscs-location-field";
import SscsConditionsCheckSummaryFieldContainer from "../field/sscs/sscs-conditions-summary-field";
import SscsConditionsCheckFieldContainer from "../field/sscs/sscs-conditions-check-field";
import {CardLimitsFieldContainer} from "../field/card-limits-field-container";
import {CardSelectField} from "../field/cabu/card-select-field";
import CommentsFieldContainer from '../field/comments-field-container';
import { DemoCalculatorField } from "core-fields";

// Lazily loading "@csas-smart/gti-rkt-ui":
const DescriptionField = lazy(() =>
  import("@csas-smart/gti-rkt-ui").then(module => ({ default: module.DescriptionField }))
);
const TransactionDetail = lazy(() =>
  import("@csas-smart/gti-rkt-ui").then(module => ({ default: module.TransactionDetail }))
);
const ButtonLinkField = lazy(() =>
  import("@csas-smart/gti-rkt-ui").then(module => ({ default: module.ButtonLinkField }))
);
const CardLinkField = lazy(() =>
  import("@csas-smart/gti-rkt-ui").then(module => ({ default: module.CardLinkField }))
);
const MulticurrencyField = lazy(() =>
  import("@csas-smart/gti-rkt-ui").then(module => ({ default: module.MulticurrencyField }))
);

// Lazily loading "@csas-smart/gti-corporate-ui":
const ProductCardsField = lazy(() =>
  import("@csas-smart/gti-corporate-ui").then(module => ({ default: module.ProductCardsField }))
);

// Lazily loading "@csas-smart/gti-g4bmigr-ui": 
const MigrationRepresentativeListField = lazy(() =>
  import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.MigrationRepresentativeListField }))
);
const AnchorNavigationField = lazy(() =>
  import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.AnchorNavigationField }))
);
const MigrTimelineField = lazy(() =>
  import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.MigrTimelineField }))
);
const GeorgeDownloadQrField = lazy(() =>
  import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.GeorgeDownloadQrField }))
);

const componentsList = {
  "string-field": { clazz: StringField, descriptor: null },
  "boolean-field": { clazz: BooleanField, descriptor: null },
  "combobox-field": { clazz: ComboBoxField, descriptor: null },
  "togglegroup-field": { clazz: ToggleGroupFieldContainer, descriptor: null },
  "text-field": { clazz: TextField, descriptor: null },
  "showtext-field": { clazz: ShowTextField, descriptor: null },
  "date-field": { clazz: DateFieldContainer, descriptor: null },
  "documents-field": { clazz: DocumentsField, descriptor: null },
  "account-field": { clazz: AccountFieldContainer, descriptor: null },
  "contacts-field": { clazz: ContactsFieldContainer, descriptor: null },
  "disbursement-orders-field": {
    clazz: DisbursementOrdersFieldContainer,
    descriptor: null,
  },
  "product-field": { clazz: ProductFieldContainer, descriptor: null },
  "decimal-field": { clazz: DecimalField, descriptor: null },
  "payment-surcharge-field": { clazz: PaymentField, descriptor: null },
  "payment-surcharge-v2-field": {
    clazz: PaymentSurchargeV2FieldContainer,
    descriptor: null,
  },
  "alerts-field": {
    clazz: AlertsFieldContainer,
    descriptor: "comp/field/contacts/alerts-field",
  },
  "horizontal-separator-field": {
    clazz: HorizontalSeparatorField,
    descriptor: "comp/field/horizontal-separator-field",
  },
  "currency-amount-field": {
    clazz: CurrencyAmountFieldContainer,
    descriptor: "comp/field/currency-amount-field",
  },
  "third-party-list-field": { clazz: EmployerSearchField, descriptor: null },
  "graphql-field": { clazz: GraphqlFieldContainer, descriptor: null },
  "logout-field": {
    clazz: LogoutField,
    descriptor: "comp/field/logout-field",
  },
  "appointed-persons-field": {
    clazz: AppointedPersonsFieldContainer,
    descriptor: "comp/field/appointed-persons-field",
  },
  "sign-togglegroup-field": {
    clazz: SignToggleGroupField,
    descriptor: "comp/field/signtogglegroup-field",
  },
  "contribution-amount-field": {
    clazz: ContributionAmountFieldContainer,
    descriptor: "comp/field/contribution-amount-field",
  },
  "pensions-togglegroup-field": {
    clazz: PensionsToggleGroupFieldContainer,
    descriptor: "comp/field/pensions-togglegroup-field",
  },
  "ng-print-field": {
    clazz: NgPrintField,
    descriptor: "comp/field/ng-print-field",
  },
  "select-date-field": {
    clazz: SelectDateField,
    descriptor: "comp/field/select-date-fields",
  },
  "feedback-field": {
    clazz: FeedbackFieldContainer,
    descriptor: "comp/field/feedback-field",
  },
  "sscs-select-disbursement-type-field": {
    clazz: SscsSelectDisbursementTypeFieldContainer,
    descriptor: "comp/field/sscs/sscs-select-disbursement-type-field",
  },
  "sscs-loan-purposes-field": {
    clazz: SscsLoanPurposesBopFieldContainer,
    descriptor: "comp/field/sscs/sscs-loan-purposes-bop-field",
  },
  //'sscs-loan-purposes-field': {clazz: SscsLoanPurposesField, descriptor: 'comp/field/sscs/sscs-loan-purposes-field'},
  "sscs-select-loan-purposes-field": {
    clazz: SscsSelectLoanPurposesFieldContainer,
    descriptor: "comp/field/sscs/sscs-select-loan-purposes-field",
  },
  "sscs-loan-conditions-field": {
    clazz: SscsLoanConditionsFieldContainer,
    descriptor: "comp/field/sscs/sscs-loan-conditions-field",
  },
  "sscs-disbursement-orders-field": {
    clazz: SscsDisbursementOrdersBopFieldContainer,
    descriptor: "comp/field/sscs/sscs-disbursement-orders-bop-field",
  },
  //'sscs-disbursement-orders-field': {clazz: SscsDisbursementOrdersField, descriptor: 'comp/field/sscs/sscs-disbursement-orders-field'},
  "sscs-location-field": {
    clazz: SscsLocationFieldContainer,
    descriptor: null
  },
  'sscs-conditions-check-summary-field': {
    clazz: SscsConditionsCheckSummaryFieldContainer,
    descriptor: null
  },
  'sscs-conditions-check-field': {
    clazz: SscsConditionsCheckFieldContainer,
    descriptor: null
  },
  "rescompany-field": {
    clazz: ResCompanyField,
    descriptor: "comp/field/rescompany-field",
  },
  "iq-field": { clazz: IqFieldContainer, descriptor: null },
  "personal-id-field": { clazz: PersonalIdField, descriptor: null },
  "specified-owners-field": {
    clazz: SpecifiedOwnersFieldContainer,
    descriptor: null,
  },
  "codebook-field": { clazz: CodebookFieldContainer, descriptor: null },
  "address-form-field": { clazz: AddressFormFieldContainer, descriptor: null },
  "ruian-field": { clazz: RuianField, descriptor: null },
  "conditions-check-field": {
    clazz: ConditionsCheckContainer,
    descriptor: null,
  },
  "conditions-summary-field": {
    clazz: ConditionsSummaryContainer,
    descriptor: null,
  },
  "buttongroup-field": { clazz: ButtongroupField, descriptor: null },
  "sigma-documents-field": {
    clazz: SigmaDocumentsFieldContainer,
    descriptor: null,
  },
  "sigma-documents-to-sign-field": {
    clazz: SigmaDocumentsToSignFieldContainer,
    descriptor: null,
  },
  "sigma-download-field": {
    clazz: SigmaDownloadFieldContainer,
    descriptor: null,
  },
  "sigma-sign-methods-field": {
    clazz: SigmaSignMethodsFieldContainer,
    descriptor: null,
  },
  "sigma-document-list-preview-field": {
    clazz: SigmaDocumentListPreviewFieldContainer,
    descriptor: null,
  },
  "sigma-branch-signature-flow-overview-field": {
    clazz: SigmaBranchSignatureOverviewFieldContainer,
    descriptor: null,
  },
  "restcaller-field": { clazz: RestCallerFieldContainer, descriptor: null },
  "kyc-field": { clazz: KycFieldContainer, descriptor: null },
  "kyc-corporate-field": {
    clazz: KycCorporateFieldContainer,
    descriptor: null,
  },
  "person-info-field": { clazz: PersonInfoFieldContainer, descriptor: null },
  "person-info-list-field": { clazz: PersonInfoListFieldContainer, descriptor: null },
  "company-field": { clazz: CompanyFieldContainer, descriptor: null },
  "collapsible-field": {
    clazz: CollapsibleField,
    descriptor: "comp/field/collapsible-field",
  },
  "ms-teams-video-call-field": {
    clazz: MsTeamsVideoCallFieldContainer,
    descriptor: null,
  },
  "refix-field": { clazz: RefixFieldContainer, descriptor: null },
  "signatory-field": { clazz: SignatoryFieldContainer, descriptor: null },
  "account-statements-field": {
    clazz: AccountStatementsFieldContainer,
    descriptor: null,
  },
  "product-promo-field": {
    clazz: ProductPromoFieldContainer,
    descriptor: null,
  },
  "future-owners-field": {
    clazz: FutureOwnersFieldContainer,
    descriptor: null,
  },
  "loan-modeling-field": {
    clazz: LoanModelingFieldContainer,
    descriptor: null,
  },
  "card-select-field": { clazz: CardSelectField, descriptor: null },
  "company-table-list-field": { clazz: CompanyTableListField, descriptor: null },
  "agreements-field": { clazz: AgreementsFieldContainer, descriptor: null },
  "stepper-field": { clazz: StepperFieldContainer, descriptor: null },
  "transactions-select-field": {
    clazz: TransactionsFieldContainer,
    descriptor: null,
  },
  "description-field": {
    clazz: DescriptionField,
    descriptor: null,
  },
  "transaction-detail-field": {
    clazz: TransactionDetail,
    descriptor: null,
  },
  "button-link-field": {
    clazz: ButtonLinkField,
    descriptor: null,
  },
  "card-link-field": {
    clazz: CardLinkField,
    descriptor: null,
  },
  "george-download-qr-field": {
    clazz: GeorgeDownloadQrField,
    descriptor: null
  },
  "george-id-activation-field": {
    clazz: GeorgeIdActivationFieldContainer,
    descriptor: null,
  },
  "generate-and-sign-doc-field": {
    clazz: GenerateAndSignDocFieldContainer,
    descriptor: null,
  },
  "migration-representative-list-field": {
    clazz: MigrationRepresentativeListField,
    descriptor: null
  },
  "migr-timeline-field": {
    clazz: MigrTimelineField,
    descriptor: null
  },
  "representative-authorization-field": {
    clazz: RepresentativeAuthorizationContainer,
    descriptor: null,
  },
  "data-sign-field": { clazz: DataSignFieldContainer, descriptor: null },
  "representative-list-field": {
    clazz: RepresentativeListFieldContainer,
    descriptor: null,
  },
  "client-info-field": { clazz: ClientInfoFieldContainer, descriptor: null },
  "anchor-navigation-field": { clazz: AnchorNavigationField, descriptor: null },
  "documents-to-sign-field": {
    clazz: DocumentsToSignFieldContainer,
    descriptor: null,
  },
  "generate-doc-buttons-field": {
    clazz: GenerateDocButtonsFieldContainer,
    descriptor: null,
  },
  "safety-method-activation-field": {
    clazz: SafetyMethodActivationFieldContainer,
    descriptor: null
  },
  "device-activation-field": {
    clazz: DeviceActivationFieldContainer,
    descriptor: null
  },
  "checklist-device-activation-field": {
    clazz: ChecklistDeviceActivationFieldContainer,
    descriptor: null
  },
  "note-field": { clazz: NoteFieldContainer, descriptor: null },
  "table-field": { clazz: TableField, descriptor: null },
  "user-field": { clazz: UserFieldContainer, descriptor: null },
  "date-time-field": {
    clazz: DateTimeField,
    descriptor: "comp/field/datetime/datetime",
  },
  "foreign-payment-field": {
    clazz: ForeignPaymentFieldContainer,
    descriptor: null,
  },
  "loan-limit-slider-field": {
    clazz: LoanLimitSliderFieldContainer,
    descriptor: null,
  },

  "button-field-container": { clazz: ButtonFieldContainer, descriptor: null },
  "info-card-field": { clazz: InfoCardField, descriptor: null },
  "range-interest-table-field": { clazz: RangeInterestTableFieldContainer, descriptor: null },
  "list-field": { clazz: ListField, descriptor: null },
  "product-card-list-field": { clazz: ProductCardListField, descriptor: null },
  "recordlist-field": { clazz: RecordListFieldContainer, descriptor: null },
  "product-cards-field": { clazz: ProductCardsField, descriptor: null },
  "timeline-field": { clazz: TimelineField, descriptor: null },
  "navigation-field": {clazz: NavigationFieldContainer, descriptor: null},
  "branch-field": { clazz: BranchFieldContainer, descriptor: null},
  "similar-cases-field": { clazz: SimilarCasesFieldContainer, descriptor: null },
  "fesync-polling-field": { clazz: FesyncPollingFieldContainer, descriptor: null },
  "multicurrency-field": {
    clazz: MulticurrencyField,
    descriptor: null,
  },
  "card-limits-field": { clazz: CardLimitsFieldContainer, descriptor: null },
  "demo-calculator-field": { clazz: DemoCalculatorField, descriptor: null },
  "icon-field": { clazz: IconField, descriptor: null },
  "comments-field": { clazz: CommentsFieldContainer, descriptor: null },

};

export const updateDescriptor = (componentName, descriptor) => {
  componentsList[componentName].descriptor = descriptor;
};

export default componentsList;
