import SessionWrapper, {GSTATE_JSON_TYPE} from "../../sessions/sessionWrapper";
import linkCreatorDelegate from "../link-creators/link-creator-delegate";
import logger from "../loggly/gtiLogger";


import AdfsCodeHandler from "./adfs2016/adfsCodeHandler";
import AdfsTokenHandler from "./adfs2016/adfsTokenHandler";
import AdfsSuccessHandler from "./adfs2016/adfsSuccessHandler";
import ExtSystemStateManager from "./common/extSystemStateManager";

export const ADFS_CLIENT = "urn:csas:apps:bti_p24";
class Adfs2016Service {
    tokenReceivedHandler;
    sessionWrapper;
    extSystemStateManager;
    handlers = [new AdfsCodeHandler(), new AdfsTokenHandler(), new AdfsSuccessHandler()];
    SUPPORTED_AUTH_TYPE='adfs-2016';
    SUPPORTED_API_KEY = import.meta.env.VITE_APP_P24_API_KEY;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
        this.extSystemStateManager = new ExtSystemStateManager();
    }

    supportType = (authType) => {
        return authType === this.SUPPORTED_AUTH_TYPE;
    }

    withTokenReceivedHandler = (tokenReceivedHandler) => {
        this.tokenReceivedHandler = tokenReceivedHandler;
        return this;
    }

    init = (params) => {
        if(!this.tokenReceivedHandler) {
            return;
        }

        this.extSystemStateManager.handleParams(params);
        const resolvedHandlers = this.handlers.filter(h => h.canHandle(params));
        if(resolvedHandlers.length === 1) {
            resolvedHandlers[0].handleParams(params);
        } else {
            logger.info(`Resolved more than one adfs2019 handler for params ${params}`);
        }

        if(this.sessionWrapper.getAccessToken() && !params?.code && params?.state) {
            this.tokenReceivedHandler(params);
        }
    }

    revoke = (hash) => {
        console.log("Revoking case " + hash);
        this.sessionWrapper.clearSessionStorage();
        return Promise.resolve();
    }

    redirectToGeorge = () => {
        //but actually not
        this.handover();
    }

    handover = (ctx?) => {
        const gstate = this.sessionWrapper.getGState(GSTATE_JSON_TYPE);
        const redirectUri = gstate?.redirectUri;
        this.sessionWrapper.clearSessionStorage();
        if(redirectUri){
            const linkCreator = new linkCreatorDelegate().getLinkCreator(ctx);
            const link = linkCreator.prepareLink(redirectUri, ctx);
            window.location.href = link;
        } else {
            //TODO mozna ukonceni OBO?? pokud jde o starter...
            window.close();
        }
    }

    logout = () => {
        this.sessionWrapper.clearSessionStorage();
        logger.info("Logout was not implemented yet for adfsService")
    }

    getLogoutTimeout = () => {
        return import.meta.env.VITE_APP_ADFS_AUTOMATIC_LOGOUT_SECS;
    }

    getSupportedAuthType = () => {
        return this.SUPPORTED_AUTH_TYPE;
    }

    getSupportedApiKey = () => {
        return this.SUPPORTED_API_KEY;
    }
}

export default new Adfs2016Service();

