import Resource from '../core/serverresource';
import RestCaller from '../core/restCaller';
import {addTranslation, processTranslations} from '../core/i18n';
import componentsList, {updateDescriptor} from '../core/componentsList';
import {resolveCaseTypeFromState, resolveHashIdFromState} from '../core/utils/taskUtils';
import {regenerateTemplates} from './templateActions';
import TranslationServiceDelegate from '../core/translations/translationServiceDelegate';
import logger from "../core/loggly/gtiLogger";
import {isEmptyString} from "@csas-smart/gti-ui-comps";

export const editorsLoaded = () => ({type: 'EDITORS_LOADED'});
export const storeHash = hashId => ({type: 'STORE_HASH_ID', data: hashId});
export const showErrorMessage = (msg) => {
    return (dispatch) => {
        dispatch({type: 'SHOW_ERROR_MESSAGE', message: msg});
        return Promise.resolve();
    }
};
export const blockScreen = (msg) => {
    return (dispatch) => {
        dispatch({type: 'BLOCK_SCREEN_WITH_MESSAGE', message: msg});
        return Promise.resolve();
    }
};

export const showSpinner = (fieldName) => {
    return (dispatch) => {
        dispatch({type: 'SHOW_SPINNER', data: fieldName});
        return Promise.resolve();
    }
};

export const hideSpinner = (fieldName) => {
    return (dispatch) => {
        dispatch({type: 'HIDE_SPINNER', data: fieldName});
        return Promise.resolve();
    }
};
export const hideError = () => ({type: 'HIDE_ERROR'});
export const taskCompleted = () => ({type: 'TASK_COMPLETED'});
export const taskCancelled = () => ({type: 'TASK_CANCELLED'});
export const taskSaved = () => ({type: 'TASK_SAVED'});
export const activityClosed = (result) => ({type: 'CLOSE_ACTIVITY', taskStatus: result.toString()});
export const activityCompletionFailed = () => ({type: 'COMPLETE_ACTIVITY_FAILED'});
export const activityDesignLoaded = (result) => ({type: 'ACTIVITY_DESIGN_LOADED', activity: result});
export const caseCreated = (result) => ({type: 'CASE_CREATED', georgeTask: result});
export const componentMounted = (componentName, validationCallback) => ({type: 'COMPONENT_MOUNTED', component: {name: componentName, validation: validationCallback} });
export const componentUnmounted = (componentName) => ({type: 'COMPONENT_UNMOUNTED', component: {name: componentName }});
export const fireAttrChangeInternal = (payload) =>  ({type : 'CHANGE_ATTRIBUTE', name: payload.name, value: payload.value, append: payload.append});
export const gaExternalIdLoaded = (extId) => ({type: 'GA_EXT_ID_LOADED', extId: extId})
export const gaApplicationCreated = (applicationId) => ({type: 'GA_APPLICATION_CREATED', applicationId: applicationId})
export const gaSavePreviousStep = (activityName) => ({type: 'GA_SAVE_PREVIOUS_STEP', activityName: activityName})


export const fireAttrChange = payload => {
    return (dispatch) => {

        const attrChangePromise = new Promise((resolve) => {
            dispatch(fireAttrChangeInternal(payload));
            resolve();
        });
        
        attrChangePromise
            .then(() => dispatch(regenerateTemplates(payload)));
        
        return Promise.resolve();
    }
}

export const fireTaskCompleted =() => {
    return (dispatch) => {
        dispatch(taskCompleted());
        return Promise.resolve();
    }
};

export const fireActivityCompletionFailed = () => {
    return (dispatch) => {
        dispatch(activityCompletionFailed());
        return Promise.reject();
    }
};

export const fireNextTaskCall = () => {
    return () => {
        const previousHashId = resolveHashIdFromState();

        return RestCaller.httpGet(Resource.pollNextTask(previousHashId))
            .then(resp => resp.nextHashId)
            .catch(err => {
                console.error("Error polling next-task: ", err);
                return null;
            });
    }
}

export const fireCaseCancelled = (skipGlobalExceptionHandler) => {
    return (dispatch) => {
        const hashId = resolveHashIdFromState();

        if(isEmptyString(hashId)) {
            return Promise.reject("Missing hashId parameter");
        }

        return RestCaller.httpDelete(Resource.killCase(hashId))
            .then(() => {
                logger.info("Received response for rejecting case " + hashId);
                dispatch(taskCancelled());
                logger.info("Tak cancelled for rejecting case " + hashId);
                return Promise.resolve();
            }, error => {
                logger.error("Error closing activity " + ": " + error);
                error.skipGlobalExceptionHandler = !!skipGlobalExceptionHandler;
                return Promise.reject(error);
            })
    }
};


export const fireCaseSaved = (skipGlobalExceptionHandler) => {
    return (dispatch, getState) => {
        const hashId = resolveHashIdFromState();
        const {attributes} = getState().task.activity;

        if(isEmptyString(hashId)) {
            return Promise.reject("Missing hashId parameter");
        }

        return RestCaller.httpPurePut(Resource.saveActivity(hashId), attributes)
            .then(() => {
                dispatch(taskSaved());
                logger.info("Received response for saving case " + hashId);
                return Promise.resolve();
            }, error => {
                logger.error("Error saving activity " + ": " + error);
                error.skipGlobalExceptionHandler = !!skipGlobalExceptionHandler;
                return Promise.reject(error);
            })
    }
};

export const loadProducts = (props, hashId) => {
        const requestDto = {
            fieldName: props.fieldName,
        };
        return RestCaller.httpPost(Resource.loadProduct(hashId), requestDto);
}

function processActivityTranslations(activity, caseType, hashId) {
    return (dispatch, getState) => {
        if (getState().task.componentsLoaded) {
            processTranslationsInternal(activity);
            return Promise.resolve(activity);
        } else {
            return RestCaller.httpGet(Resource.loadRequiredEditorNames(caseType, hashId))
                .then(result => {
                    // filter component list to contain required editors only
                    const requiredEditors = Object.keys(componentsList)
                                            .filter(key => result.requiredEditors.includes(key))
                                            .reduce((obj, key) => {
                                            obj[key] = componentsList[key];
                                            return obj;
                                            }, {});
                    // fetch all required editors                                    
                    return Promise.all(loadEditorsInternal(requiredEditors))
                    .then(() => dispatch(editorsLoaded(requiredEditors)));
                })
                .then(() => {
                    processTranslationsInternal(activity);
                    return Promise.resolve(activity);
                })
                .catch(() => {
                    console.log("Failed to load editors.");
                });
        }
    }
}

function processTranslationsInternal(activity) {
    const keys = Object.assign({});

    processTranslations(activity.design.translations, keys, 'task', activity.name);

    activity.design.fields.map(field => {
        //const defaultTranslationsAll = componentsList[field.editor.name].descriptor.defaultTranslations;
        //first of all fill default property values
        processTranslations(field.editor.translations, keys, field.name, null);
        processTranslations(field.translations, keys, field.name, null);
    });

    addTranslation(keys);
}

// Do I need to send to server activity name?
export function fireActivityComplete(activity) {
    return (dispatch, getState) => {

        const hashId = resolveHashIdFromState();
        const {attributes} = getState().task.activity;

        return RestCaller.httpPut(Resource.completeActivity(hashId), attributes)
            .then(result => {
                dispatch(activityClosed(result))
                dispatch(gaSavePreviousStep(activity.activityName))
                return Promise.resolve(result);
            }, error => {
                logger.error("Error closing activity " + activity + ": " + error);
                return Promise.reject(error);
            })
    }
}

export function fireLoadActivityDesign() {
    return (dispatch) =>  {
        const hashId = resolveHashIdFromState();
        const caseType = resolveCaseTypeFromState();
        return RestCaller.httpGet(Resource.loadActivity(hashId))
            .then(result => dispatch(processActivityTranslations(result, caseType, hashId)))
            .then(result => {
                dispatch(activityDesignLoaded(result));
                return Promise.resolve(result);
            });
    }
}

export const takeTask = () => {
    return (dispatch, getState) => {
        const hashId = resolveHashIdFromState(getState);
        return RestCaller.httpPut(Resource.takeTaskUrl(hashId))
            .then(result => {
                if(!result || result.taskStatus !== "IN_PROGRESS") {
                    throw new Error(`Cannot take task ${hashId}`)
                }

                return Promise.resolve(result);
            });
    }
}

export function fireStoreHashId(hashId) {
    return (dispatch) => {
        dispatch(storeHash(hashId));
        return Promise.resolve();
    }
}

export function caseCreatedPromise(result) {
    return (dispatch) => {
        dispatch(caseCreated(result));
        return Promise.resolve(result);
    }
}

export function loadProductName(module) {
    return () => {
        const hashId = resolveHashIdFromState();
        const translationService = TranslationServiceDelegate.getTranslationService(module, hashId);

        logger.info("Getting name of product for case " + hashId);
        // Load product name, only where there is a service registered to do so. Otherwise, the task label from configuration will be used.
        if(translationService){
            return translationService.getProductName()
                .then(result => {
                    return Promise.resolve(result.productI18N);
                })
                .catch(error => {
                    logger.error(error);
                    return Promise.resolve("");
                });
        }
    }
}

export function loadState(skipGlobalExceptionHandler) {
    return () => {
        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(Resource.getApplicationState(hashId)).then(result => {
            return Promise.resolve(result);
        }).catch(error => {
            error.skipGlobalExceptionHandler = skipGlobalExceptionHandler;
            return Promise.reject(error);
        })
    }
}

export function persistState(state) {
    return () => {
        const hashId = resolveHashIdFromState();

        const request = {
            hashId: hashId,
            feSession: state
        };

        return RestCaller.httpPost(Resource.getApplicationState(hashId), request)
    }
}

export function loadEditors(t, caseType, hashId) {
    return (dispatch) => {
        return RestCaller.httpGet(Resource.loadRequiredEditorNames(caseType, hashId))
        .then(result => {
            // filter component list to contain required editors only
            const requiredEditors = Object.keys(componentsList)
                                    .filter(key => result.requiredEditors.includes(key))
                                    .reduce((obj, key) => {
                                    obj[key] = componentsList[key];
                                    return obj;
                                    }, {});
            // fetch all required editors                                    
            return Promise.all(loadEditorsInternal(requiredEditors))
            .then(() => dispatch(editorsLoaded(requiredEditors)));
        })
        .catch(() => dispatch(blockScreen(
            t("common:popup.error.internalError")
        )));
    }
}

//promise resolved
const loadEditorsInternal = (compsList) => {
    return Object.keys(compsList).map(component =>
        new Promise((resolve, reject) => {
            logger.info("Getting info about " + component);

            RestCaller.httpGet(Resource.loadEditor(component))
                .then(result => {
                    logger.info("Updating component: " + component + " with result: " + result);
                    updateDescriptor(component, result);
                    resolve();
            }, error => {
                    logger.info('Gettin descriptor for component ' + component + ' failed', error);
                    reject(error);
                });
        })
    );
};

export function loadCodebook(codebook, attributes, lang) {
    return new Promise((resolve, reject) => {
        fetch(Resource.getCodebook(codebook), {
            method: 'POST',
            body: JSON.stringify(attributes),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Language": lang
            }
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then(function (result) {
                resolve(result);
            })
            .catch(function (error) {
                const errorMsg = `request failed', ${error.statusText}`;
                logger.error(errorMsg);
                reject(errorMsg);
            });
    })
}

export function loadCodebookWithLangCallback(codebook, attributes, lang, callback) {
    fetch(Resource.getCodebook(codebook), {
        method: 'POST',
        body: JSON.stringify(attributes),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "language": lang,
            "accept-language": lang
        }
    })
        .then(Resource.checkStatus)
        .then(Resource.parseJSON)
        .then(function (result) {
            callback(result);
        })
        .catch(function (error) {
            logger.error('request failed', error.statusText);
        });
}

export function loadCodebookWithLang(codebook, attributes, lang) {
    return fetch(Resource.getCodebook(codebook), {
        method: 'POST',
        body: JSON.stringify(attributes),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "language": lang,
            "accept-language": lang
        }
    })
        .then(Resource.checkStatus)
        .then(Resource.parseJSON)
        .then((result) => Promise.resolve(result))
        .catch(function (error) {
            logger.error('request failed', error.statusText);
        });
}

export function loadContributionData(hashId) {
    return RestCaller.httpGet(Resource.getContributionData(hashId));
}

export function toggleFieldUpdated(fieldName, toggleAttr) {
    return (dispatch) => {
        const hashId = resolveHashIdFromState();

        const request = {
            value : toggleAttr.value
        }

        //[1] first of all call real time update
        return RestCaller.httpPut(Resource.updateToggleFieldState(fieldName, hashId), request)
            //[2] then update redux attributes and return result as Promise
                .then(result => {
                    return dispatch(fireAttrChange({name: toggleAttr.name, value: result.value}))
                        .then(() => Promise.resolve(result));
                })
            //[3] in case of error return Promise reject with error message
                .catch(error => {
                    logger.error("Error updating toggle field " + fieldName + ": " + error);
                    return Promise.reject(error);
                });
    }
}

export function restcaller(fieldName) {
    return (dispatch) => {
        const hashId = resolveHashIdFromState();

        return RestCaller.httpPost(Resource.restCallerUrl(fieldName, hashId))
            .then(result => {
                const responses = result.responses;
                for(let i=0; i<responses.length; i++) {
                    dispatch(fireAttrChange({name: responses[i].attribute, value: responses[i].response}));
                }
                return Promise.resolve(result);
            })
            .catch(error => {
                logger.error("Error in restcaller " + fieldName + ": " + error);
                throw error;
            });
    }
}
