import React from 'react';
import AlertComp from '../../comp/alert-comp';

const GcedrError = (props) => {
    const {apology, message, type, t} = props;

    const buildTemplate = () => {
        let template = '';
        if(apology) {
            template += "<h5><strong>" + t('common:field.gcedr-conditions-check-field.apology')+ "</strong></h5>";
        }
        template += "<p>" + message + "</p>";

        return template
    }

    return(
        <AlertComp
            name='conditions-check-error'
            type={type}
            template={buildTemplate()}
        />
    )
}

export default GcedrError;