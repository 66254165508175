import {DateField} from "@csas-smart/gti-ui-comps";

import React from "react";
import {addLabourDays} from "../common/reusableFunctions";

const DateFieldContainer = (props) => {

    return (
            <DateField {...props}
                addLabourDays={addLabourDays}/>
    )
};

export default DateFieldContainer;