import React from "react";
import {ProductPromoField} from "@csas-smart/gti-ui-comps";
import {Col, Row} from "reactstrap";


const ProductPromoFieldContainer = (props) => {

    return (
            <Row>
                <Col className={"p-0"}>
                    <ProductPromoField {...props} />
                </Col>
            </Row>
    )
};

export default ProductPromoFieldContainer;