import React, {Component} from "react";
import {Col, Row} from 'reactstrap';
import PropertiesLoader from '../../core/propertiesloader';
import {unescapeBrackets} from '../../core/i18n';
import TemplateWrapper from "../../core/lazy-wrappers/templateWrapper";
import Resource from '../../core/serverresource';
import RestCaller from '../../core/restCaller';
import {connect} from 'react-redux';
import {resolveHashIdFromState} from "../../core/utils/taskUtils";
import { isNumber} from '../../common/validations';
import {GdsFieldContainer} from "@csas-smart/gti-ui-comps";
import { PaymentSurchargeFieldProps, PaymentSurchargeFieldState } from "./types";
import { isEmptyString, isNotEmptyString } from "validations";
import { composeAccountObject } from "core-helpers";
import { Account } from "core-types";

const mapStateToProps = () => {
    return {
        hashId: resolveHashIdFromState()
    }
};

class PaymentSurchargeField extends Component<PaymentSurchargeFieldProps, PaymentSurchargeFieldState> {

    constructor(props) {
        super(props);
        const {field, attributes} = this.props;
        const watchedAttributes = PropertiesLoader.getProperty(field.editor, "watchedAttributes", []);
        const isStatic = watchedAttributes.length === 0;
        const isPaymentAmountEntered = (watchedAttributes.indexOf("mshuAmount") > -1);

        this.state = {
            template: this.loadTemplate(),
            watchedAttributes: watchedAttributes,
            payOffDate: attributes.payOffDate,
            paymentAmount: attributes.paymentAmount,
            fullAccountNumber: attributes.fullAccountNumber,
            isStatic: isStatic,
            isPaymentAmountEntered: isPaymentAmountEntered
        };
    }

    componentDidMount() {
        const {payOffDate, paymentAmount, fullAccountNumber} = this.props.attributes;
        const {hashId} = this.props;
        this.loadAmountAndFees(paymentAmount, payOffDate, fullAccountNumber, hashId);
    }

    componentWillReceiveProps(nextProps) {
        const nextPayOffDate = nextProps.attributes.payOffDate;
        const nextPaymentAmount = nextProps.attributes.paymentAmount;
        const nextFullAccountNumber = nextProps.attributes.fullAccountNumber;
        const {payOffDate, paymentAmount, fullAccountNumber} = this.props.attributes;
        const {hashId} = this.props;
        const {isPaymentAmountEntered} = this.state;
        // Load fees on attribute change
        if ((nextPayOffDate && nextPayOffDate !== payOffDate) || (nextPaymentAmount && nextPaymentAmount !== paymentAmount && isPaymentAmountEntered)
            || (nextFullAccountNumber && nextFullAccountNumber !== fullAccountNumber)) {
            this.loadAmountAndFees(nextPaymentAmount, nextPayOffDate, fullAccountNumber, hashId);
        }
    }

    loadAmountAndFees(paymentAmount, payOffDate, fullAccountNumber, hashId) {
        const {isPaymentAmountEntered} = this.state;

        if (isPaymentAmountEntered) {
            if (isNumber(paymentAmount) && isNumber(payOffDate) && isNotEmptyString(hashId)) {
                this.loadFees(paymentAmount, payOffDate, hashId);
            }
        } else {
            if (isNotEmptyString(fullAccountNumber) && isNumber(payOffDate) && isNotEmptyString(hashId)) {
                const account: Account = composeAccountObject(fullAccountNumber);
                if (isEmptyString(account.account)) {
                    throw Error('popup.error.invalidFullAccountNumber');
                }
                const accountPrefix = isNotEmptyString(account.prefix) ? account.prefix : "000000";
                RestCaller.httpGet(Resource.getPayOffFees(accountPrefix, account.account, payOffDate, hashId))
                    .then(result => {
                        this.processPaymentAmount(result);
                        this.loadFees(result.principalAmount, payOffDate, hashId);
                    })
                    .catch(error => {
                        console.error(error);
                        throw Error('popup.error.invalidFullAccountNumber');
                    });
            }
        }
    }

    processPaymentAmount(result) {
        const {attributes} = this.props.field.selector;
        if (attributes["paymentAmount"]) {
            const paymentAmountAttrName = attributes["paymentAmount"].name;
            this.props.fieldValueChanged({
                name: paymentAmountAttrName,
                value: result.principalAmount
            });
        }
    }

    loadFees(paymentAmount, payOffDate, hashId) {
        if (isNumber(paymentAmount) && isNumber(payOffDate) && isNotEmptyString(hashId)) {
            let localPaymentDate = this.formatToLocalDateInISO(payOffDate);
            RestCaller.httpGet(Resource.getFairCompensationFee(paymentAmount, localPaymentDate, hashId))
                .then(fairCompensationFeeResult => {
                    this.processFeeAmount(fairCompensationFeeResult);
                })
                .catch(error => {
                    console.error(error);
                    return Promise.reject(error);
                });
        }
    }

    processFeeAmount(fairCompensationFeeResult) {
        const {attributes} = this.props.field.selector;
        if (attributes["feeAmount"]) {
            const feeAmountAttrName = attributes["feeAmount"].name;
            this.props.fieldValueChanged({
                name: feeAmountAttrName,
                value: fairCompensationFeeResult.finalFee
            });
        }
    }

    formatToLocalDateInISO(date) {
        let dateObj = new Date(date)
        const offset = dateObj.getTimezoneOffset()
        dateObj = new Date(dateObj.getTime() - (offset * 60 * 1000))
        return dateObj.toISOString().split('T')[0];
    }

    loadTemplate() {
        const template = PropertiesLoader.getProperty(this.props.field.editor, "template", false);
        const translatedTemplate = this.props.t('field.properties.template');
        let resultTemplate = (!translatedTemplate || translatedTemplate === 'field.properties.template') ? template : translatedTemplate;
        resultTemplate = unescapeBrackets(resultTemplate);
        return resultTemplate;
    }

    render() {
        const {field, t, required} = this.props;
        const {template, isStatic} = this.state;

        return (<GdsFieldContainer tooltipKey="tooltipText" field={field} t={t} required={required} hideRequired={true}
                                componentId={'paymentSurchargeField'}>
            <Row>
                <Col md={12}>
                    <TemplateWrapper template={template} templateName={'payment-surcharge-' + field.name}
                                     isStatic={isStatic}/>
                </Col>
            </Row>
        </GdsFieldContainer>);
    }
}

export default connect(mapStateToProps, null)(PaymentSurchargeField);
