import React from "react";
import {resolveHashIdFromState} from "../core/utils/taskUtils";
import RestCaller from "../core/restCaller";
import Resource from "../core/serverresource";
import {LoanModelingField} from "@csas-smart/gti-corp-landing-ui/dist";


const LoanModelingFieldContainer = (props) => {
    const hashId = resolveHashIdFromState();

    const getLoan = (inputData) => {
        let input = {"hashId": hashId, "paymentAmount": inputData}
        return RestCaller.httpPost(Resource.postLoanModeling(), input);
    }

    return <LoanModelingField {...props} getLoan={getLoan}/>
}

export default LoanModelingFieldContainer