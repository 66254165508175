import {SigmaDocumentListPreviewField} from '@csas-smart/gti-sigma-ui';
import React from "react";
import SessionWrapper from '../../sessions/sessionWrapper';
import {FieldProps} from "@csas-smart/gti-ui-comps";

export interface SigmaDocumentListPreviewFieldProps extends FieldProps {
    attributes: {
        folderId: string,
    };
}

const SigmaDocumentListPreviewFieldContainer = (props: SigmaDocumentListPreviewFieldProps) => {

    const sessionWrapper = new SessionWrapper();

    const folderApiConfig = {
        environment: import.meta.env.VITE_ENV.toUpperCase(),
        webApiKey: import.meta.env.VITE_APP_API_KEY,
        accessToken: sessionWrapper.getAccessToken()
      }

    return (
        <SigmaDocumentListPreviewField {...props} folderApiConfig={folderApiConfig} lang={sessionWrapper.getLowerCaseLanguage()}/>
    )
};

export default SigmaDocumentListPreviewFieldContainer;