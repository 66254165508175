import React, {Component, Suspense} from "react";
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import {objectKeysToLowerCase} from "./utils/queryUtils";
import Resource from './serverresource';
import {fireCaseCancelled, hideError, loadEditors} from '../actions/taskActions';
import {withTranslation} from 'react-i18next';
import {getLocale, refreshTranslator, setDateFnsLocale} from "./i18n";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {connect} from 'react-redux';
import {DeviceTypeHandler} from '@csas-smart/gti-ui-comps';
import {AppContextHandler} from '@csas-smart/gti-ui-comps';
import GeorgeChatWrapper from './lazy-wrappers/george-chat-wrapper';
import {sessionLogin} from "../actions/mepActions";
import {isNumber} from "../common/validations";
import ErrorComponent from "./error-comp";
import {Helmet} from "react-helmet";
import dayjs from "dayjs";
import 'dayjs/locale/en';
import 'dayjs/locale/cs';
import authService from "./auths/authService";
import GdsLanguageProvider from "./gds/gds-language-provider";
import logger from './loggly/gtiLogger';
import {decodeBase64} from "./utils/objectUtils";
import {cancelCaseOnTimeout} from "./relevantStrategies/modules";
import SessionWrapper from "../sessions/sessionWrapper";
import GaTagManager from "./ga-tag-manager";
import {loadActor} from "../actions/userActions";
import 'virtual:svg-icons-register'


const HandlebarUtil = React.lazy(() => import('./handlebars'));
const Task = React.lazy(() => import('./task'));
const TaskInitializer = React.lazy(() => import('./taskInitializer'));
const NextTask = React.lazy(() => import('./next-task'));
const ActualTask = React.lazy(() => import('./actual-task'));


const mapDispatchToProps = (dispatch) => {
    return {
        loadEditors: (t, caseType, hashId) => dispatch(loadEditors(t, caseType, hashId)),
        closeErrorButton: () => dispatch(hideError(false)),
        fireCaseCancelled: (skipGlobalExceptionHandler) => dispatch(fireCaseCancelled(skipGlobalExceptionHandler)),
        sessionLogin: (cgpHashedId) => dispatch(sessionLogin(cgpHashedId)),
        loadActor: () => dispatch(loadActor())
    }
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.root.errorMessage,
        blockScreen: state.root.blockScreen,
        hashId: state.task.hashId,
        chatType: state.task.activity.chatType,
        task: state.task.georgeTask,
        actor: state.user.actor
    }
};

class App extends Component {

    constructor(props) {
        super(props);
        this.state = { loginStatus: false, initialization: true };
        this.events = ["load", "mousedown", "click", "keypress"];
        this.sessionWrapper = new SessionWrapper();
    }

    componentDidMount() {
        const mergedParams = this.props.mergedParams;
        Resource.initialize();

        authService
            .getService()
            .withTokenReceivedHandler(this.tokenReceivedHandler)
            .init(mergedParams);

    }

    tokenReceivedHandler = (params) => {
        dayjs.locale(getLocale());


        //Alien could not provide any state or any params - this code block will be executed only if params exists
        if(params) {
            //check if params is base64encoded
            if ((params.state && typeof params.state === 'string')) {
                console.log("app.js parse state after token receive: " + params.state);
                const parsedParams = decodeBase64(params.state);
                const loweredParsedParams = objectKeysToLowerCase(parsedParams);
                if (loweredParsedParams.casetype) {
                    params = loweredParsedParams;
                }
            }

            //chat storage
            if (import.meta.env.VITE_APP_AUTH_HEADER && params[import.meta.env.VITE_APP_URL_AUTH_PARAM]) {
                window.sessionStorage.setItem(import.meta.env.VITE_APP_AUTH_HEADER, params[import.meta.env.VITE_APP_URL_AUTH_PARAM]);
            }

            if (import.meta.env.VITE_APP_AUTH_HEADER_CORP && params[import.meta.env.VITE_APP_URL_AUTH_PARAM_CORP]) {
                window.sessionStorage.setItem(import.meta.env.VITE_APP_AUTH_HEADER_CORP, params[import.meta.env.VITE_APP_URL_AUTH_PARAM_CORP]);
            }

            if (import.meta.env.VITE_APP_AUTH_HEADER_BTI && params[import.meta.env.VITE_APP_URL_AUTH_PARAM_USER]) {
                window.sessionStorage.setItem(import.meta.env.VITE_APP_AUTH_HEADER_BTI, params[import.meta.env.VITE_APP_URL_AUTH_PARAM_USER]);
            }

            if (params && Object.keys(params).filter(key => key === 'zadost').length === 0) {
                this.sessionWrapper.setGtiState(JSON.stringify(params))
            }
            if(params.casetype) {
                this.sessionWrapper.setCaseType(params.casetype);
            }
            if(params.hashid) {
                this.sessionWrapper.setHashId(params.hashid);
            }
        }

        //Load actor
        return this.props.loadActor()
            .then(() => {
                this.setState({ loginStatus: true, initialization: false });
            });
    }

    registerEvents = () => {
        for (var i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
    };

    initComplete = () => {
        return this.props.loadEditors(this.props.t, this.sessionWrapper.getCaseType(), this.sessionWrapper.getHashId())
            .then(() => {
                refreshTranslator();
                this.registerEvents();
                this.setTimeout();
            });
    }

    shouldComponentUpdate() {
        return true;
    }

    resetTimeout = () => {
        this.clearTimeout();
        this.setTimeout();
    }

    clearTimeout = () => {
        if (this.logoutTimeout) {
            clearTimeout(this.logoutTimeout);
        }
    }

    setTimeout = () => {
        const strLogoutTimeout = authService.getService().getLogoutTimeout();
        const logoutTimeout = (strLogoutTimeout && isNumber(strLogoutTimeout)) ? Number(strLogoutTimeout) * 1000 : -1;

        if (logoutTimeout > 0) {
            this.logoutRetries = 0;
            this.logoutTimeout = setTimeout(this.logout, logoutTimeout);
        }
    }

    logout = () => {
        // Send a logout request to the API
        logger.info("Sending a logout request to the API...");
        this.destroy();

        if (this.props.task.taskStatus === "COMPLETED") {
            //just revoke token
            authService.getService().logout();
        } else {
            const module = this.sessionWrapper.getModule().toLowerCase();
            const cancelCase = cancelCaseOnTimeout(module);

            if(cancelCase){
                //cancel case and revoke token
                this.props.fireCaseCancelled(true)
                    .then(() => {
                        logger.info("Logging out after case " + new SessionWrapper().getHashId() + " was canceled")
                        authService.getService().logout()
                        logger.info("Logged out");
                    })
                    .catch(this.handleLogoutError);
            } else {
                authService.getService().logout()
                    .catch(this.handleLogoutError);
            }
        }
    }

    handleLogoutError = (err) => {
        logger.info(`logout failed: ${err}`);
        this.logoutRetries++;

        const baseRetryDelta = import.meta.env.VITE_APP_LOUGOUT_RETRY_BASE_DELTA_SECS || 0.5;
        const retryDeltaCap = import.meta.env.VITE_APP_LOGOUT_RETRY_MAX_DELTA_SECS || 10;
        const retryDelta = Math.min(retryDeltaCap, baseRetryDelta * Math.pow(2, this.logoutRetries - 1));

        logger.info(`Next Retry #${this.logoutRetries} in ${retryDelta} secs`)
        // intentionally not storing timeout handle in this.logoutTimeout to prevent interrupting logout flow
        setTimeout(this.logout, retryDelta * 1000);
    };

    destroy() {
        this.clearTimeout();

        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    closeErrorButton = () => {
        this.props.closeErrorButton();
    };

    render() {
        setDateFnsLocale()

        if (this.state.initialization) {
            return null; //todo - tady by to chtelo mzurku
        }

        //TODO - login failed - invalid token, non-existing sessionId
        if (!this.state.loginStatus) {
            this.logout();
            return null;
        }

        const helmet = <Helmet htmlAttributes={{ lang: getLocale() }} />;

        const { chatType, actor } = this.props;
        const chatWrapper = (chatType && chatType !== 'NONE') ? <GeorgeChatWrapper chatType={chatType} /> : null;
        const content = this.props.blockScreen ?
            <ErrorComponent message={this.props.errorMessage} t={this.props.t} /> :
            <Router>
                <div className="g-bs4 g-bootstrap notranslate">
                    <Suspense fallback={null}>
                        <HandlebarUtil />
                    </Suspense>
                    <div className="g-store">

                        <Suspense fallback={null}>
                                <Routes>
                                    <Route path="task/next/:hashId" element={ <NextTask initCompleteCallback={this.initComplete}/>} />
                                    <Route path="task/:hashId" element={<ActualTask initCompleteCallback={this.initComplete}/>} />
                                    <Route path="/" element={<TaskInitializer initCompleteCallback={this.initComplete} />} />

                                    <Route path="zadost" element={<Task/>} />
                                </Routes>
                        </Suspense>

                    </div>

                    <Modal isOpen={this.props.errorMessage != null && !this.props.blockScreen}
                        backdrop={'static'}
                        wrapClassName="g-bs4 g-bootstrap g-store fontsize14"
                        modalClassName="g-modal">
                        <ModalHeader>
                            {this.props.t('common:popup.error.title')}
                        </ModalHeader>
                        <ModalBody>
                            {
                                (this.props.errorMessage != null && this.props.errorMessage.split(" ").length == 1) ?
                                    this.props.t('common:popup.error.' + this.props.errorMessage) :
                                    this.props.errorMessage
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color={'primary'}
                                onClick={this.closeErrorButton}>{this.props.t('common:popup.error.button.close')}</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </Router>;

        return (
            <GdsLanguageProvider>
                <AppContextHandler actor={actor}>
                    <DeviceTypeHandler>
                        {helmet}
                        {chatWrapper}
                        {content}
                        <GaTagManager />
                    </DeviceTypeHandler>
                </AppContextHandler>
            </GdsLanguageProvider>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps) (withTranslation('translation') (App));
