//internationalization
import i18n, {InitOptions} from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import en from '../locales/en/default.json';
import cs from '../locales/cs-CZ/default.json';
import SessionWrapper from "../sessions/sessionWrapper";
import {Language} from "@csas-smart/gti-ui-comps";
import {setDefaultOptions} from "date-fns";
import {Locale} from 'date-fns'
import dateFnsEn from 'date-fns/locale/en-US'
import dateFnsCs from 'date-fns/locale/cs'

const EN = "EN";
const CS = "CS";
const SK = "SK";

const options: InitOptions = {
    fallbackLng: 'en',
    ns: ['translation'],
    defaultNS: 'translation',
    debug: true,
    keySeparator: '::',
    interpolation: {    
        escapeValue: false
    },
    react: {
        useSuspense: false,
        wait: true
    },
    resources: {
        en: {
            incode: {
                title:'In-code Title'
            },
            common: en
        },
        cs: {
            incode: {
                title:'In-code Titulek'
            },
            common: cs
        }
    }
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(options);

/**
 * "cs" | "en"
 */
export const getLocale = (): Lowercase<Language> => {
        return options.lng as Lowercase<Language>;
};

export const resolveLocalefromParams = (params) => {
    const {lang} = params;
    let resultLang = lang ? lang.toUpperCase() : (getLocale() ? getLocale().toUpperCase() : "CS");
    return mapLang(resultLang);
}

const mapLang = (lang: string): Language => {
    if(!lang) {
        return null;
    }

    switch(lang.toUpperCase()) {
        case CS: return Language.CS;
        case EN: return Language.EN;
        case SK: return Language.CS;
        default : return Language.EN;
    }
}

export const setLang = (lang) => {
    const sessionWrapper = new SessionWrapper();
    let resultLang = mapLang(lang);
    if (!resultLang) {
        resultLang = sessionWrapper.getLanguage() || Language.CS // DEFAULT
    }

    sessionWrapper.setLanguage(resultLang);
    options.lng = resultLang.toLowerCase() as Lowercase<Language>;
};

export const refreshTranslator = () => {
    i18n.init(options);
};

export const addTranslation = (translations) => {
    console.log(translations);

    //key = cs
    Object.keys(translations).map(key => {
        if(!options.resources[key]) {
            options.resources[key] = Object.assign({});
        }

        const trans = {...options.resources[key], ...translations[key]};
        options.resources[key] = trans;
    });

    i18n.init(options);
};

export const processTranslations = (translations, resource, namespace, keyPrefix) => {
    if(translations) {
        translations.map(t => {
            let { locale, translation } = t;

            locale = locale.toLowerCase();


            if(!resource[locale]) {
                resource[locale] = Object.assign({});
            }

            if(!resource[locale][namespace]) {
                resource[locale][namespace] = Object.assign({});
            }

            Object.keys(translation).map(key => {
                const resourceKey = keyPrefix ? keyPrefix + '.' + key : key;
                const translatedValue = translation[key] ? escapeBrackets(translation[key]) : null;

                resource[locale][namespace][resourceKey] = translatedValue;
            });
        })
    }
};

export const escapeBrackets = (strValue) => {
    return strValue.replace(/{{/g, "{'{'}{'{'}").replace(/}}/g,"{'}'}{'}'}");
};

export const unescapeBrackets = (strValue) => {
    if(!strValue) {
        return strValue;
    }
    return strValue.replace(/{'{'}{'{'}/gm, '{{').replace(/{'}'}{'}'}/gm, '}}');
};

export const translate = (t, key, defaultValue) => {
    if(!t) {
            return defaultValue;
    }

    const retValue = t(key);

    if(retValue && retValue !== key) {
        return retValue;
    }

    return defaultValue;
};

export const setDateFnsLocale = () => {
    const availableLocales: Record<Language, Locale> = {
        [Language.CS]: dateFnsCs,
        [Language.EN]: dateFnsEn
    }

    setDefaultOptions({ locale: availableLocales[getLocale().toUpperCase()] ?? availableLocales[Language.EN] })
}

export default i18n;
