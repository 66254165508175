import React, { lazy } from "react";
import SessionWrapper from "../../sessions/sessionWrapper";
import {loadCodebookWithLang} from "../../actions/taskActions";
import RestCaller from "../../core/restCaller";
import Resource from "../../core/serverresource";

/**
 * Lazily loading "@csas-smart/gti-payments-ui": 
 */ 
const ForeignPaymentField = lazy(() =>
    import("@csas-smart/gti-payments-ui").then(module => ({ default: module.ForeignPaymentField }))
);

const ForeignPaymentFieldContainer = (props) => {
    const sessionWrapper = new SessionWrapper();
    const loadCodebookWithLangHandler = (codebook, attributes, lang) => {
        return loadCodebookWithLang(codebook, attributes, lang ? lang : sessionWrapper.getLanguage());
    }
    const validatePayment = (request) => {
        return RestCaller.httpPost(Resource.dionValidatePayment(), request)
    }
    const validateMandatoryFields = (request) => {
        return RestCaller.httpPost(Resource.dionValidateMandatoryFields(), request)
    }

    return <ForeignPaymentField {...props}
                                validatePayment={validatePayment}
                                validateMandatoryFields={validateMandatoryFields}
                                language={sessionWrapper.getLanguage()}
                                loadCodebookFn={loadCodebookWithLangHandler} />
}

export default ForeignPaymentFieldContainer;