import SessionWrapper from "../../sessions/sessionWrapper";

/**
 * enhancer sets the 'Authorization' header to resolved accessToken stored in session storage.
 * Result header is in formate Authorization: Bearer <access_token>
 */
class AuthorizationBearer {
    HEADER_NAME = "Authorization";
    BEARER_PREFIX = "Bearer";

    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    enhanceHeaders(headers) {
        if (!headers) {
            headers = {};
        }

        const token = this.sessionWrapper.getAccessToken();


        return {...headers, [this.HEADER_NAME]: `${this.BEARER_PREFIX} ${token}`};
    }
}

export default AuthorizationBearer;
