import React, { lazy } from 'react';
import SessionWrapper from '../../sessions/sessionWrapper';

// Lazily loading "@csas-smart/gti-mnt-ui": 
const Refix = lazy(() =>
    import("@csas-smart/gti-mnt-ui").then(module => ({ default: module.Refix }))
);


const RefixFieldContainer = (props) => {
    const {field, fieldValueChanged, attributes} = props;
    const language = new SessionWrapper().getLanguage();

    const handleChange = (attrName, attrValue) => {
        const selectedAttribute = field.selector.attributes[attrName];

        if (!selectedAttribute || !selectedAttribute.name) {
            return;
        }

        fieldValueChanged({name: selectedAttribute.name, value: attrValue});
    }

    return (
        <Refix fixations={attributes.offersRefixRates}
               onChange={handleChange}
               selectedFinalFix={attributes.selectedFinalFix}
               t={props.t}
               language={language}
        />
    )
}

export default RefixFieldContainer;
