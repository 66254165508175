import { SigmaDocumentsField } from '@csas-smart/gti-sigma-ui';
import React from "react";
import { useDispatch } from 'react-redux';
import { getDocumentByField, getFolderByField } from "../../actions/sigmaActions";
import { AppDispatch } from '../../core/store';
import { resolveFilenameFromResponseHeaders } from "../../common/reusableFunctions";

const SigmaDocumentsFieldContainer = (props) => {

    let filename = undefined;
    const dispatch = useDispatch<AppDispatch>();

    const onFolderLoad = () => {
        return dispatch(getFolderByField(props.field.name));
    };

    const onDocumentShow = (documentId) => {
        return dispatch(getDocumentByField(props.field.name, documentId))
            .then(processDocument)
            .catch(documentRejected)
    };

    const processDocument = async (response) => {
        filename = resolveFilenameFromResponseHeaders(response);
        const blob = await response.blob();
        return {blob, filename};
    };

    const documentRejected = (error) => {
        console.log(error);
    };

    return (
        <SigmaDocumentsField {...props} onFolderLoad={onFolderLoad} onDocumentShow={onDocumentShow}/>
    )
};

export default SigmaDocumentsFieldContainer;
