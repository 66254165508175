import linkCreatorDelegate from "../link-creators/link-creator-delegate";
import {decodeBase64, encodeBase64} from "../utils/objectUtils";
import SessionWrapper, {GSTATE_JSON_TYPE} from "../../sessions/sessionWrapper";
import logger from "../loggly/gtiLogger";
import Resource from "../serverresource";
import { isNotEmptyObject } from "validations";

export const ALLOWED_TABLET_SALES_CHANNELS = ["FZ", "CSHUB", "PU"];
class AdfsService {
    tokenReceivedHandler;
    sessionWrapper;
    SUPPORTED_AUTH_TYPE='adfs-starter';
    SUPPORTED_API_KEY = import.meta.env.VITE_APP_SES_API_KEY;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    supportType = (authType) => {
        if(authType !== this.SUPPORTED_AUTH_TYPE && !this.sessionWrapper.supportsAdfsTokenStarter()) {
            return false;
        }

        this.sessionWrapper.setTokenStarterSupported();
        return true;
    }

    withTokenReceivedHandler = (tokenReceivedHandler) => {
        this.tokenReceivedHandler = tokenReceivedHandler;
        return this;
    }

    init = (params) => {
        if(this.tokenReceivedHandler) {
            const {access_token, returnurl, saleschannel} = params;
            let redirectUri = null;

            if(returnurl) {
                if (returnurl.indexOf("http://") > -1 || returnurl.indexOf("https://") > -1 || returnurl.indexOf("cshub://") > -1) {
                    redirectUri = returnurl;
                } else if (typeof returnurl === 'string' && returnurl !== 'null') {
                    redirectUri = decodeBase64(returnurl);
                }
            }

            const gState = {};

            if(redirectUri) {
                gState["redirectUri"] = redirectUri;
            }

            if(access_token) {
                this.sessionWrapper.setAccessToken(access_token);
            }
            if(saleschannel) {
                this.sessionWrapper.setSalesChannel(saleschannel);
                this.sessionWrapper.setTablet(ALLOWED_TABLET_SALES_CHANNELS.indexOf(saleschannel) > -1);
            }

            if(isNotEmptyObject(gState)) {
                this.sessionWrapper.setGState(encodeBase64(gState));
            }

            this.sessionWrapper.setBtiFlag();
            Resource.setInterceptor();

            this.tokenReceivedHandler(params);
        }
    }

    revoke = (hash) => {
        console.log("Revoking case " + hash);
        this.sessionWrapper.clearSessionStorage();
        return Promise.resolve();
    }

    redirectToGeorge = () => {
        //but actually not
        this.handover();
    }

    handover = (ctx?) => {
        const gstate = this.sessionWrapper.getGState(GSTATE_JSON_TYPE);
        const redirectUri = gstate?.redirectUri;
        this.sessionWrapper.clearSessionStorage();
        if(redirectUri){
            const linkCreator = new linkCreatorDelegate().getLinkCreator(ctx);
            const link = linkCreator.prepareLink(redirectUri, ctx);
            window.location.href = link;
        } else {
            //TODO mozna ukonceni OBO?? pokud jde o starter...
            window.close();
        }
    }

    logout = () => {
        this.sessionWrapper.clearSessionStorage();
        logger.info("Logout was not implemented yet for adfsService")
    }

    getLogoutTimeout = () => {
        return import.meta.env.VITE_APP_ADFS_AUTOMATIC_LOGOUT_SECS;
    }

    getSupportedAuthType = () => {
        return this.SUPPORTED_AUTH_TYPE;
    }

    getSupportedApiKey = () => {
        return this.SUPPORTED_API_KEY;
    }
}

export default new AdfsService();
