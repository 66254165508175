import {resolveHashIdFromState} from "../core/utils/taskUtils";
import RestCaller from "../core/restCaller";
import Resource from "../core/serverresource";
import {fireAttrChange} from "./taskActions";

export function getFolder(folderId, attrName) {
    return (dispatch) => {
        console.log("Loading folder.");

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(Resource.getSigmaFolder(folderId, hashId))
            .then((result) => {
                    console.log('Response: ', result);
                    dispatch(fireAttrChange({name: attrName, value: result, append: false}));
                    return Promise.resolve(result);
                },
                error => {
                    console.log('Loading folder failed', error);
                    return Promise.reject(error);
                });
    }
}

export function getClientFolder(folderId) {
    return () => {
        console.log("Loading folder.");

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(Resource.getSigmaClientFolder(folderId, hashId))
            .then((result) => {
                    console.log('Response: ', result);
                    return Promise.resolve(result);
                },
                error => {
                    console.log('Loading folder failed', error);
                    return Promise.reject(error);
                });
    }
}

export function getFolderByField(fieldName) {
    return () => {
        console.log("Loading folder.");

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(Resource.getSigmaFolderByFieldName(fieldName, hashId))
            .then((result) => {
                    console.log('Response: ', result);
                    return Promise.resolve(result);
                },
                error => {
                    console.log('Loading folder failed', error);
                    return Promise.reject(error);
                });
    }
}

export function getSignMethodsByField(fieldName) {
    return () => {
        console.log("Loading sign methods.");

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(Resource.getSigmaSignMethodsByFieldName(fieldName, hashId))
            .then((result) => {
                    console.log('Response: ', result);
                    return Promise.resolve(result);
                },
                error => {
                    console.log('Loading folder failed', error);
                    return Promise.reject(error);
                });
    }
}

export function getSignatureMethods(folderId) {
    return () => {
        console.log("Loading signature methods.");

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGet(Resource.getSigmaSignatureMethods(hashId, folderId))
            .then((result) => {
                    console.log('Response: ', result);
                    return Promise.resolve(result);
                },
                error => {
                    console.log('Loading signature methods failed', error);
                    return Promise.reject(error);
                });
    }
}

export function signDocuments(folderId) {
    return () => {
        console.log("Signing documents.");

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPutWithoutBody(Resource.signSigmaDocuments(hashId, folderId))
            .then((result) => {
                    console.log('Documents signed. Response: ', result);
                },
                error => {
                    console.log('Signing documents failed', error);
                    return Promise.reject(error);
                });
    }
}

export function changeSigningMethod(folderId, method) {
    return () => {
        console.log("Changing signing method.");

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPutWithoutBody(Resource.changeSigmaSigningMethod(hashId, folderId, method))
            .then((result) => {
                    console.log('Signing method changed. Response: ', result);
                },
                error => {
                    console.log('Changing signing method failed', error);
                    return Promise.reject(error);
                });
    }
}

export function getDocument(folderId, documentHashedId) {
    return () => {
        console.log("Loading document.");

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGetWithBinary(Resource.getSigmaDocument(hashId, folderId, documentHashedId))
            .then((response) => {
                    console.log('Document loaded.');
                    return Promise.resolve(response);
                },
                error => {
                    console.log('Loading document failed', error);
                    return Promise.reject(error);
                });
    }
}

export function getDocumentByField(folderName, documentHashedId) {
    return () => {
        console.log("Loading document.");

        const hashId = resolveHashIdFromState();
        return RestCaller.httpGetWithBinary(Resource.getSigmaDocumentByField(hashId, folderName, documentHashedId))
            .then((response) => {
                    console.log('Document loaded.');
                    return Promise.resolve(response);
                },
                error => {
                    console.log('Loading document failed', error);
                    return Promise.reject(error);
                });
    }
}
