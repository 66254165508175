import SessionWrapper from "../../sessions/sessionWrapper";
import {isUrlMep} from "../utils/urlUtils";

/**
 * enhancer sets the 'smart-origin' header to smart-origin resolved
 */
class Origin {

    HEADER_NAME = "smart-origin";

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    enhanceHeaders(headers, requestedUrl) {
        if (!headers) {
            headers = {};
        }

        if (isUrlMep(requestedUrl)) {
            return headers;
        }

        const salesChannel = this.sessionWrapper.getSalesChannel();
        if (salesChannel) {
            const origin = {
                "salesChannel": salesChannel,
                "applicationSystem": null
            };
            return {
                ...headers, [this.HEADER_NAME]: JSON.stringify(origin)
            };
        }else {
            return headers;
        }
    }
}

export default Origin;
