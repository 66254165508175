import React, { lazy } from 'react';
import {resolveHashIdFromState} from '../../core/utils/taskUtils';
import {GdsFieldContainer} from '@csas-smart/gti-ui-comps';
import {decodeBase64} from '../../core/utils/objectUtils';


const ACTIVITY_DONE_VALUE = 'FINISH';
const PLEDGE_SERVICE_CODE = 'gct1357';
const EXTENSION_SERVICE_CODE = 'gct1421';

// Lazily loading "@csas-smart/gti-cedr-ui": 
const ConditionsSummary = lazy(() =>
    import("@csas-smart/gti-cedr-ui").then(module => ({ default: module.ConditionsSummary }))
);

const ConditionsSummaryContainer = (props) => {
    const {field, t, required} = props;
    const checkConditions = props.attributes.checkConditions ?? [];
    const servicesConditions = props.attributes.servicesConditions;
    const georgeContinueOrBack = props.attributes.georgeContinueOrBack;
    const taskHashId = resolveHashIdFromState();
    const fees = props.attributes.serviceFees ?? [];

    if (georgeContinueOrBack?.toUpperCase() === ACTIVITY_DONE_VALUE) {
        let serviceOrders = [...servicesConditions];
        serviceOrders = serviceOrders?.map(order => {
            // eslint-disable-next-line no-unused-vars
            const {conditionName, ...service} = order;

            if (service.serviceName === PLEDGE_SERVICE_CODE) {
                const {homeBranch, clientBusinessTeam, ...pledge} = service;
                return {
                    ...pledge,
                    orgUnitId: homeBranch.orgUnitId,
                    userId: clientBusinessTeam.userId,
                }
            }

            if (service.serviceName === EXTENSION_SERVICE_CODE) {
                const {reasonText, ...extension} = service;
                return {
                    ...extension,
                    reasonText: decodeBase64(reasonText),
                }
            }

            return service;
        })

        fetch(`${import.meta.env.VITE_APP_GCEDR_RESOURCE_URL}/services/order?hashId=${taskHashId}`, {
            method: 'POST',
            body: JSON.stringify({serviceOrders: serviceOrders}),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).catch(err => {
            console.error("Failed to call GCEDR", err);
        })
    }

    return <GdsFieldContainer field={field} t={t} required={required} componentId='ConditionsSummaryField'>
            <ConditionsSummary checkConditions={checkConditions} servicesConditions={servicesConditions} serviceFees={fees} />
    </GdsFieldContainer>;
};

export default ConditionsSummaryContainer;
