import React, { useEffect } from "react";
import { FieldProps, GdsFieldContainer, ValidationError } from "@csas-smart/gti-ui-comps";
import { Card, FormCol, FormRow } from "@george-labs.com/design-system";
import DemoSlider from "./comp/DemoSlider";
import SelectLoanTerm from "./comp/SelectLoanTerm";
import MonthlyRepaymentCard from "./comp/MonthlyRepaymentCard";
import axios from "axios";
import validatedComponent from "../../../hoc/validatedComponent";
import { demoCalculatorValidationFnc } from "./demoCalculatorValidationFnc";

interface Props extends FieldProps {
  attributes: {
    loanAmount: number;
    loanTerm: number;
    monthlyPayment: number;
  },

}
const DemoCalculatorField = ({field, required, attributes, t, fieldValueChanged, validations, validate}: Props) => {

  useEffect(() => {
    const data = {
      "loanAmount": attributes.loanAmount,
      "loanTerm": attributes.loanTerm
    }
    const headers = {
      "web-api-key": "41797e40-12d8-49fe-8214-8a46aa21fb67"
    };
    //const url = "https://smart-int.be.tocp4s.csin.cz/ngruntime/api/calculator/demo/payment";
    const url = "https://www.csast.csas.cz/webapi/api/v1/smart/calculator/demo/payment";
    axios.post(url, data, {headers})
      .then((result: any) => {
        const name = field.selector.attributes["monthlyPayment"].name;
        return fieldValueChanged({name, value: result.data.monthlyPayment })})

  }, [attributes.loanTerm, attributes.loanAmount]);

  const handleLoanAmountChange = (value: number) => {
    const name = field.selector.attributes["loanAmount"].name;
    revalidate();
    return fieldValueChanged({name, "value": value});
  }

  const handleLoanTermChange = (value) => {
    const name = field.selector.attributes["loanTerm"].name;
    revalidate();
    return fieldValueChanged({name, "value": value});
  }

  const revalidate = () => {
    if (validations.validationError.error){
      validate();
    }
  }


  return <GdsFieldContainer field={{ ...field, labelWidth: 0}} required={required} t={t}>
    <Card>
      <FormRow>
        <FormCol lg={7}>
          <SelectLoanTerm  required={field.required} loanTerm={attributes.loanTerm || 0} onChange={handleLoanTermChange} />
        </FormCol>
        <FormCol lg={5}>
          <MonthlyRepaymentCard monthlyRepayment={attributes.monthlyPayment} />
        </FormCol>
      </FormRow>
      <DemoSlider loanAmount={attributes.loanAmount || 0} onChange={handleLoanAmountChange} />
    </Card>
    <ValidationError error={validations.validationError.error} errorText={validations.validationError.message}/>
  </GdsFieldContainer>
}

const component = validatedComponent(DemoCalculatorField, demoCalculatorValidationFnc);
export { component as DemoCalculatorField };