import React, {lazy, useEffect, useState} from "react";
import Resource from "../../core/serverresource";
import { GdsFieldContainer } from "@csas-smart/gti-ui-comps";

// Lazily loading "@csas-smart/gti-corporate-ui": 
const SpecifiedOwnersField = lazy(() =>
    import("@csas-smart/gti-corporate-ui").then(module => ({ default: module.SpecifiedOwnersField }))
);

const SpecifiedOwnersFieldContainer = (props) => {
    const [countries, setCountries] = useState([]);
    const {field, t, required} = props;

    useEffect(() => {
        loadCountries();
    }, []);

    const loadCountries = () => {
        fetch(Resource.getCodebook("CB_Country"), {
            method: 'POST',
            body: JSON.stringify(["CERTIS"]),
            headers: {
                "Content-Type": "application/json; charset=utf-8; Client-Accept-Language=en"
            }
        })
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then(
                (result) => {
                    setCountries(result.filter(country => country.valid));
                })
            .catch(function (error) {
                console.log('request failed', error.statusText);
            });
    }

    return (
        <GdsFieldContainer tooltipKey="tooltipText" t={t} field={field} required={required} componentId={'specifiedOwnersComponent'}>
            <SpecifiedOwnersField countries={countries} {...props} />
        </GdsFieldContainer>
    );
}

export default SpecifiedOwnersFieldContainer;