import SessionWrapper from "../../sessions/sessionWrapper";
import {isUrlMep} from "../utils/urlUtils";

/**
 * enhancer sets the 'Accept-Language' header by resolved lang in i18n through the 'getLocale' method
 */
class XSessionId {
    HEADER_NAME = "X-sid";
    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    enhanceHeaders(headers, requestedUrl) {
        if (!headers) {
            headers = {};
        }
        if (isUrlMep(requestedUrl)) {
            return headers;
        }

        return {...headers, [this.HEADER_NAME]: this.sessionWrapper.getSessionId()};
    }
}

export default XSessionId;
