import React from "react";
import Resource from "../core/serverresource";
import {AgreementsField} from "@csas-smart/gti-corp-landing-ui";
import SessionWrapper from "../sessions/sessionWrapper";


const AgreementsFieldContainer = (props) => {
    const sessionWrapper = new SessionWrapper();

    const getFullText = (sourceSystemRecordId) => {
        return fetch(Resource.getFullAgreementText(sourceSystemRecordId), {
                method: 'GET'
            }
        )
            .then(Resource.checkStatus);
    }

    const getShortText = (agreementType) => {
        return fetch(Resource.getShortText(agreementType), {
                method: 'GET'
            }
        )
            .then(Resource.checkStatus);
    }

    return <AgreementsField {...props} getFullText={getFullText} getShortText={getShortText} language={sessionWrapper.getLanguage()}/>
}

export default AgreementsFieldContainer