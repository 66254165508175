import React from "react";
import { ProductField } from "@csas-smart/gti-ui-comps";
import { loadProducts } from "../actions/taskActions";
import { resolveHashIdFromState } from "../core/utils/taskUtils";


const ProductFieldContainer = (props) => {

    const hashId = resolveHashIdFromState();

    /**
     * Function that triggers service call based on the field name to query for users products. 
     * @param {*} request Request object containing the field name.
     * @returns Promise with the list of users products.
     */
    const onLoadProducts = (request) => {
        return loadProducts(request, hashId);
    }

    return <ProductField {...props} loadProducts={onLoadProducts}/>

}

export default ProductFieldContainer;