import React, { useEffect, useState } from "react";
import GeorgeSpinner from "../../core/georgeSpinner";
import AddressComp from "../../comp/address-comp";
import EmployerSearchComp from "../../comp/employer-search-comp";
import propertiesloader from "../../core/propertiesloader";
import { translate } from "../../core/i18n";


/**
 * ResCompanyField
 * Field slouží k vyhledávání společností v ARESu pomocí názvu firmy / iča. Vyhledávat lze pouze tuzemské firmy.
 * Pokud hledaná firma není v ARESu, uživatel může data vyplnit ručně.  
 */

const ResCompanyField = ({ field, t, required, editable, validations, attributes, fieldValueChanged }) => {

    const value = attributes.company ? attributes.company : null;
    const [isMounted, setMounted] = useState(false);
    const [translations] = useState(getTranslations(t));
    const [addressValidationFnc, setAddressValidationFnc] = useState<Function>(function () {});
    const customLabels = getCustomLabels(field.editor, t);

    useEffect(() => {
        // If the company attribute is not defined, propagate empty object. 
        !attributes.company ? onChange({}) : null;
        setMounted(true);
    }, []);


    /**
     * Update attribute logic:
     */
    const onChange = (newValue) => {
        const companyAttrName = field.selector.attributes["company"].name;
        fieldValueChanged({ name: companyAttrName, value: newValue });
        if (field.selector.attributes["registrationNumber"]){
            const regNoAttrName = field.selector.attributes["registrationNumber"].name;
            fieldValueChanged({ name: regNoAttrName, value: newValue.registr });
        }
    }

    // On blur from search comp, perform address validation
    const onSearchBlur = (newValue) => {
        onChange(newValue);
        validateAddressContent();
    };


    /**
     * Validation logic: 
     */
    const propagateError = (error) => {
        const newError = Object.assign(validations.validationError, error);
        validations.setError(newError);
    };

    const propagateAddressError = (error) => {
        propagateError({ addressCompError: error })
    };

    const addressComponentMounted = (componentName, validationFnc) => {
        setAddressValidationFnc(() => validationFnc);
        validations.componentMounted(componentName, validationFnc);
    };

    // Revalidate address component, when there is an error in address comp
    const validateAddressContent = () => {
        if (validations.validationError && validations.validationError.addressCompError) {
            const isAddressError = Object.values(validations.validationError.addressCompError)
                .reduce((a, b) => a || b);
            if (isAddressError && addressValidationFnc instanceof Function) {
                console.log("Evaluating addrress call due to: " + isAddressError);
                addressValidationFnc.call(null);
            } else {
                console.log("Not evaluating address due to: " + isAddressError);
            }
        }
    }

    /**
     * Address component setup logic: 
     */
    const addressValidations = {
        ...validations,
        propagateError: propagateAddressError,
        componentMounted: addressComponentMounted,
        errorText: translations
    };

    const searchCompanyValidations = {
        ...validations,
        propagateError: propagateError
    };

    /**
     * Render component view: 
     * When value is not yet initialized, render George Spinner. Otherwise render component view.
     */
    return isMounted ? (
        <>
            <EmployerSearchComp
                field={field}
                t={t}
                required={required}
                editable={editable}
                value={value}
                handleChange={onChange}
                handleBlur={onSearchBlur}
                validations={searchCompanyValidations}
                customLabels={customLabels}
                isRegNumberRequired={true}
                defaultValue={{isManual: true, country: "CZ"}}
            />

            <AddressComp field={{ ...field, label: customLabels.address }}
                value={value}
                editable={editable}
                required={true}
                onChange={onChange}
                customLabels={translations}
                validations={addressValidations}
                allowViewTypeChange={false}
                initialViewType={'editable'}
                t={t} />
        </>
    ) : <GeorgeSpinner />;
}

/**
 * Common translations for labels, errors etc. defined in default.json.
 */
const getTranslations = (t): {
    streetLabel: string,
    buildingNumberLabel: string,
    registryBuildingNumberLabel: string,
    cityLabel: string,
    zipCodeLabel: string,
    countryLabel: string,
    incomeTransfer: string,
    buildingNumberError: string,
    registryBuildingNumberEmptyError: string,
    registryBuildingNumberWrongError: string,
    zipCodeError: string,
    cityError: string
    } => {
        return {
            streetLabel: t('common:comp.address-comp.streetLabel'),
            buildingNumberLabel:  t('common:comp.address-comp.buildingNumberLabel'),
            registryBuildingNumberLabel: t('common:comp.address-comp.registryBuildingNumberLabel'),
            cityLabel: t('common:comp.address-comp.city'),
            zipCodeLabel: t('common:comp.address-comp.zipCode'),
            countryLabel: t('common:comp.address-comp.country'),
            incomeTransfer: t('common:field.employer-search-field.incomeTransfer'),
            buildingNumberError: t('common:field.employer-search-field.buildingNumberError'),
            registryBuildingNumberEmptyError: t('common:field.employer-search-field.registryBuildingNumberEmptyError'),
            registryBuildingNumberWrongError: t('common:field.employer-search-field.registryBuildingNumberWrongError'),
            zipCodeError: t('common:field.employer-search-field.zipCodeError'),
            cityError: t('common:field.employer-search-field.cityError')
        }
};

/**
 * Method for evaluating translations defined in JSON.
 */
const getCustomLabels = (editor, t): {
    name: string,
    registrationNumber: string,
    address: string
} => {
    return {
        name: translate(t, "field.properties.nameLabel", propertiesloader.getProperty(editor, "nameLabel")),
        registrationNumber:  translate(t, "field.properties.registrationNumberLabel", propertiesloader.getProperty(editor, "registrationNumberLabel")),
        address: translate(t, "field.properties.addressLabel", propertiesloader.getProperty(editor, "addressLabel"))
    };
}

export default ResCompanyField;