import { Button, FormField, InputGroup, TextInput } from "@george-labs.com/design-system";
import React from "react";

interface Props {
  required: boolean,
  loanTerm: number,

  onChange: (_: number) => void

}

const SelectLoanTerm = ({ required, loanTerm, onChange }: Props) => {

  return (<FormField
    label="Loan Term:"
    required={required}
    input={
      <InputGroup
        prepend={
          <Button
            icon={Button.ICON.MINUS}
            fullWidth={Button.FULL_WIDTH.NEVER}
            hiddenLabel={Button.HIDDEN_LABEL.ALWAYS}
            onClick={() => {onChange(loanTerm - 1)}}
          >
            Subtract
          </Button>
        }
        append={
          <Button
            icon={Button.ICON.PLUS}
            fullWidth={Button.FULL_WIDTH.NEVER}
            hiddenLabel={Button.HIDDEN_LABEL.ALWAYS}
            onClick={() => {onChange(loanTerm + 1)}}
          >
            Add
          </Button>
        }
      >
        <TextInput value={loanTerm} className="text-center col-sm-2" />
      </InputGroup>
    }
  />);


};

export default SelectLoanTerm;