import React, {useState, useEffect} from "react";
import {Row, Col, Container} from "reactstrap";
import {useSelector} from 'react-redux';
import CancelPopUp from "./popups/cancelPopup";
import IconWrapper from "./lazy-wrappers/iconWrapper";
import {useTranslation} from "react-i18next";
import SessionWrapper from "../sessions/sessionWrapper";

export const PageHeader = ({isError = false}) => {
    const sessionWrapper = new SessionWrapper();

    const georgeTaskStatus = useSelector(state => state.task?.georgeTask?.taskStatus || null);
    const productName = sessionWrapper.getProductName() || "";
    const {t} = useTranslation(['common']);

    const showCancelButton = georgeTaskStatus !== 'COMPLETED' && !isError;
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [cancelPopUp, setCancelPopUp] = useState(null);

    const dismissPopUp = () => {
        setShowCancelPopup(false);
    }

    const showPopUp = () => {
        setShowCancelPopup(true);
    }

    useEffect(() => {
        setCancelPopUp(<CancelPopUp showCancelPopUp={showCancelPopup} dismissPopUpFunc={dismissPopUp}/>)
    }, [showCancelPopup])

    let largeCloseButtonContent = null;
    let smallCloseButtonContent = null;
    if(showCancelButton) {
        largeCloseButtonContent = (
            <div onClick={showPopUp}>
                {t('page.header.close')}&nbsp;<IconWrapper name="error" width={16} height={16}/>
            </div>
        );
        smallCloseButtonContent = (
            <div onClick={showPopUp}>
                <IconWrapper name="error" width={16} height={16}/>
            </div>
        );
    }

    return (
        <React.Fragment>
            {cancelPopUp}
            <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={isError ? "app-error-header" : 'app-page-header'}>
                    <Container className="appContainer">
                        <Row>
                            <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                            </Col>

                            <Col xl={8} lg={8} md={8} sm={8} xs={8} className={'app-page-header-title'}>
                                {productName ? <h1>productName</h1> : ""}
                            </Col>

                                <Col xs={2} className={!isError ? 'd-lg-none btn btn-outline-light float-right app-page-header-close-button': ""}>
                                    {smallCloseButtonContent}
                                </Col>
                            <Col xs={2} className={!isError ? 'd-none d-lg-block btn btn-outline-light float-right app-page-header-close-button': ""}>
                                {largeCloseButtonContent}
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </React.Fragment>
    )
}
