import React, { lazy } from "react";
import { useDispatch } from 'react-redux';
import {checklistGetData, checklistSetOBO} from "../../actions/g4bmigrActions";

/**
 * Lazily loading "@csas-smart/gti-g4bmigr-ui": 
 */ 
const RepresentativeListField = lazy(() =>
    import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.RepresentativeListField }))
);

const RepresentativeListFieldContainer = (props) => {

    const dispatch = useDispatch();

    const onUpdate = () => {
        console.log('RepresentativeListFieldContainer - onUpdate called');

        return dispatch(checklistGetData());
    };

    const getCdnUrl = () => {
        return import.meta.env.VITE_APP_CDM_CDN_URL;
    };

    const onPrepareCrm = (cluid) => {
        console.log('RepresentativeListFieldContainer - onPrepareCrm called');

        return dispatch(checklistSetOBO({ cluid }));
    };

    const getTraceUrl = () => {
        return import.meta.env.VITE_APP_TRACE_URL;
    }

    return (
        <RepresentativeListField {...props} onUpdate={onUpdate} cdnUrl={getCdnUrl()} prepareCrm={onPrepareCrm} traceUrl={getTraceUrl()} />
    )
};

export default RepresentativeListFieldContainer;
