import React, {lazy} from "react";
import {FieldProps} from "@csas-smart/gti-ui-comps";
import {CABU_MODULE} from "../../core/relevantStrategies/modules.ts";

const CardSelectFieldLazy = lazy(() =>
    import("@csas-smart/gti-corporate-ui").then(module => ({ default: module.CardSelectFieldContainer }))
);

export const CardSelectField = (props: FieldProps) => {
    return <CardSelectFieldLazy cabuAppUrl={CABU_MODULE.url} {...props} />
}