import React, { Component } from "react";
import { connect } from "react-redux";
import { componentMounted, componentUnmounted, fireAttrChange } from "../actions/taskActions";
import { EvalFactory } from "@csas-smart/gti-ui-comps";
import GTranslatedComponent from "./gTranslatedComponent";
import { hasObjectProerty } from "./utils/objectUtils";
import FieldContextHandler from "./context/fieldContextHandler";
import componentsList from "./componentsList";


const mapDispatchToProps = dispatch => {
    return {
        fireAttrChange: change => dispatch(fireAttrChange(change)),
        componentMounted: (componentName, validationCallback) => dispatch(componentMounted(componentName, validationCallback)),
        componentUnmounted: (componentName) => dispatch(componentUnmounted(componentName))
    };
};

const mapStateToProps = (state, ownProps) => {
    // Prevent field rendering on new activity load
    if (state.task.activity.design === null)
        return;

    var selectorObjects = Object.keys(ownProps.field.selector.attributes).map(a => {
        return { "selector": a, "targetAttribute": ownProps.field.selector.attributes[a].name }
    });
    var invertedSelector = {};
    selectorObjects.forEach(s => invertedSelector[s.targetAttribute] = s.selector);
    var attributes = {};
    state.task.activity.attributes
        //https://stackoverflow.com/questions/52085538/how-to-avoid-for-in-eslint-issues
        .filter(a => hasObjectProerty(invertedSelector, a.name))
        .forEach(a => {
            attributes[invertedSelector[a.name]] = a.value;
        });
    const activityAttributes = state.task.activity.attributes;    
    const context = EvalFactory.createContext(state.task.activity.attributes, undefined, state.user.actor);
    const visible = EvalFactory.eval(ownProps.field.visible, context, true);
    const editable = EvalFactory.eval(ownProps.field.editable, context, true);
    const required = EvalFactory.eval(ownProps.field.required, context, false);

    return { attributes, visible, editable, required, context, activity: state.task.activity, components: state.task.components, activityAttributes};
};

class InnerField extends Component {

    constructor(props) {
        super(props);
        this.fieldValueChanged = this.fieldValueChanged.bind(this);
        this.setError = this.setError.bind(this);
        this.raiseErrorFlag = this.raiseErrorFlag.bind(this);
        this.dropErrorFlag = this.dropErrorFlag.bind(this);
        this.componentMountedWrapper = this.componentMountedWrapper.bind(this);
        this.componentUnmountedWrapper = this.componentUnmountedWrapper.bind(this);
        this.state = { validationError: {} };
    }

    setError(error) {
        this.setState((state) => {
            return {validationError: Object.assign({}, state.validationError, error)}
        });
    }

    dropErrorFlag(currentErrorValue, newError) {
        if (currentErrorValue === true) {
            this.setError(newError);
        }
    }

    raiseErrorFlag(currentErrorValue, newError) {
        if (currentErrorValue === false && this.props.location !== "FOOTER") {
            this.setError(newError);
        }
    }

    // Wrapping component mounted call, to recognize components in footer - they are not to be validated.
    componentMountedWrapper(componentName, validation) {
        if (this.props.location !== "FOOTER") {
            this.props.componentMounted(componentName, validation);
        }
    }

    // Wrapping component unmounted call, to recognize components in footer - they are not to be validated.
    componentUnmountedWrapper(componentName) {
        if (this.props.location !== "FOOTER") {
            this.props.componentUnmounted(componentName);
        }
    }

    fieldValueChanged(attrChange) {
        var payload = { name: attrChange.name, value: attrChange.value };
        return this.props.fireAttrChange(payload);
    }

    render() {
        const { field, attributes, visible, editable, required, completeActivityAction, validateActivityAction, location, context } = this.props;
        let validations = {
            componentMounted: this.componentMountedWrapper,
            componentUnmounted: this.componentUnmountedWrapper,
            dropErrorFlag: this.dropErrorFlag,
            raiseErrorFlag: this.raiseErrorFlag,
            setError: this.setError,
            validationError: this.state.validationError
        };

        
        if (!visible) {
            return null;
        }

        if (!componentsList[field.editor.name]) {
            console.error(field.editor.name + " was not found in valid components list");
            return null;
        }

        const fName = componentsList[field.editor.name].clazz;

        var f = 
        <FieldContextHandler field={field} activityAttributes={this.props.activityAttributes}>
            <GTranslatedComponent translationType={field.name}>
                {
                    React.createElement(
                        fName,
                        {
                            field: field,
                            attributes: attributes,
                            editable: editable,
                            fieldValueChanged: this.fieldValueChanged,
                            completeActivityAction: completeActivityAction,
                            validateActivityAction: validateActivityAction,
                            location: location,
                            required: required,
                            context: context,
                            validations: validations
                        }
                    )
                }
            </GTranslatedComponent>
        </FieldContextHandler>;

        return f;
    }

}

/**
 * The class represents the single field on the screen
 */
export default connect(mapStateToProps, mapDispatchToProps)(InnerField);
