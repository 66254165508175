import React, { useContext, Suspense, lazy } from "react";
import {useDispatch} from "react-redux";
import {fireCaseCancelled} from "../actions/taskActions";
import authService from "../core/auths/authService";
import Resource from '../core/serverresource';
import RestCaller from "../core/restCaller";
import SessionWrapper from "../sessions/sessionWrapper";
import {DeviceContext, DeviceContextProps, Language} from "@csas-smart/gti-ui-comps";
import {v4 as uuidv4} from 'uuid';
import logger from '../core/loggly/gtiLogger'
import {AppDispatch} from "../core/store";


const KycField = lazy(() =>
  import("@csas-smart/gti-kyc-ui").then(module => ({ default: module.KycField }))
);


function getKycInfo() {
    return RestCaller.httpGet(Resource.getKycInfo());
}

function getQuestions() {
    return RestCaller.httpGet(Resource.getQuestionnarie());
}

function setQuestions(request) {
    return RestCaller.httpPostWithoutResponse(Resource.getQuestionnarie(), request);
}

function saveAttachments(request){
    
    const formData = new FormData();
    formData.append("documentType", request.files[0].metadata);

    const promises = request.files.map(file => {
        return fetch(file.fileUrl).then(r => r.blob())
                .catch(ex => {
                    const errorRequest = {
                        method: 'GET',
                        url: file.fileUrl,
                        correlationId: uuidv4(),
                        // There is a file actually, not sure we want to log that: formData: formData,
                        exception: JSON.stringify(ex, Object.getOwnPropertyNames(ex))
                    };
                    logger.api(errorRequest);
                    return Promise.reject();
                })
    })

    return Promise.all(promises).then((files) => {
        files.forEach((file, index) => formData.append("binaryData", file, request.files[index].fileName)); // The filename evaluation is suboptimal..
        return fetch(Resource.saveAttachments(), {
            method: 'POST',
            body: formData
        })
        .then(Resource.checkStatus)
        .catch(ex => {
            const serviceRequest = {
                method: 'POST',
                url: Resource.saveAttachments(),
                correlationId: uuidv4(),
                // There is a file actually, not sure we want to log that: formData: formData,
                exception: JSON.stringify(ex, Object.getOwnPropertyNames(ex))
              };
             logger.api(serviceRequest);
             return Promise.reject();
        })
    });
}

const KycFieldContainer = (props) => {

    const dispatch = useDispatch<AppDispatch>();
    const contextValue = useContext<DeviceContextProps>(DeviceContext);
    const sessionWrapper = new SessionWrapper();

    const callHandover = () => {
        return dispatch(fireCaseCancelled(true))
            .finally(() => { // This is common path for both then and catch
            authService.getService().handover();
            // never resolved (due to handover)
            return new Promise(() => {});
        })
    };

    const sumbitAnswers = () => {
        return props.completeActivityAction();
    }

    return <div id="kyc-field">
            <Suspense fallback={null}><KycField {...props} language={sessionWrapper.getLanguage() === Language.CS ? "CZ" : "EN"}
                    device={ contextValue.isDesktop ? "DESKTOP" : "PHONE" } 
                    onComplete={() => {return sumbitAnswers()}} 
                     onBackToGeorge={callHandover} 
                     getKycInfo={getKycInfo} 
                     getQuestions={getQuestions} 
                     setQuestions={setQuestions}
                     saveAttachments = {saveAttachments}
            />
            </Suspense>
            </div>
            
};

export default KycFieldContainer;
