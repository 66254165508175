import RestCaller from "../restCaller";
import Resource from "../serverresource";
import {getLocale, setLang} from "../i18n";
import {GRG_PARAM_VALUE} from "./oidcService";

import SessionWrapper, {GSTATE_JSON_TYPE} from '../../sessions/sessionWrapper';
import {decodeBase64, decodeUriState} from "../utils/objectUtils";
import {resolveHashRoute} from "../utils/queryUtils";
import logger from "../loggly/gtiLogger";
import {LEGACY_SALES_CHANNELS} from "./authService";
import {LEGACY_AUTH_MODULES} from "../relevantStrategies/modules";
import { isEmptyString } from "validations";

/**
 * Service written specially for handling authentication and authorization of G4B application. To apply it on any other
 * application there must be a lot of refactoring
 */
class LegacyService {
    tokenReceivedHandler;
    sessionWrapper: SessionWrapper;
    SUPPORTED_AUTH_TYPE='legacy';
    SUPPORTED_API_KEY = import.meta.env.VITE_APP_API_KEY;
    applicationState;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    supportType = (authType) => {
        const module = this.sessionWrapper.getModule();

        if(authType === this.SUPPORTED_AUTH_TYPE) {
            logger.info("Using legacy auth");
            return true;
        }

        //FIXME: Temporary old way - should not be used anymore
        //AC-42276 - SES
        const salesChannel = this.sessionWrapper.getSalesChannel();
        const legacyModule = LEGACY_AUTH_MODULES.map(mod=>mod.name).indexOf(module?.toLowerCase()) >= 0
            && LEGACY_SALES_CHANNELS.indexOf(salesChannel) >= 0;
        const allowedAuthsModule = authType === import.meta.env.VITE_APP_AUTH_TYPE && legacyModule;

        if(allowedAuthsModule) {
            logger.info("Enabling old way of legacy auth");
            return true;
        }

        return false;
    }

    withTokenReceivedHandler = (tokenReceivedHandler) => {
        this.tokenReceivedHandler = tokenReceivedHandler;
        return this;
    }

    init = (params) => {
        if(this.tokenReceivedHandler) {


            //state param value encoded in url
            const {state, code} = params;
            let decodedParams = null

            if(this.validState(state)) {
                this.sessionWrapper.setGState(decodeUriState(state));
                decodedParams = this.stateToLowerCase(decodeBase64(state));

                ///FIXME: V puvodnim requestu nejsou request parametry citelne, ale zakodovane. Proto se na zacatku (v app.js)
                //nespravne resolvne jazyk
                const {lang} = decodedParams;
                if(lang) {
                    setLang(lang);
                }

                this.sessionWrapper.setSalesChannel(GRG_PARAM_VALUE);
            } else {
                console.warn("Invalid state: " + state);
            }

            //decode state into object
            this.applicationState = decodedParams;

            //code sent by B24 FE when first redirecting
            if(code && isEmptyString(this.sessionWrapper.getAccessToken())) {
                this.obtainAccessToken(code);
            }
            //fallback when refreshing page
            else {
                Resource.setInterceptor();
                this.tokenReceivedHandler(this.applicationState);
            }
        }
    }


    stateToLowerCase = (jsonState) => {
        const parsedParams = {};

        Object.keys(jsonState)
            .forEach(key => {
                const parsedKey = key.startsWith('/') ? key.substr(1).toLowerCase() : key.toLocaleLowerCase();
                const value = jsonState[key];
                parsedParams[parsedKey] = value;
            });

        return parsedParams;
    }

    obtainAccessToken = (code) => {
        const urlParams = {
            'grant_type': 'authorization_code',
            'code': code,
            'redirect_uri': import.meta.env.VITE_FE_APP_URL,
            'client_id': import.meta.env.VITE_APP_CLIENT_ID
        };

        this.sessionWrapper.setRouteRedirect(resolveHashRoute(window.location.hash));
        RestCaller.httpPostWithUrlEncodedFormData(Resource.getToken(), urlParams)
            .then(Resource.checkStatus)
            .then(Resource.parseJSON)
            .then(response => {
                console.log("Response with token: " + response);
                const {access_token} = response;
                this.sessionWrapper.setAccessToken(access_token);
                Resource.setInterceptor();
                window.location.hash = this.sessionWrapper.getRouteRedirect();

                if(this.tokenReceivedHandler) {
                    this.tokenReceivedHandler(this.applicationState);
                }
            })
        ;
    }

    addParameter = (formBody, paramName, paramValue) => {
        formBody.push(encodeURIComponent(paramName), encodeURIComponent(paramValue))
    }

    validState = (state) => {
        if(!state) {
            return false;
        }
        const decodedState = decodeBase64(state);

        return decodedState.returnState != null || decodedState.redirectUri != null;
    }

    logout = () => {
        const hashId = this.sessionWrapper.getHashId();
        logger.info("Logout through sso legacy for hashId: " + hashId + " BEGIN")
        return this.revoke(hashId)
            .then(() => {
                logger.info("Logout through sso legacy for hashId: " + hashId + " successfully ENDED. Initiate redirecting to the source application")
                this.redirectToGeorge();
                return Promise.resolve();
            })
            .catch(err => {
                const statusText = err?.statusText;
                const message = err?.message;

                logger.error("Error during revoke in legacyService with statusText: " + statusText + " and message: " + message);
                return Promise.reject();
            })
            ;
    }

    revoke = (hashId) => {
        const request = {
            token: this.sessionWrapper.getAccessToken(),
            "X-TRN-ID": hashId
        };

        return RestCaller.httpPostWithUrlEncodedFormData(Resource.revokeToken(), request)
            .then(response => {
                return Promise.resolve(response)
            })
            .catch(err => Promise.reject(err));
    }

    generateRedirect = () => {
        let redirectUri = import.meta.env.VITE_APP_MEP_HANDOVER_G4B_REDIRECT_URI;
        if(this.sessionWrapper.getGState(null)) {
            const gState = this.sessionWrapper.getGState(GSTATE_JSON_TYPE);
            redirectUri = gState.redirectUri ? gState.redirectUri : redirectUri;
        }

        return redirectUri;
    }

    handover = () => {
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', Resource.handoverToken());

        let redirectUri = this.generateRedirect();

        let state = 'overview';
        let targetClientId =  import.meta.env.VITE_APP_MEP_HANDOVER_TARGET_G4B_CLIENT_ID;

        if(this.sessionWrapper.getGState(null)) {
            const gState = this.sessionWrapper.getGState(GSTATE_JSON_TYPE);
            state = gState.returnState ? gState.returnState : state; //TODO - what if returnState is missing ?
            targetClientId = gState.srcClientId ? gState.srcClientId : import.meta.env.VITE_APP_MEP_HANDOVER_TARGET_G4B_CLIENT_ID;
        }

        this.appendAttribute(form, 'state', state);
        this.appendAttribute(form, 'access_token', this.sessionWrapper.getAccessToken());
        this.appendAttribute(form, 'target_client_id', targetClientId);
        this.appendAttribute(form, 'redirect_uri', redirectUri);
        this.appendAttribute(form, 'response_type', import.meta.env.VITE_APP_MEP_HANDOVER_RESPONSE_TYPE);
        this.appendAttribute(form, 'language_id', getLocale());

        document.getElementsByTagName('body')[0].appendChild(form);

        this.sessionWrapper.clearSessionStorage();
        form.submit();
    }

    redirectToGeorge = () => {
        let redirectUrl = import.meta.env.VITE_APP_LOGOUT_URL_B24;

        if(this.sessionWrapper.getGState(null)) {
            const gState = this.sessionWrapper.getGState(GSTATE_JSON_TYPE);
            const state = gState.returnState ? gState.returnState : null;
            redirectUrl += "?state=" + state;
        } else {
            logger.error("Missing gState in session"); //FIXME - delete console.error or throw and handle error
        }

        logger.info("Redirecting to " + redirectUrl);
        this.sessionWrapper.clearSessionStorage();
        logger.info("Session cleared");
        window.location.href = redirectUrl;
    }

    appendAttribute = (form, name, value) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;
        form.appendChild(input);
    }

    getLogoutTimeout = () => {
        return import.meta.env.VITE_APP_AUTOMATIC_LOGOUT_SECS;
    }

    getSupportedAuthType = () => {
        return this.SUPPORTED_AUTH_TYPE;
    }

    getSupportedApiKey = () => {
        return this.SUPPORTED_API_KEY;
    }
}

export default new LegacyService();
