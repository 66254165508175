import React, {Suspense} from 'react';
const Template = React.lazy(() => import("../Template"));


const TemplateWrapper = (props) => {
  return(
      <Suspense fallback={null}>
        <Template {...props}/>
      </Suspense>
      );
};

export default TemplateWrapper;