import { DocumentsField } from "@csas-smart/gti-ui-comps";
import React from "react";
import { deleteDocument, loadDocument, loadDocuments, uploadFiles } from "../actions/documentActions";
import { showErrorMessage } from "../actions/taskActions";
import {isMobileApp} from "../core/utils/salesChannelUtils";
import { AppDispatch } from "../core/store";
import { useDispatch } from "react-redux";


const DocumentsFieldContainer = (props) => {

    const dispatch = useDispatch<AppDispatch>();

    const onDownload = (fieldName, file) => {
        return dispatch(loadDocument(fieldName, file))
            .then((fileContent) => {documentResolved(fileContent, file)}, documentRejected);
    }

    const documentRejected = (error) => {
        console.log("Loading of document failed:", error);
    };

    const documentResolved = (fileContent, file) => {
        //webView - way
        if(isMobileApp()) {
            const reader = new window.FileReader();
            fileContent.blob.then(blob => {
                reader.readAsBinaryString(blob)
                reader.onloadend = () => {
                    if (!window.webkit) {
                        console.error("Window does not conatins webkit");
                        return;
                    }

                    if (!window.webkit.messageHandlers) {
                        console.error("Window does not conatins webkit.messageHandlers");
                        return;
                    }

                    if (!window.webkit.messageHandlers.readBlob) {
                        console.error("Window does not conatins webkit.messageHandlers.readBlob");
                        return;
                    }

                    window.webkit.messageHandlers.readBlob.postMessage(reader.result);

                }
            });
        } else {//classic way
            //document is <DOM> document. Not document object used by this "component"
            const link = document.createElement('a');

            link.setAttribute('download', fileContent.filename ? fileContent.filename : file.fileName);
            fileContent.blob.then(blob => {
                link.href = window.URL.createObjectURL(new Blob([blob]))
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
         }
    };

    return <DocumentsField loadDocuments={(attrName, allowedRecordTypes, fieldName) => dispatch(loadDocuments(attrName, allowedRecordTypes, fieldName))}
                           uploadFiles={(fieldName, attrName, files) => dispatch(uploadFiles(fieldName, attrName, files))} 
                           deleteFile={(fieldName, attrName, file) => dispatch(deleteDocument(fieldName, attrName, file))} 
                           downloadFile={onDownload}
                           showErrorMessage={(message) => dispatch(showErrorMessage(message))}
                           {...props}
    />

}

export default DocumentsFieldContainer;