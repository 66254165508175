import React from "react";
import {loadCodebook} from "../actions/taskActions";
import {CodebookField, Language} from "@csas-smart/gti-ui-comps";
import SessionWrapper from "../sessions/sessionWrapper";

const CodebookFieldContainer = (props) => {
    const sessionWrapper = new SessionWrapper();

    /* Temporary override for corporates: https://csas.atlassian.net/browse/DFM-106614
    * Michal Skramusky and Jindrich Jehlicka promised to solve the root cause until 31.10.2024.
    * Afterwards, this "if" will be removed mercilessly.
    */
    let language = sessionWrapper.getLanguage();
    if (props?.field?.name?.includes("forceCSLang")) {
        language = Language.CS;
    }

    return <CodebookField {...props} loadCodebookFn={loadCodebook} language={language}/>;
}

export default CodebookFieldContainer;
