import {AlertsField} from "@csas-smart/gti-ui-comps";
import React from "react";
import {useSelector} from "react-redux";
import { RootState } from "../core/store";

/**
 * Component representing an alert. For additional info please visit: https://gti-library-int.csint.cz/#/fields/alerts-field  
 */
const AlertsFieldContainer = (props) => {

    let isFailedCompleted = useSelector<RootState>(state => state.task.activity.isFailedCompleted);
    return <div className="alerts-field">
            <AlertsField {...props} isFailedCompleted={isFailedCompleted} />
        </div>
};

export default AlertsFieldContainer;