import React from "react";
import {useTranslation} from "react-i18next";
import {fireCaseCancelled, fireCaseSaved} from "../../actions/taskActions";
import authService from "../auths/authService";
import {Button, ButtonGroup, Modal, ModalBody, ModalFooter} from "@george-labs.com/design-system";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";

const CancelPopUp = ({showCancelPopUp, dismissPopUpFunc}) => {
    const actions = useAppSelector(state => state.task?.activity?.actions || []);
    // const activityDesign = useSelector(state => state.task.activity);

    const {t} = useTranslation(['common']);
    const dispatch = useAppDispatch();    

    const fireCancelCase = () => {
        dispatch(fireCaseCancelled())
            .then(() => dismissPopUpFunc())
            .then(() => authService.getService().handover());
    };

    // FIXME: Should not we fire GA event? 
    const fireSaveCase = () => {
        dispatch(fireCaseSaved())
            .then(() => dismissPopUpFunc())
            .then(() => authService.getService().handover());
    }

    return (
        <Modal isOpen={showCancelPopUp} title={t('popup.cancel.title')} onClose={dismissPopUpFunc}>
            <ModalBody>
                <div>{t('popup.cancel.messageTop')}</div>
                <div>{t('popup.cancel.messageBottom')}</div>
            </ModalBody>
            <ModalFooter>
                <ButtonGroup>
                    {
                        actions.some(action => action.type === "SAVE") && 
                        <Button id={'save-case-btn'} variant={Button.VARIANT.SECONDARY} onClick={fireSaveCase} >
                            {t('popup.cancel.button.save')}
                        </Button>
                    }
                        <Button id={'cancel-case-btn'} color="primary" onClick={fireCancelCase}>
                            {t('popup.cancel.button.cancel')}
                        </Button>
                </ButtonGroup>
            </ModalFooter>
        </Modal>);
};

export default CancelPopUp;
