import { CategorizationState } from "./types";

const initialState: CategorizationState = { 
    categories: null,
    selectedCaseType: null,
    selectedSubCaseType: null
};

function mapCategory(category) {
    if(category.subCaseTypes && category.subCaseTypes.length > 0) {
        //split "subCaseTypes.length" times
        const splitted = category.subCaseTypes.map((subCaseType) => Object.assign({}, category, {subCaseTypes: [], subCaseType: subCaseType}));
        return splitted;
    } else {
        //just return category
        return category;
    }
}

const categorizationReducers = (state = initialState, action): CategorizationState => {
    var newState;
    switch (action.type) {
        case 'UPDATE_CATEGORIES':
            var categories = action.categories.flatMap((category) => mapCategory(category));
            //var categories  = action.categories;
            newState = Object.assign( {}, initialState, {categories: categories, selectedCaseType: null});
            return newState;
        case 'SELECT_CATEGORY':
            var caseType = action.caseType;
            var subCaseType = action.subCaseType;

            newState = Object.assign( {}, initialState, {categories: [...state.categories], selectedCaseType: caseType, selectedSubCaseType: subCaseType});
            return newState;
      default:
        return state;
    }
};

export default categorizationReducers;