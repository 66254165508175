// @ts-ignore 
import React, { lazy } from "react";
import { useDispatch } from 'react-redux';
import { wizardFolderSign, wizardContractsStatus } from "../../actions/g4bmigrActions";
import SessionWrapper from "../../sessions/sessionWrapper";
import { validatedComponent } from '@csas-smart/gti-ui-comps';

/**
 * Lazily loading "@csas-smart/sigma-ui": 
 */ 
const SigmaApiConfigWrapper = lazy(() =>
    import("@csas-sigma/sigma-ui").then(module => ({ default: module.SigmaApiConfigWrapper }))
);
const SigmaOnlineAuthApiConfigWrapper = lazy(() =>
    import("@csas-sigma/sigma-ui").then(module => ({ default: module.SigmaOnlineAuthApiConfigWrapper }))
);

/**
 * Lazily loading "@csas-smart/gti-g4bmigr-ui": 
 */ 
const DigitalDataSignField = lazy(() =>
    import("@csas-smart/gti-g4bmigr-ui").then(module => ({ default: module.DigitalDataSignField }))
);
  


const DataSignFieldContainer = (props) => {

    const dispatch = useDispatch();
    const sessionWrapper = new SessionWrapper();
    const apiConfig = {
        env: import.meta.env.VITE_ENV.toUpperCase(),
        webApiKey: import.meta.env.VITE_APP_API_KEY,
        accessToken: sessionWrapper.getAccessToken()
    }

    const onFolderSign = (requestDto) => {
        console.log('DataSignFieldContainer - onFolderSign called');

        return dispatch(wizardFolderSign(requestDto));
    };

    const getContractsStatus = (requestDto) => {
        console.log('DataSignFieldContainer - getContractsStatus called');

        return dispatch(wizardContractsStatus(requestDto));
    };

    return (
        <SigmaApiConfigWrapper {...props} accessToken={apiConfig.accessToken} environment={apiConfig.env} webApiKey={apiConfig.webApiKey}>
            <SigmaOnlineAuthApiConfigWrapper onlineAuthorizationToken={apiConfig.accessToken} environment={apiConfig.env} webApiKey={apiConfig.webApiKey}>
              <DigitalDataSignField {...props} onFolderSign={onFolderSign} getContractsStatus={getContractsStatus} />  
            </SigmaOnlineAuthApiConfigWrapper>
        </SigmaApiConfigWrapper>
    )

};

const validateDataSignField = (setError, required, attributes) => {
    if (!required){
        return true;
    }

    const { isSigned } = attributes;

    if (isSigned) {
        setError({error: false});
        return true;
    }
    else {
        setError({error: true});
        return false;
    }
}

export default validatedComponent(DataSignFieldContainer, validateDataSignField);
