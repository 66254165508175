import SessionWrapper from "../../../sessions/sessionWrapper";
import Resource from "../../serverresource";
import {ALLOWED_TABLET_SALES_CHANNELS} from "../adfsService";

class AdfsSuccessHandler {

    sessionWrapper: SessionWrapper;
    
    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    canHandle = (params) => {
        return this.sessionWrapper.getAccessToken() && !params?.code && params?.state;
    }

    handleParams =  (params) => {
        if(params?.access_token) {
            this.sessionWrapper.setAccessToken(params.access_token);
        }

        Resource.setInterceptor();

        this.sessionWrapper.setBtiFlag();
        if(params?.saleschannel) {
            this.sessionWrapper.setSalesChannel(params?.saleschannel);
            this.sessionWrapper.setTablet(ALLOWED_TABLET_SALES_CHANNELS.indexOf(params?.saleschannel) > -1);
        }
    }
}

export default AdfsSuccessHandler;
